import { Attachment, CreateOfferProject, CreateOfferTender } from '@react-fe/expertunity-base/models';
import { Schema } from 'yup';
import { StateCreator } from 'zustand';
import { CreateOfferModalStep, OfferFormFields } from '../components/create-offer-modal/create-offer-modal.constants';

export interface OfferState {
  isCreateOfferOnBehalf: boolean;
  activeStep: CreateOfferModalStep;
  offerValidationSchema: Schema<OfferFormFields> | null;
  tenderAttachments: Attachment[];
  isOpenCreateOfferModal: boolean;
  tenderDetails: CreateOfferTender | null;
  project: CreateOfferProject | null;
  offerUid: string | null;
  isSubmittingNewAttachments: boolean;
  hasAcceptedOrRejectedOffer: boolean;
  currentBidderUid: string | null;
  setIsCreateOfferOnBehalf: (isCreateOfferOnBehalf: boolean) => void;
  setActiveStep: (step: CreateOfferModalStep) => void;
  setOfferValidationSchema: (schema: Schema<OfferFormFields>) => void;
  isLastStep: () => boolean;
  clearCreateOfferState: () => void;
  setTenderAttachments: (attachments: Attachment[]) => void;
  setIsOpenCreateOfferModal: (isOpen: boolean) => void;
  setTenderDetails: (tenderDetails: CreateOfferTender) => void;
  setProject: (project: CreateOfferProject) => void;
  setOfferUid: (offerUid: string) => void;
  setIsSubmittingNewAttachments: (isSubmitting: boolean) => void;
  setHasAcceptedOrRejectedOffer: (hasAcceptedOrRejectedOffer: boolean) => void;
  setCurrentBidderUid: (currentBidderUid: string | null) => void;
}

export const createOfferSlice: StateCreator<OfferState> = (set, get) => ({
  isCreateOfferOnBehalf: false,
  activeStep: CreateOfferModalStep.DOCUMENTS,
  offerValidationSchema: null,
  tenderAttachments: [],
  isOpenCreateOfferModal: false,
  tenderDetails: null,
  project: null,
  offerUid: null,
  isSubmittingNewAttachments: false,
  hasAcceptedOrRejectedOffer: false,
  currentBidderUid: null,
  setIsCreateOfferOnBehalf: (isCreateOfferOnBehalf: boolean) => {
    set({ isCreateOfferOnBehalf: isCreateOfferOnBehalf });
  },
  setActiveStep: (step: CreateOfferModalStep) => {
    set({ activeStep: step });
  },
  setOfferValidationSchema: (schema: Schema<OfferFormFields>) => {
    set({ offerValidationSchema: schema });
  },
  isLastStep: () => {
    return get().activeStep === 1;
  },
  clearCreateOfferState: () => {
    set({ activeStep: CreateOfferModalStep.DOCUMENTS, tenderAttachments: [], offerUid: null });
  },
  setTenderAttachments: (attachments: Attachment[]) => {
    set({ tenderAttachments: attachments });
  },
  setIsOpenCreateOfferModal: (isOpen: boolean) => {
    set({ isOpenCreateOfferModal: isOpen });
  },
  setTenderDetails: (tenderDetails: CreateOfferTender) => {
    set({ tenderDetails: tenderDetails });
  },
  setProject: (project: CreateOfferProject) => {
    set({ project: project });
  },
  setOfferUid: (offerUid: string) => {
    set({ offerUid: offerUid });
  },
  setIsSubmittingNewAttachments: (isSubmitting: boolean) => {
    set({ isSubmittingNewAttachments: isSubmitting });
  },
  setHasAcceptedOrRejectedOffer: (hasAcceptedOrRejectedOffer: boolean) => {
    set({ hasAcceptedOrRejectedOffer: hasAcceptedOrRejectedOffer });
  },
  setCurrentBidderUid: (currentBidderUid: string | null) => {
    set({ currentBidderUid: currentBidderUid });
  },
});
