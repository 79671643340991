import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { API_URL } from '../constants';
import { QueryKeys } from '../models';
import { Geoname } from '../models/geoname';

export function useGeonames(
  searchTerm: string,
  country: string,
  countryBias: string,
  searchType: 'postalcode' | 'placename' = 'postalcode',
  username = 'olmero',
  maxRows = 12,
  style = 'SHORT',
): UseQueryResult<{ postalCodes: Geoname[] }> {
  return useQuery<{ postalCodes: Geoname[] }>({
    queryKey: [QueryKeys.POSTAL_CODES, searchTerm, country, countryBias, searchType, username, maxRows, style],
    queryFn: async (): Promise<any> => {
      const url = `${API_URL}/geonames/postalCodeSearch`;
      const params = {
        ...(searchType === 'postalcode' ? { postalcode_startsWith: searchTerm } : { placename_startsWith: searchTerm }),
        country,
        countryBias,
        username,
        maxRows,
        style,
      };
      const response = await axios.get<any>(url, { params });
      return response.data;
    },
    enabled: !!searchTerm && !!country && !!countryBias,
  });
}

export default useGeonames;
