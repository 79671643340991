import { Attachment } from '@react-fe/expertunity-base/models';

type AttachmentsByCategory = Record<string, { label: string; attachments: Attachment[] }>;

export const INITIAL_STATE: AttachmentsByCategory = {
  work_description: {
    label: 'work_description',
    attachments: [],
  },
  general_terms: {
    label: 'general_terms',
    attachments: [],
  },
  special_terms: {
    label: 'special_terms',
    attachments: [],
  },
  plans: {
    label: 'plans',
    attachments: [],
  },
  attachment: {
    label: 'attachment',
    attachments: [],
  },
  candidate_information: {
    label: 'candidate_information',
    attachments: [],
  },
};

type Action = {
  type: 'SET_ATTACHMENTS';
  payload: Attachment[];
};

export function attachmentReducer(state: AttachmentsByCategory, action: Action): AttachmentsByCategory {
  if (action.type === 'SET_ATTACHMENTS') {
    const sortedAttachments = [...action.payload].sort((a, b) => a.name.localeCompare(b.name));
    const newState = { ...state };

    Object.keys(newState).forEach(category => {
      newState[category] = { ...newState[category], attachments: [] };
    });

    sortedAttachments.forEach(attachment => {
      if (newState[attachment.category]) {
        newState[attachment.category].attachments.push(attachment);
      }
    });

    return newState;
  }
  return state;
}

export default attachmentReducer;
