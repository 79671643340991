import { Button, Icon, Text } from '@react-fe/common-ui';
import { Attachment } from '@react-fe/expertunity-base/models';
import { useDateFormatter } from '@react-fe/core';
import { faDownload } from '@fortawesome/pro-regular-svg-icons';
import { useFileIcon, useFileSize, useForceFileName } from '@react-fe/expertunity-base/hooks';

interface AttachmentCardProps {
  attachment: Attachment;
  iconClassname?: string;
}

export function AttachmentCard({ attachment, iconClassname }: Readonly<AttachmentCardProps>) {
  const { dateFormatter } = useDateFormatter();
  const icon = useFileIcon(attachment.name);
  const size = useFileSize(attachment.file.length);

  return (
    <a href={useForceFileName(attachment)} className="border rounded-lg p-spacing-2 flex gap-spacing-2">
      <Icon className="p-spacing-2 text-text-secondary text-base" icon={icon}></Icon>
      <div className="grow flex flex-col">
        <div className="grow flex gap-spacing-2">
          <div className="grow flex flex-col">
            <Text>{attachment.name}</Text>
            <Text variant={Text.variants.BODY2} color={Text.colors.TEXT_SECONDARY}>
              {size} • {dateFormatter(attachment.file.createdAt, 'Pp')}
            </Text>
          </div>
          <Button.Root type={Button.Root.types?.GHOST}>
            <Button.Icon icon={faDownload} className={iconClassname ?? 'text-base'} />
          </Button.Root>
        </div>
        {attachment.description && (
          <Text variant={Text.variants.BODY2} color={Text.colors.TEXT_SECONDARY}>
            {attachment.description}
          </Text>
        )}
      </div>
    </a>
  );
}

export default AttachmentCard;
