import React, { useMemo } from 'react';
import { CoreComponentProps } from '@react-fe/core';
import { ErrorMessage, FieldInputProps } from 'formik';
import { ComponentDefaultTestId, getTestId, Text } from '@react-fe/common-ui';
import cx from 'classnames';

export interface FormErrorProps extends CoreComponentProps {
  field?: FieldInputProps<any>;
}

export const FormError: React.FC<FormErrorProps> = ({ id, 'data-testid': dataTestId, className, field }) => {
  const formErrorProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId || getTestId(ComponentDefaultTestId.FORM_ERROR, id),
      className: cx([className, 'input-hint !text-error mt-[3px] ']),
    }),
    [id, dataTestId, className],
  );

  if (!field) return null;

  return (
    <ErrorMessage name={field.name} {...formErrorProps}>
      {msg => {
        const messages = Array.isArray(msg) ? msg : [msg];
        return messages.map((message, index) => {
          const isValidNode = typeof message === 'string' || React.isValidElement(message);
          return isValidNode ? (
            <Text key={index} color={Text.colors.ERROR} variant={Text.variants.CAPTION} className="!mt-[3px]">
              {message}
            </Text>
          ) : null;
        });
      }}
    </ErrorMessage>
  );
};

FormError.displayName = 'FormError';

export default FormError;
