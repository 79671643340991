import { useMemo } from 'react';
import { faFile, faFilePdf, faFileXls, faFileZip } from '@fortawesome/pro-regular-svg-icons';

export const useFileIcon = (filename: string) => {
  return useMemo(() => {
    if (!filename.includes('.')) {
      return faFile;
    }

    const splitName = filename.toLocaleLowerCase().split('.');
    const type = splitName[splitName.length - 1];

    switch (type) {
      case 'xls':
      case 'xlsx':
        return faFileXls;
      case 'zip':
        return faFileZip;
      case 'pdf':
        return faFilePdf;
      default:
        return faFile;
    }
  }, [filename]);
};
