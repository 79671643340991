import { ComponentDefaultTestId, getTestId } from '@react-fe/common-ui';
import { CoreComponent } from '@react-fe/core';
import { useField } from 'formik';
import React, { forwardRef, useCallback, useMemo } from 'react';
import { FileUploadInput, FileUploadInputProps } from '../file-upload-input';
import { FormError } from '../form-error';
import { FormControlRootProps } from './form-control-root';

type FormControlMergedProps<T> = {
  value?: T;
  onChange?: (value: T) => void;
} & Omit<FormControlRootProps, 'value' | 'onChange'> &
  Omit<FileUploadInputProps<T>, 'value' | 'onChange'>;

export interface FormControlFileUploadProps<T> extends FormControlMergedProps<T> {
  label?: string;
  transformAttachment?: (uploadedContent: any) => T;
  fileNameExtractor?: (file: T) => string;
  fileSizeExtractor?: (file: T) => number;
}

function FormControlFileUploadInner<T>(
  {
    id,
    'data-testid': dataTestId,
    name,
    uploadEndpoint,
    transformAttachment,
    fileNameExtractor,
    fileSizeExtractor,
    className,
    ...otherProps
  }: FormControlFileUploadProps<T>,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const [field, , helpers] = useField(name as string);

  const handleUploadSuccess = useCallback(
    (uploaded: any | any[]) => {
      if (Array.isArray(uploaded)) {
        const newAttachments = uploaded.map(upload => (transformAttachment ? transformAttachment(upload) : upload));
        helpers.setValue([...field.value, ...newAttachments]);
      } else {
        const newAttachment = transformAttachment ? transformAttachment(uploaded) : uploaded;
        helpers.setValue([...field.value, newAttachment]);
      }
    },
    [transformAttachment, helpers, field.value],
  );

  const handleFileDelete = useCallback(
    (deletedFile: T) => {
      helpers.setValue(field.value.filter((file: T) => file !== deletedFile));
    },
    [helpers, field.value],
  );

  const formControlFileUploadProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId || getTestId(ComponentDefaultTestId.FORM_CONTROL_FILE_UPLOAD, id),
      className,
    }),
    [id, dataTestId, className],
  );

  return (
    <div {...formControlFileUploadProps}>
      <FileUploadInput
        {...field}
        {...otherProps}
        className="flex flex-col h-full"
        uploadEndpoint={uploadEndpoint}
        onUploadSuccess={handleUploadSuccess}
        fileNameExtractor={fileNameExtractor}
        fileSizeExtractor={fileSizeExtractor}
        onDeleteFile={handleFileDelete}
      />
      <FormError field={field} />
    </div>
  );
}

export const FormControlFileUpload = forwardRef(FormControlFileUploadInner) as <T>(
  props: FormControlFileUploadProps<T> & { ref?: React.ForwardedRef<HTMLInputElement> },
) => ReturnType<typeof FormControlFileUploadInner>;

(FormControlFileUpload as CoreComponent<FormControlFileUploadProps<unknown>>).displayName = 'FormControl.FileUpload';

export default FormControlFileUpload;
