export enum ProgressBarVariant {
  DETERMINATE = 'determinate',
  INDETERMINATE = 'indeterminate',
  BUFFER = 'buffer',
}

export enum ProgressBarColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  INHERIT = 'inherit',
}
