import { Checkbox, Skeleton } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import { CategorizedAttachmentState, useTypedBaseStore } from '@react-fe/expertunity-base/stores';
import cx from 'classnames';
import { forwardRef, useMemo, useRef, useState } from 'react';
import { AttachmentWithIndex } from '../attachments-list/attachments-list.constants';
import { CategorizedAttachmentForm } from '../categorized-attachment-form';
import { ToCategorizeHeader } from './components/to-categorize-header';

export type ToCategorizeListProps = CoreComponentProps & {
  fieldName: string;
  attachments: AttachmentWithIndex[];
  form: { values: any };
  removeAttachmentByIndex: (index: number) => void;
};

export const ToCategorizeList: CoreComponent<ToCategorizeListProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  ToCategorizeListProps
>(({ id, 'data-testid': dataTestId, className, fieldName, attachments, form, removeAttachmentByIndex }, ref) => {
  const { lastFocusedAttachmentIndex, isSubmittingNewAttachments } = useTypedBaseStore<CategorizedAttachmentState>();
  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);
  const internalRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx(className);

  const handleAttachmentCheckbox = (attachmentIndex: number) => {
    setSelectedIndexes(prev =>
      prev.includes(attachmentIndex) ? prev.filter(index => index !== attachmentIndex) : [...prev, attachmentIndex],
    );
  };

  const toCategorizeListProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      ref: mergedRef,
    }),
    [id, dataTestId, classNames, mergedRef],
  );

  return (
    <div {...toCategorizeListProps}>
      <ToCategorizeHeader
        fieldName={fieldName}
        attachments={attachments}
        selectedIndexes={selectedIndexes}
        setSelectedIndexes={setSelectedIndexes}
      />

      {isSubmittingNewAttachments && <Skeleton height={44} variant={'rectangular'} className="my-spacing-2" />}

      {attachments.map(attachmentWithIndex => (
        <div
          className={`flex items-center gap-spacing-2 ${lastFocusedAttachmentIndex === attachmentWithIndex.index && '!bg-background-paper-elevation-1'}`}
          key={`to-categorize-attachment-form-group-${attachmentWithIndex.index}`}
        >
          <Checkbox
            id={`to-categorize-attachment-form-group-${attachmentWithIndex.index}-checkbox`}
            className="pl-spacing-2 p-spacing-1"
            value={selectedIndexes.includes(attachmentWithIndex.index)}
            onChange={() => handleAttachmentCheckbox(attachmentWithIndex.index)}
          />
          <CategorizedAttachmentForm
            className="w-full"
            index={attachmentWithIndex.index}
            name={fieldName}
            attachment={attachmentWithIndex.attachment}
            form={form}
            remove={removeAttachmentByIndex}
          />
        </div>
      ))}
    </div>
  );
});

ToCategorizeList.displayName = 'ToCategorizeList';

export default ToCategorizeList;
