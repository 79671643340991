import React, { forwardRef, useMemo, useRef } from 'react';
import { CoreComponent, useMergedRef, withStaticProps } from '@react-fe/core';
import { Typography, TypographyProps, TypographyStaticProps, TypographyStaticValues } from '../typography';
import { HeadingVariant } from './heading.constants';

export type HeadingProps = TypographyProps<HeadingVariant>;

const HeadingComponent: CoreComponent<HeadingProps, HTMLDivElement> &
  TypographyStaticProps & {
    variants?: typeof HeadingVariant;
  } = forwardRef<HTMLDivElement, HeadingProps>(({ variant = Heading.variants?.H1, children, ...otherProps }, ref) => {
  const internalRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);

  const headingProps = useMemo(
    () => ({
      variant,
      ref: mergedRef,
      ...otherProps,
    }),
    [variant, mergedRef, otherProps],
  );

  return <Typography {...headingProps}>{children}</Typography>;
});

export const Heading = withStaticProps(HeadingComponent, {
  variants: HeadingVariant,
  ...TypographyStaticValues,
});

Heading.displayName = 'Heading';

export default Heading;
