import { Button, Form, FormControl, Heading, HeadingVariant } from '@react-fe/common-ui';
import { Formik, FormikHelpers } from 'formik';
import { object, string } from 'yup';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MutationConfig } from '@react-fe/expertunity-base/models';
import useCreateInternalComment from './hooks/use-create-internal-comment';

export interface AddCommentProps {
  onCancel: () => void;
  onSuccess: () => void;
  onError: () => void;
  mutationConfig: MutationConfig;
}

export function AddComment({ onCancel, onSuccess, onError, mutationConfig }: AddCommentProps) {
  const { t } = useTranslation();
  const { mutate } = useCreateInternalComment(mutationConfig);

  return (
    <div className="flex flex-col gap-spacing-3">
      <Heading variant={HeadingVariant.H4}>{t('add_comment')}</Heading>
      <Formik
        validateOnChange={false}
        initialValues={{ comment: '' }}
        validationSchema={object().shape({
          comment: string().required(),
        })}
        onSubmit={(values, { setSubmitting }: FormikHelpers<{ comment: string }>) => {
          mutate(values, {
            onSuccess: () => {
              setSubmitting(false);
              onSuccess();
            },
            onError: () => {
              onError();
            },
          });
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormControl.Root name="comment" className="mb-spacing-3">
              <FormControl.TextField multiline rows={3} label={t('comment')} />
            </FormControl.Root>

            <div className="flex gap-spacing-2 justify-end">
              <Button.Root type={Button.Root.types.OUTLINED} onClick={onCancel}>
                {t('cancel')}
              </Button.Root>
              <Button.Root inputType={Button.Root.inputType?.SUBMIT} disabled={isSubmitting} loading={isSubmitting}>
                {t('add')}
              </Button.Root>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddComment;
