import { Button, Form, FormControl, Title } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import cx from 'classnames';
import { useFormikContext } from 'formik';
import { forwardRef, useMemo, useRef } from 'react';
import { ConfigurableDescriptionLabels } from '../../configurable-description-modal.constants';

export type ConfigurableDescriptionFormProps = CoreComponentProps & {
  labels: ConfigurableDescriptionLabels;
  handleCancel: () => void;
};

export const ConfigurableDescriptionForm: CoreComponent<ConfigurableDescriptionFormProps, HTMLFormElement> = forwardRef<
  HTMLFormElement,
  ConfigurableDescriptionFormProps
>(({ id, 'data-testid': dataTestId, className, labels, handleCancel }, ref) => {
  const { isSubmitting } = useFormikContext();
  const internalRef = useRef<HTMLFormElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx(className);

  const configurableDescriptionFormProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      ref: mergedRef,
    }),
    [id, dataTestId, classNames, mergedRef],
  );

  return (
    <Form {...configurableDescriptionFormProps}>
      <Title title={labels.title} subTitle={labels.subtitle} />

      <FormControl.Root name="description" className="mb-spacing-3">
        <FormControl.TextField
          multiline
          rows={3}
          label={labels.descriptionField}
          placeholder={labels.descriptionPlaceholder}
        />
      </FormControl.Root>

      <div className="w-full flex gap-spacing-2 justify-end">
        <Button.Root type={Button.Root.types.OUTLINED} onClick={handleCancel}>
          {labels.cancelButton}
        </Button.Root>
        <Button.Root inputType={Button.Root.inputType?.SUBMIT} disabled={isSubmitting} loading={isSubmitting}>
          {labels.confirmButton}
        </Button.Root>
      </div>
    </Form>
  );
});

ConfigurableDescriptionForm.displayName = 'ConfigurableDescriptionForm';

export default ConfigurableDescriptionForm;
