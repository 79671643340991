import React, { forwardRef, useEffect, useMemo, useRef } from 'react';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import { EditorContent, useEditor } from '@tiptap/react';
import { RichTextBar } from '../rich-text-bar';
import { EditorEvents } from '@tiptap/core';
import { StarterKit } from '@tiptap/starter-kit';
import { Text } from '../text';
import { ComponentDefaultTestId, getTestId } from '@react-fe/common-ui';
import Underline from '@tiptap/extension-underline';
import Placeholder from '@tiptap/extension-placeholder';
import CharacterCount from '@tiptap/extension-character-count';
import './text-editor-input.scss';
import cx from 'classnames';

export interface TextEditorInputProps extends CoreComponentProps {
  value?: string;
  placeholder?: string;
  limit?: number;
  maxHeight?: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  onUpdate?: (props: EditorEvents['update']) => void;
}

export const TextEditorInput: CoreComponent<TextEditorInputProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  TextEditorInputProps
>(
  (
    {
      id,
      'data-testid': dataTestId,
      className,
      value = '',
      placeholder,
      limit,
      maxHeight,
      disabled,
      onUpdate,
      helperText,
      error,
    },
    ref,
  ) => {
    const internalRef = useRef<HTMLDivElement>(null);
    const mergedRef = useMergedRef(ref, internalRef);
    const showHelper = !!helperText && !error;

    useEffect(() => {
      if (internalRef.current) {
        const tipTapElement = internalRef.current.querySelector('.tiptap');
        if (tipTapElement && typeof maxHeight === 'string') {
          (tipTapElement as HTMLElement).style.maxHeight = maxHeight;
        }
      }
    }, [maxHeight, helperText]);

    const extensions = useMemo(() => {
      return [
        StarterKit,
        Underline,
        Placeholder.configure({
          placeholder: placeholder,
        }),
        CharacterCount.configure({
          limit: limit,
        }),
      ];
    }, [placeholder, limit]);

    const textEditorInputProps = useMemo(() => {
      return {
        id,
        'data-testid': dataTestId || getTestId(ComponentDefaultTestId.TEXT_EDITOR, id),
        className: cx(className, 'flex flex-col w-fit relative'),
        ref: mergedRef,
        placeholder,
      };
    }, [id, dataTestId, className, mergedRef, placeholder]);

    const editor = useEditor({
      extensions,
      onUpdate,
      content: value,
      editable: !disabled,
      shouldRerenderOnTransaction: false,
    });

    return (
      <div {...textEditorInputProps}>
        <EditorContent editor={editor} className={error ? 'tiptap-error' : ''} />
        <RichTextBar editor={editor} className={showHelper ? '!bottom-[26px]' : ''} disabled={disabled} />
        {showHelper && (
          <Text className={'input-hint'} color={Text.colors?.TEXT_SECONDARY} variant={Text.variants?.CAPTION}>
            {helperText}
          </Text>
        )}
      </div>
    );
  },
);

TextEditorInput.displayName = 'TextEditorInput';

export default TextEditorInput;
