import { faUndo } from '@fortawesome/pro-regular-svg-icons';
import { Button } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import { Namespaces } from '@react-fe/expertunity-base';
import cx from 'classnames';
import { forwardRef, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
export interface ClearFiltersButtonProps extends CoreComponentProps {
  disabled: boolean;
  clearFilters: () => void;
  maxWidth?: boolean;
}

export const ClearFiltersButton: CoreComponent<ClearFiltersButtonProps, HTMLButtonElement> = forwardRef<
  HTMLButtonElement,
  ClearFiltersButtonProps
>(({ id, 'data-testid': dataTestId, disabled, clearFilters, maxWidth }, ref) => {
  const { t } = useTranslation();
  const internalRef = useRef<HTMLButtonElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx('!bg-transparent', !disabled && '!text-info', maxWidth && 'w-max');

  const clearFiltersButtonProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      ref: mergedRef,
      className: classNames,
      disabled,
    }),
    [id, dataTestId, mergedRef, classNames, disabled],
  );

  return (
    <Button.Root type={Button.Root.types.GHOST} {...clearFiltersButtonProps} onClick={clearFilters}>
      {!disabled && <Button.Icon icon={faUndo} />}
      {t('clear_all_filters_button', {
        ns: Namespaces.Common,
        defaultValue: 'Clear all',
      })}
    </Button.Root>
  );
});

ClearFiltersButton.displayName = 'ClearFiltersButton';

export default ClearFiltersButton;
