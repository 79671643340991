import { Chip, TextField } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import { HIERARCHICAL_ITEMS_MODAL_SX_STYLES, Namespaces, TypesOfWorkModal } from '@react-fe/expertunity-base';
import cx from 'classnames';
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from '@react-fe/expertunity-base/hooks';
import { ModalKeys, TypeOfWork } from '@react-fe/expertunity-base/models';

export interface TenderTypeOfWorkModalFilterProps extends CoreComponentProps {
  filtersCleared: boolean;
  setTypesOfWorkUids: (typesOfWork: string[]) => void;
  valuesFromSettings?: TypeOfWork[];
  rounded?: boolean;
}

export const TypeOfWorkModalFilter: CoreComponent<TenderTypeOfWorkModalFilterProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  TenderTypeOfWorkModalFilterProps
>(
  (
    {
      id,
      'data-testid': dataTestId,
      className,
      filtersCleared,
      setTypesOfWorkUids,
      valuesFromSettings,
      rounded = true,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const [typesOfWorks, setTypesOfWorks] = useState<TypeOfWork[]>(valuesFromSettings || []);
    const internalRef = useRef<HTMLDivElement>(null);
    const mergedRef = useMergedRef(ref, internalRef);
    const classNames = cx(className, 'flex');
    const { openModal, closeModal } = useModal();

    useEffect(() => {
      if (filtersCleared) {
        setTypesOfWorks([]);
        setTypesOfWorkUids([]);
      }
    }, [filtersCleared, setTypesOfWorkUids]);

    useEffect(() => {
      if (valuesFromSettings) {
        setTypesOfWorkUids(valuesFromSettings.map(type => type.uid));
        setTypesOfWorks(valuesFromSettings);
      }
    }, [valuesFromSettings, setTypesOfWorkUids]);

    const handleConfirm = (results: TypeOfWork[]) => {
      setTypesOfWorks(results);
      setTypesOfWorkUids(results.length ? results.map(type => type.uid) : ['']);
      closeModal(ModalKeys.TYPES_OF_WORK_MODAL);
    };

    const handleControlClick = () => {
      openModal({
        modalId: ModalKeys.TYPES_OF_WORK_MODAL,
        content: (
          <TypesOfWorkModal
            data-testid="test-type-of-work-dialog"
            handleClose={() => closeModal(ModalKeys.TYPES_OF_WORK_MODAL)}
            handleConfirm={handleConfirm}
            currentSelectedTypes={typesOfWorks}
            title={t('invite_contractors_modal_types_of_work_modal_title', {
              ns: Namespaces.TenderPage,
              defaultValue: 'Select type of work',
            })}
            description={t('invite_contractors_modal_types_of_work_modal_description', {
              ns: Namespaces.TenderPage,
              defaultValue: 'Select the types of work to find suitable companies',
            })}
          />
        ),
        customSx: HIERARCHICAL_ITEMS_MODAL_SX_STYLES,
      });
    };

    const tenderTypeOfWorkModalFilterProps = useMemo(
      () => ({
        id,
        'data-testid': dataTestId,
        className: classNames,
        ref: mergedRef,
      }),
      [id, dataTestId, classNames, mergedRef],
    );

    return (
      <div {...tenderTypeOfWorkModalFilterProps}>
        <TextField
          label={t('type_of_work_modal_filter_label', { defaultValue: 'Types of work' })}
          onClick={handleControlClick}
          className="!cursor-pointer w-full h-full"
          customSx={{
            '& .MuiOutlinedInput-notchedOutline': {
              borderRadius: rounded ? '100px' : '4px',
              height: '60px',
              width: '100%',
              padding: '4px',
            },
          }}
          InputProps={{
            inputProps: {
              className: typesOfWorks.length ? '!hidden' : '',
            },
            placeholder:
              typesOfWorks.length === 0
                ? t('tender_form_type_of_work_placeholder', {
                    ns: Namespaces.CreateTenderPage,
                  })
                : '',
            startAdornment:
              typesOfWorks.length > 0 ? (
                <div className="flex items-center gap-2 cursor-pointer p-spacing-1 w-full h-[56px]">
                  <Chip
                    label={t(typesOfWorks[0].label.replace(/-/g, '_'), { ns: Namespaces.TypesOfWork })}
                    variant="filled"
                    size={'small'}
                    sx={{
                      minWidth: 0,
                      '& .MuiChip-label': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: '100%',
                      },
                    }}
                  />
                  {typesOfWorks.length > 1 && (
                    <Chip label={`+${typesOfWorks.length - 1} more`} sx={{ flexShrink: 0 }} size={'small'} />
                  )}
                </div>
              ) : null,
          }}
          readOnly
        />
      </div>
    );
  },
);

TypeOfWorkModalFilter.displayName = 'TypeOfWorkModalFilter';

export default TypeOfWorkModalFilter;
