import { Link } from './link';
import { Company } from './company';
import { Attachment, AttachmentCategory } from './attachment';
import { File } from './file.ts';

export enum OfferState {
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  DRAFT = 'draft',
  RECEIVED = 'received',
  DISABLED = 'disabled',
}

interface Discount {
  percent?: number;
  amount?: number;
  period_in_days?: number;
}

interface Deduction {
  name: string;
  percent?: number | null;
  absolute?: number | null;
}

export interface OfferContact {
  company_name: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  fax: string | null;
  mobile: string;
  function: string;
  logo_url: string | null;
  company: Company;
}

export interface OfferAttachment {
  id: string;
  category: AttachmentCategory;
  name: string;
  description?: string;
  extension?: string;
  file: File;
}

export interface OfferTermDocument {
  date_of_acceptance: string | null;
  terms_attachment: Attachment;
  file_uid: string;
}

export interface Offer {
  uid: string;
  tender_uid: string;
  bidder_uid: string;
  bidder_name: string;
  bidder_email?: string;
  is_bidder_blacklisted: boolean;
  state: OfferState;
  created_by: string;
  state_modified_at: string;
  net_incl_vat: number;
  created_at: string;
  modified_at: string;
  link: string;
  links: Link[];
  subsequent: boolean;
  email_id?: number;
  gross_excl_vat: number;
  net_fixed_price_excl_vat?: number;
  discount?: Discount;
  early_payment_discount?: Discount;
  deductions_before_vat?: {
    total_deduction: number;
    deductions?: Deduction[];
  };
  intermediate_result?: number;
  net_excl_vat?: number;
  net_before_vat?: number;
  vat?: number;
  vat_percentage?: number;
  is_blacklisted?: boolean;
  description?: string;
  benchmark?: boolean;
  hide?: boolean;
  deviation?: number;
  contact: OfferContact;
  attachments: OfferAttachment[];
  terms_conditions_accepted?: boolean;
  terms?: OfferTermDocument[];
  name?: string;
  last_published_at?: string;
  last_inspection_issuer_at?: string;
  external?: boolean;
  date_time_received?: string;
  _links?: {
    update?: Link;
  };
  rejection_message?: string;
  accepted_message?: string;
}
