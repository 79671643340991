import { DateCalendar as MUIDateCalendar } from '@mui/x-date-pickers';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import cx from 'classnames';
import { isAfter, isBefore } from 'date-fns';
import { forwardRef, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../button';

export interface DateCalendarProps extends CoreComponentProps {
  value?: Date;
  onChange?: (value: Date) => void;
  onBlur?: (e?: any) => void;
  showToday?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

export const DateCalendar: CoreComponent<DateCalendarProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  DateCalendarProps
>(
  (
    {
      id,
      'data-testid': dataTestId,
      className,
      value,
      onChange,
      onBlur,
      showToday = true,
      minDate,
      maxDate,
      ...otherProps
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const internalRef = useRef<HTMLDivElement>(null);
    const mergedRef = useMergedRef(ref, internalRef);
    const classNames = cx(className, 'flex flex-col');

    const dateCalendarProps = useMemo(
      () => ({
        id,
        'data-testid': dataTestId,
        className: classNames,
        ref: mergedRef,
      }),
      [id, dataTestId, classNames, mergedRef],
    );

    const today = useMemo(() => new Date(), []);
    const isTodayDisabled = useMemo(() => {
      if (minDate && isBefore(today, minDate)) return true;
      if (maxDate && isAfter(today, maxDate)) return true;
      return false;
    }, [today, minDate, maxDate]);

    const handleTodayClick = () => {
      if (onChange && !isTodayDisabled) {
        onChange(today);
      }
    };

    return (
      <div {...dateCalendarProps}>
        <MUIDateCalendar
          {...otherProps}
          value={value}
          onChange={onChange}
          minDate={minDate}
          maxDate={maxDate}
          sx={{
            width: 'unset',
            '& .MuiPickersCalendarHeader-root': {
              paddingLeft: 'unset',
              marginTop: 'unset',
            },
          }}
        />
        {showToday && (
          <Button.Root
            className={`${!isTodayDisabled && '!pl-0 !text-primary'} w-fit self-start`}
            type={Button.Root.types.GHOST}
            onClick={handleTodayClick}
            disabled={isTodayDisabled}
          >
            {t('today', { defaultValue: 'Today' })}
          </Button.Root>
        )}
      </div>
    );
  },
);

DateCalendar.displayName = 'DateCalendar';

export default DateCalendar;
