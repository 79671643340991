import { TreeViewBaseItem } from '@mui/x-tree-view';
import { TFunction } from 'i18next';

export function filterTreeItems(items: TreeViewBaseItem[], searchValue: string, t: TFunction): TreeViewBaseItem[] {
  return items.reduce<TreeViewBaseItem[]>((acc, item) => {
    const label = t(item.label.toString());
    if (label.toLowerCase().includes(searchValue.toLowerCase())) {
      acc.push(item);
    } else if (item.children) {
      const filteredChildren = filterTreeItems(item.children, searchValue, t);
      if (filteredChildren.length > 0) {
        acc.push({ ...item, children: filteredChildren });
      }
    }
    return acc;
  }, []);
}
