import { Popover as MuiPopover, SxProps } from '@mui/material';
import { ComponentDefaultTestId, getTestId } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, ElementContent, withStaticProps } from '@react-fe/core';
import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { PopoverAnchorOriginHorizontal, PopoverAnchorOriginVertical } from './popover.contants';
import styles from './popover.module.scss';

export interface PopoverProps extends CoreComponentProps {
  anchorEl?: HTMLElement | null;
  customSx?: SxProps;
  anchorOriginVertical?: PopoverAnchorOriginVertical;
  anchorOriginHorizontal?: PopoverAnchorOriginHorizontal;
  children?: ElementContent;
  handleClose?: () => void;
}

export interface PopoverHandler {
  openPopover: (event: React.MouseEvent<HTMLElement>) => void;
  closePopover: () => void;
}

const PopoverComponent: CoreComponent<PopoverProps, PopoverHandler> & {
  anchorOriginVertical?: typeof PopoverAnchorOriginVertical;
  anchorOriginHorizontal?: typeof PopoverAnchorOriginHorizontal;
} = forwardRef<PopoverHandler, PopoverProps>(
  (
    {
      id,
      'data-testid': dataTestId,
      className,
      children,
      customSx,
      anchorEl: parentAnchorEl,
      handleClose: parentHandleClose,
      anchorOriginVertical = Popover.anchorOriginVertical?.BOTTOM,
      anchorOriginHorizontal = Popover.anchorOriginHorizontal?.LEFT,
    },
    ref,
  ) => {
    const [innerAnchorEl, setInnerAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(parentAnchorEl || innerAnchorEl);

    const handleClose = () => {
      setInnerAnchorEl(null);
      parentHandleClose?.();
    };

    useImperativeHandle(ref, () => ({
      openPopover(event: React.MouseEvent<HTMLElement>) {
        setInnerAnchorEl(event.currentTarget);
      },
      closePopover() {
        setInnerAnchorEl(null);
      },
    }));

    const popoverProps = useMemo(() => {
      const props: Record<string, unknown> = {
        id,
        'data-testid': dataTestId || getTestId(ComponentDefaultTestId.POPOVER, id),
        className,
        anchorEl: parentAnchorEl || innerAnchorEl,
        onClose: handleClose,
        classes: {
          paper: styles['popover-paper'],
        },
        anchorOrigin: {
          vertical: anchorOriginVertical,
          horizontal: anchorOriginHorizontal,
        },
        sx: {
          marginTop: 1,
          padding: 2,
          ...customSx,
        },
      };
      return props;
    }, [
      parentAnchorEl,
      innerAnchorEl,
      anchorOriginHorizontal,
      anchorOriginVertical,
      className,
      dataTestId,
      id,
      customSx,
    ]);

    return (
      <MuiPopover open={open} {...popoverProps}>
        {children}
      </MuiPopover>
    );
  },
);

export const Popover = withStaticProps(PopoverComponent, {
  anchorOriginVertical: PopoverAnchorOriginVertical,
  anchorOriginHorizontal: PopoverAnchorOriginHorizontal,
});

Popover.displayName = 'Popover';

export default Popover;
