import { StateCreator } from 'zustand';
import { ToastConfiguration } from '../hooks';

export interface ToastState {
  toastOpen: boolean;
  toastConfiguration: ToastConfiguration | null;
  setToastConfiguration: (toastConfiguration: ToastConfiguration) => void;
  showToast: () => void;
  hideToast: () => void;
}

export const createToastState: StateCreator<ToastState> = set => ({
  toastOpen: false,
  toastConfiguration: null,
  setToastConfiguration: (toastConfiguration: ToastConfiguration) => {
    set({ toastConfiguration });
  },
  showToast: () => {
    set({ toastOpen: true });
  },
  hideToast: () => {
    set({ toastOpen: false });
    setTimeout(() => {
      set({ toastConfiguration: null });
    }, 100);
  },
});
