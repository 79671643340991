import { Language } from '@react-fe/i18n-utils';
import { normalizeLanguage } from '../utils/normalize-language';
import { LANGUAGES as LANGUAGES_SIMPLE } from './languages-simple';
import GB from '@react-fe/i18n-utils/assets/country-flags/GB.svg';
import CH from '@react-fe/i18n-utils/assets/country-flags/CH.svg';
import DE from '@react-fe/i18n-utils/assets/country-flags/DE.svg';
import PT from '@react-fe/i18n-utils/assets/country-flags/PT.svg';
import SE from '@react-fe/i18n-utils/assets/country-flags/SE.svg';
import { Locale } from 'date-fns';
import { de, enGB, pt, sv, itCH } from 'date-fns/locale';

const COUNTRY_CODE_TO_SVG_FLAG: { [key: string]: string } = {
  GB,
  DE,
  CH,
  PT,
  SE,
};

export const LANGUAGES: Language[] = LANGUAGES_SIMPLE.map(language => ({
  ...language,
  normalizedCountryCode: normalizeLanguage(language.value.code),
  flag: COUNTRY_CODE_TO_SVG_FLAG[language.countryCode],
}));

export const localeMap: { [key: string]: Locale } = {
  pt: pt,
  de: de,
  'en-GB': enGB,
  'sv-SE': sv,
  'it-CH': itCH,
  sv: sv,
};

export const getLocaleByCode = (localeStr: string): Locale => {
  return localeMap[localeStr];
};
