import { ComponentDefaultTestId, DateCalendar, DateCalendarProps, getTestId } from '@react-fe/common-ui';
import { CoreComponent } from '@react-fe/core';
import { useField } from 'formik';
import { forwardRef, useCallback, useMemo } from 'react';
import { FormError } from '../form-error';
import { FormControlRootProps } from './form-control-root';

type FormControlMergedProps = FormControlRootProps & DateCalendarProps;

export const FormControlDateCalendar: CoreComponent<FormControlMergedProps, HTMLInputElement> = forwardRef<
  HTMLInputElement,
  FormControlMergedProps
>(({ id, 'data-testid': dataTestId, className, name = '', onChange: parentOnChange, ...otherProps }, ref) => {
  const [field, , helpers] = useField(name);
  const { setValue, setTouched } = helpers;

  const onChange = useCallback(
    (value: Date) => {
      setValue(value, true).catch();
      setTimeout(() => {
        setTouched(true, true).catch();
      }, 0);

      if (parentOnChange) {
        parentOnChange(value);
      }
    },
    [parentOnChange, setValue, setTouched],
  );

  const formControlMergedProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId || getTestId(ComponentDefaultTestId.FORM_CONTROL_DATE_CALENDAR, id),
      className,
      ...otherProps,
    }),
    [id, dataTestId, className, otherProps],
  );

  return (
    <div className="flex flex-col max-w-[282px]">
      <DateCalendar {...formControlMergedProps} {...field} onChange={onChange} />
      <FormError field={field} />
    </div>
  );
});

FormControlDateCalendar.displayName = 'FormControl.Datepicker';

export default FormControlDateCalendar;
