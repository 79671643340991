import React, { forwardRef, useMemo, useRef } from 'react';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import useBaseStore from '@react-fe/expertunity-base/stores';
import { FormControl, Heading } from '@react-fe/common-ui';
import { Namespaces } from '@react-fe/expertunity-base/constants';
import { OfferFormControlName } from '../../../../create-offer-modal.constants';

export type CreateOfferOnBehalfFieldsProps = CoreComponentProps;

export const CreateOfferOnBehalfFields: CoreComponent<CreateOfferOnBehalfFieldsProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  CreateOfferOnBehalfFieldsProps
>(({ id, 'data-testid': dataTestId, className }, ref) => {
  const { t } = useTranslation();
  const { isCreateOfferOnBehalf } = useBaseStore();
  const internalRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx(className, 'flex flex-col gap-spacing-2');

  const createOfferOnBehalfFieldsProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      ref: mergedRef,
    }),
    [id, dataTestId, classNames, mergedRef],
  );

  const today = useMemo(() => new Date(), []);

  if (!isCreateOfferOnBehalf) {
    return null;
  }

  return (
    <div {...createOfferOnBehalfFieldsProps}>
      <Heading variant={Heading.variants.H5}>
        {t('create_offer_on_behalf_fields_title', {
          ns: Namespaces.SellerCreateOfferPage,
          defaultValue: 'Offer details',
        })}
      </Heading>

      <div className="flex flex-col gap-spacing-2">
        <FormControl.Root name={OfferFormControlName.DATE_TIME_RECEIVED_FIELD}>
          <FormControl.Datepicker
            maxDate={today}
            dateTime={true}
            label={t('create_offer_on_behalf_fields_date_time_received_label', {
              ns: Namespaces.SellerCreateOfferPage,
              defaultValue: 'Offer received on',
            })}
            required
          />
        </FormControl.Root>

        <FormControl.Root name={OfferFormControlName.CREATED_BY_FIELD}>
          <FormControl.TextField
            label={t('create_offer_on_behalf_fields_entered_by_label', {
              ns: Namespaces.SellerCreateOfferPage,
              defaultValue: 'Entered by',
            })}
            readOnly
          />
        </FormControl.Root>
      </div>
    </div>
  );
});

CreateOfferOnBehalfFields.displayName = 'CreateOfferOnBehalfFields';

export default CreateOfferOnBehalfFields;
