import { CoreComponent, CoreComponentProps, ElementContent, useMergedRef } from '@react-fe/core';
import cx from 'classnames';
import { forwardRef, useMemo, useRef } from 'react';
import { Text } from '../text';

export type LinkProps = CoreComponentProps & {
  href?: string;
  target?: string;
  textClassName?: string;
  component?: string;
  children: ElementContent;
};

export const Link: CoreComponent<LinkProps, HTMLAnchorElement> = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ id, 'data-testid': dataTestId, className, href, target, textClassName, component, children }, ref) => {
    const internalRef = useRef<HTMLAnchorElement>(null);
    const mergedRef = useMergedRef(ref, internalRef);
    const classNames = cx(className);

    const linkProps = useMemo(
      () => ({
        id,
        'data-testid': dataTestId,
        className: classNames,
        ref: mergedRef,
        href,
        target,
      }),
      [id, dataTestId, classNames, mergedRef, href, target],
    );

    return (
      <a {...linkProps}>
        <Text className={cx('!underline !text-info', textClassName)} component={component as any}>
          {children}
        </Text>
      </a>
    );
  },
);

Link.displayName = 'Link';

export default Link;
