import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { Icon, Menu, MenuItem, Text } from '@react-fe/common-ui';
import { Namespaces } from '@react-fe/expertunity-base/constants';
import { CategorizedAttachmentState, useTypedBaseStore } from '@react-fe/expertunity-base/stores';
import { useTranslation } from 'react-i18next';

export interface CategorizedAttachmentMenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  index: number;
  onClose: () => void;
  handleEditCategoryInput: () => void;
  remove: (index: number) => void;
}

export const CategorizedAttachmentMenu: React.FC<CategorizedAttachmentMenuProps> = ({
  anchorEl,
  open,
  index,
  onClose,
  handleEditCategoryInput,
  remove,
}) => {
  const { t } = useTranslation();
  const { setLastFocusedAttachmentIndex } = useTypedBaseStore<CategorizedAttachmentState>();

  const handleClose = () => {
    setLastFocusedAttachmentIndex(null);
    onClose();
  };

  const handleOnRemove = () => {
    remove(index);
    handleClose();
  };

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
      <MenuItem onClick={handleEditCategoryInput}>
        {t('show_edit_category_input', { ns: Namespaces.CategorizedAttachment, defaultValue: 'Edit category' })}
      </MenuItem>
      <MenuItem onClick={handleOnRemove}>
        <Icon className="text-error" icon={faTrash} />
        <Text color={Text.colors.ERROR}>
          {t('categorized_attachment_form_remove_label', {
            ns: Namespaces.CategorizedAttachment,
            defaultValue: 'Delete file',
          })}
        </Text>
      </MenuItem>
    </Menu>
  );
};
