import { faSearch, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { InputAdornment as MuiInputAdornment, TextField as MuiTextField } from '@mui/material';
import { CoreComponent, CoreComponentProps } from '@react-fe/core';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Button, ComponentDefaultTestId, getTestId, Icon, TextFieldVariant } from '../../';
import { SearchFieldVariant } from './search-field.constants';

export interface SearchFieldProps extends CoreComponentProps {
  onChange: (value: string) => void;
  placeholder?: string;
  defaultValue?: string;
  label: string;
  variant?: TextFieldVariant | SearchFieldVariant;
  disabled?: boolean;
}

export interface SearchFieldRef extends Partial<HTMLInputElement> {
  clear: () => void;
  setValue: (value: string) => void;
}

export const SearchField: CoreComponent<SearchFieldProps, SearchFieldRef> = forwardRef<
  SearchFieldRef,
  SearchFieldProps
>(
  (
    {
      id,
      'data-testid': dataTestId,
      className,
      onChange,
      placeholder,
      label,
      defaultValue,
      variant = TextFieldVariant.STANDARD,
      disabled,
    },
    ref,
  ) => {
    const internalVariant = variant === SearchFieldVariant.OUTLINED_RECTANGLE ? TextFieldVariant.OUTLINED : variant;
    const isRectangularVariant = variant === SearchFieldVariant.OUTLINED_RECTANGLE;
    const [searchTerm, setSearchTerm] = useState(defaultValue);

    useImperativeHandle(ref, () => ({
      clear: () => setSearchTerm(''),
      setValue: (value: string) => setSearchTerm(value),
    }));

    useEffect(() => {
      setSearchTerm(defaultValue);
    }, [defaultValue]);

    const searchFieldProps = useMemo(
      () => ({
        id,
        'data-testid': dataTestId || getTestId(ComponentDefaultTestId.SEARCH_FIELD, id),
        className,
        placeholder,
        label,
        variant,
        disabled,
      }),
      [id, dataTestId, className, placeholder, label, variant, disabled],
    );

    const _onChange = useCallback(
      (query: string) => {
        setSearchTerm(query);
        if (query.length >= 3 || query.length === 0) {
          const delayDebounceFn = setTimeout(() => {
            onChange(query);
          }, 200);
          return () => clearTimeout(delayDebounceFn);
        }
      },
      [onChange],
    );

    return (
      <MuiTextField
        {...searchFieldProps}
        value={searchTerm}
        variant={internalVariant}
        size="medium"
        onChange={e => _onChange(e.target.value)}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: !isRectangularVariant ? '100px' : undefined,
          },
          ...(isRectangularVariant && {
            '& .MuiInputLabel-root': {
              top: '4px',
              left: '4px',
            },
          }),
        }}
        InputProps={{
          startAdornment: (
            <MuiInputAdornment position="start" className="p-spacing-1">
              <Icon icon={faSearch} />
            </MuiInputAdornment>
          ),
          endAdornment: searchTerm && searchTerm?.length > 0 && (
            <MuiInputAdornment position="end" className="p-spacing-1">
              <Button.Root
                className="!pr-0 focus:!text-grey-600"
                type={Button.Root.types.GHOST}
                onClick={() => _onChange('')}
              >
                <Button.Icon icon={faXmark} />
              </Button.Root>
            </MuiInputAdornment>
          ),
        }}
      />
    );
  },
);

SearchField.displayName = 'SearchField';

export default SearchField;
