import React, { useCallback, useState } from 'react';
import { ElementContent } from '@react-fe/core';
import { LocaleContext } from '../../context/locale-context';
import { de, Locale } from 'date-fns/locale';

export const LocaleProvider: React.FC<{ children: ElementContent }> = ({ children }) => {
  const [locale, setLocale] = useState(de);
  const switchLocale = useCallback((newLocale: Locale) => {
    setLocale(newLocale);
  }, []);

  return <LocaleContext.Provider value={{ locale, switchLocale }}>{children}</LocaleContext.Provider>;
};
