import { Language } from '@react-fe/i18n-utils';
import { de, enGB, itCH, pt, sv } from 'date-fns/locale';

export const LANGUAGES: Omit<Language, 'flag'>[] = [
  { value: enGB, label: 'English', countryCode: 'GB', countryName: 'United Kingdom' },
  { value: de, label: 'Deutsch', countryCode: 'CH', countryName: 'Schweiz' },
  { value: itCH, label: 'Italian', countryCode: 'CH', countryName: 'Svizzera' },
  { value: pt, label: 'Português', countryCode: 'PT', countryName: 'Portugal' },
  { value: sv, label: 'Svenska', countryCode: 'SE', countryName: 'Sverige' },
];
