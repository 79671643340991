import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../../components/header/header';
import Nav from '../../components/nav/nav';
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs';
import { useAuth } from 'react-oidc-context';
import useBaseStore from '@react-fe/expertunity-base/stores';
import { ModalProvider } from '../../components/modal-provider';

export function Root() {
  const auth = useAuth();
  const { setCurrentUser } = useBaseStore();

  useEffect(() => {
    if (auth?.user?.profile) {
      const { client_uid, name, uid, email } = auth.user.profile;
      setCurrentUser({
        uid: uid as string,
        name: name as string,
        client_uid: client_uid as string,
        email: email as string,
      });
    }
  }, [auth?.user?.profile, setCurrentUser]);

  return (
    <div className="min-h-screen flex bg-[#F7F9FC]">
      <Nav />
      <main className={'grow flex flex-col pr-spacing-4 pb-spacing-4 pl-spacing-3 overflow-x-hidden'}>
        <div className="sticky top-0 z-10 pt-spacing-4 pb-spacing-3 bg-[#F7F9FC]">
          <Header />
          <Breadcrumbs />
        </div>
        <ModalProvider>
          <Outlet />
        </ModalProvider>
      </main>
    </div>
  );
}

export default Root;
