import { Tooltip, TooltipProps } from './tooltip';
import { forwardRef } from 'react';
import { CoreComponent } from '@react-fe/core';
import { tooltipClasses as muiTooltipClasses, TooltipClasses as MuiTooltipClasses } from '@mui/material';
import cx from 'classnames';

export type NoMaxWidthTooltipProps = TooltipProps;

export const NoMaxWidthTooltip: CoreComponent<NoMaxWidthTooltipProps> = forwardRef<HTMLElement, NoMaxWidthTooltipProps>(
  ({ ...props }, ref) => {
    const { children, ...otherProps } = props;

    const modifiedClass = cx(muiTooltipClasses.tooltip, ` !max-w-full `);
    const classes: Partial<MuiTooltipClasses> = {
      tooltip: modifiedClass,
    };

    return (
      <Tooltip {...otherProps} classes={classes}>
        {children}
      </Tooltip>
    );
  },
);
