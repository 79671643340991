import React, { forwardRef } from 'react';
import { CoreComponent, CoreComponentProps } from '@react-fe/core';
import { faQuestion, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconSize } from './icon.constant';

export interface IconProps extends CoreComponentProps {
  icon: IconDefinition;
  color?: string;
  size?: IconSize;
  className?: string;
}

export const Icon: CoreComponent<IconProps, SVGSVGElement> = forwardRef(
  ({ icon = faQuestion, id, 'data-testid': dataTestId, className, color, size = IconSize.SMALL }, ref) => {
    return (
      <FontAwesomeIcon
        icon={icon}
        color={color}
        size={size}
        className={className}
        id={id}
        data-testid={dataTestId}
        ref={ref}
      />
    );
  },
);

Icon.displayName = 'Icon';

export default Icon;
