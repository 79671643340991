import { ForwardedRef, MutableRefObject, RefObject, useLayoutEffect, useRef } from 'react';

/**
 * Returns a single ref callback that merges multiple ref callbacks
 * @param refs
 */
export const useMergedRef = <T>(...refs: (RefObject<T> | ForwardedRef<T> | null)[]): RefObject<T> => {
  const mergedRef = useRef<T>(null);

  useLayoutEffect(() => {
    refs.forEach(ref => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(mergedRef.current);
      } else {
        (ref as MutableRefObject<T | null>).current = mergedRef.current;
      }
    });
  }, [refs]);

  return mergedRef;
};

export default useMergedRef;
