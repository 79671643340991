import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { logger } from '@react-fe/core';
import { useParams } from 'react-router-dom';
import { CreateOffer, QueryKeys } from '@react-fe/expertunity-base/models';
import { useBaseStore } from '@react-fe/expertunity-base/stores';
import { API_URL } from '@react-fe/expertunity-base/constants';

const getEndpoint = (bidderUid: string, tenderUid: string, isCreateOfferOnBehalf: boolean) => {
  const baseURL = `${API_URL}/tenders/${tenderUid}/bidders/${bidderUid}`;

  if (isCreateOfferOnBehalf) {
    return `${baseURL}/create-offer-as-issuer-for-bidder`;
  }

  return `${baseURL}/offers`;
};

export const useCreateOffer = () => {
  const { tenderUid } = useParams();
  const { isCreateOfferOnBehalf, currentBidderUid } = useBaseStore();
  const { currentUser } = useBaseStore();

  const endpoint = getEndpoint(currentBidderUid ?? currentUser.client_uid, tenderUid as string, isCreateOfferOnBehalf);
  const queryClient = useQueryClient();

  if (!tenderUid) {
    logger.error(
      'useCreateOffer - useParams could not find tenderUid, check the route or the context that the hook is being called',
    );
  }

  return useMutation({
    mutationFn: async (offerData: Partial<CreateOffer>) => {
      const response = await axios.post(endpoint, offerData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    },
    onSuccess: (data, variables) => {
      logger.debug('Offer created successfully:', data);

      queryClient
        .invalidateQueries({ queryKey: ['offers'] })
        .then(() => logger.debug("invalidateQueries run with params { queryKey: ['offers'] }."));

      queryClient
        .invalidateQueries({ queryKey: [QueryKeys.TENDER_OFFERS] })
        .then(() => logger.debug("invalidateQueries run with params { queryKey: ['QueryKeys.TENDER_OFFERS'] }."));

      queryClient
        .invalidateQueries({ queryKey: ['my-offers'] })
        .then(() => logger.debug("invalidateQueries run with params { queryKey: ['my-offers'] }."));

      queryClient
        .invalidateQueries({ queryKey: ['bidders'] })
        .then(() => logger.debug("invalidateQueries run with params { queryKey: ['bidders'] }."));
    },
    onError: error => {
      logger.error('Error creating offer:', error);
    },
  });
};

export default useCreateOffer;
