import React, { ReactNode, useState } from 'react';
import { Icon, IconSize } from '../icon';
import { Heading } from '../heading';
import { Text } from '../text';
import { faEllipsisVertical, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { Button, Menu } from '@react-fe/common-ui';

interface TitleProps {
  title: string;
  subTitle?: string;
  icon?: IconDefinition;
  children?: ReactNode;
  menuContent?: React.ReactElement<{ onClose?: () => void }>;
}

export function Title({ title, subTitle, icon, children, menuContent }: Readonly<TitleProps>) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => setAnchorEl(null);

  const enhancedMenuContent = menuContent ? React.cloneElement(menuContent, { onClose: handleClose }) : null;

  return (
    <div className={'flex justify-between items-center'}>
      <div className={'flex gap-spacing-1'}>
        {icon && (
          <div className={'flex items-center justify-center h-[2rem] w-[1rem]'}>
            <Icon size={IconSize.BASE} icon={icon} />
          </div>
        )}
        <div className={'flex flex-col gap-spacing-0.5'}>
          <Heading variant={Heading.variants.H5}>{title}</Heading>
          {subTitle && (
            <Text variant={Text.variants.BODY2} color={Text.colors?.TEXT_SECONDARY}>
              {subTitle}
            </Text>
          )}
        </div>
        {menuContent && (
          <Button.Root
            type={Button.Root.types?.GHOST}
            size={Button.Root.sizes.SMALL}
            onClick={e => setAnchorEl(e.currentTarget)}
          >
            <Button.Icon icon={faEllipsisVertical} />
          </Button.Root>
        )}
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {enhancedMenuContent}
        </Menu>
      </div>
      {children}
    </div>
  );
}

export default Title;
