import { createContext } from 'react';

export interface Breadcrumb {
  [key: string]: { to: string; name: string };
}

export interface BreadcrumbContextType {
  breadcrumbs: Breadcrumb;
  setBreadcrumb: (breadcrumb: Breadcrumb) => void;
}

export const BreadcrumbContext = createContext<BreadcrumbContextType | undefined>(undefined);
