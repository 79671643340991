import { faFile, faFilePdf, faFileXls } from '@fortawesome/pro-regular-svg-icons';
import { Icon, Text, Tooltip } from '@react-fe/common-ui';
import { useDateFormatter } from '@react-fe/core';
import { Attachment } from '@react-fe/expertunity-base/models';
import { useFileSize } from '@react-fe/expertunity-base/hooks';
import { CategorizedAttachmentFormControlNames } from '../../../../categorized-attachments-area.constants';
import { useDownloadFile } from '../../../../hooks/use-download-file';

export interface CategorizedAttachmentNameProps {
  name: string;
  index: number;
  attachment: Attachment;
  form: { values: any };
}

export const CategorizedAttachmentName: React.FC<CategorizedAttachmentNameProps> = ({
  name,
  index,
  attachment,
  form,
}: CategorizedAttachmentNameProps) => {
  const { dateFormatter } = useDateFormatter();
  const downloadFile = useDownloadFile(attachment);
  const fileSize = useFileSize(attachment.file?.length);
  const attachmentIcon = attachment?.file?.name?.includes('xls')
    ? faFileXls
    : attachment?.file?.name?.includes('pdf')
      ? faFilePdf
      : faFile;

  return (
    <div className="flex items-center w-[30%] cursor-pointer" onClick={downloadFile}>
      <Tooltip title={form.values[name][index][CategorizedAttachmentFormControlNames.NAME]}>
        <div className="flex items-center gap-spacing-2">
          <div className="flex grow flex-shrink-0 items-center justify-center w-[40px] h-[40px]">
            <Icon className="!h-[16px] !w-[16px]" icon={attachmentIcon} />
          </div>

          <div className="flex flex-col justify-center gap-spacing-0.5 overflow-x-hidden">
            <Text ellipsis>{form.values[name][index][CategorizedAttachmentFormControlNames.NAME]}</Text>

            <div className="flex items-center gap-spacing-0.5">
              <Text className="leading-[0px]" variant={Text.variants.CAPTION} color={Text.colors.TEXT_SECONDARY}>
                {fileSize || '0 kB'}
              </Text>
              <Text color={Text.colors.TEXT_SECONDARY}>•</Text>
              <Text className="leading-[0px]" variant={Text.variants.CAPTION} color={Text.colors.TEXT_SECONDARY}>
                {dateFormatter(form.values[name][index]?.file?.createdAt)}
              </Text>
            </div>
          </div>
        </div>
      </Tooltip>
    </div>
  );
};
