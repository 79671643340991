import { CoreComponentProps, ElementContent } from '@react-fe/core';
import { Badge as MUIBadge } from '@mui/material';
import { ReactNode } from 'react';

export interface BadgeProps extends CoreComponentProps {
  children: ElementContent;
  variant?: 'standard' | 'dot';
  content?: ReactNode;
  invisible?: boolean;
}

export function Badge({ children, content, invisible = false, variant = 'standard' }: BadgeProps) {
  return (
    <MUIBadge
      badgeContent={content}
      classes={{ badge: 'bg-info text-primary-contrast' }}
      variant={variant}
      invisible={invisible}
      overlap="circular"
      sx={{
        '& .MuiBadge-badge': {
          fontSize: '0.75rem',
          lineHeight: '166.667%',
        },
      }}
    >
      {children}
    </MUIBadge>
  );
}

export default Badge;
