import i18nConfiguration from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { LANGUAGES } from '@react-fe/i18n-utils';
import { resources } from './resources.ts';
import { NAMESPACES_LIST } from '../constants';

i18nConfiguration
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'en-GB',
    ns: NAMESPACES_LIST,
    supportedLngs: [...LANGUAGES.map(language => language.value.code), 'en'],
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
    },
    resources,
  });

export { i18nConfiguration };
