import { AttachmentCard } from '@react-fe/expertunity-base';
import { Attachment } from '@react-fe/expertunity-base/models';
import { WorkDescriptionAttachmentTitle } from './work-description-attachment-title';

export const WorkDescriptionAttachments = ({
  workDescriptionTemplates,
}: {
  workDescriptionTemplates: Attachment[];
}) => {
  if (!workDescriptionTemplates.length) return null;

  return (
    <div className="flex flex-col gap-spacing-2">
      <WorkDescriptionAttachmentTitle />
      <div className="flex flex-col gap-spacing-1">
        {workDescriptionTemplates?.map(template => (
          <AttachmentCard key={template.uid} attachment={template} iconClassname="text-info" />
        ))}
      </div>
    </div>
  );
};
