import React, { forwardRef, useId, useMemo, useRef } from 'react';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import {
  FormControl as MUIFormControl,
  FormControlLabel as MUIFormControlLabel,
  FormHelperText as MUIFormHelperText,
  FormLabel as MUIFormLabel,
  Radio as MUIRadio,
  RadioGroup as MUIRadioGroup,
} from '@mui/material';
import { RadioGroupItem } from './radio-group.constants';
import { ComponentDefaultTestId, getTestId } from '@react-fe/common-ui';
import cx from 'classnames';

export interface RadioGroupProps extends CoreComponentProps {
  items: RadioGroupItem[];
  label?: string;
  value?: string;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  helperText?: string;
  error?: boolean;
  required?: boolean;
}

export const RadioGroup: CoreComponent<RadioGroupProps, HTMLDivElement> = forwardRef<HTMLDivElement, RadioGroupProps>(
  (
    { id, 'data-testid': dataTestId, className, items, value, onChange, label, name, helperText, error, required },
    ref,
  ) => {
    const internalRef = useRef<HTMLDivElement>(null);
    const mergedRef = useMergedRef(ref, internalRef);
    const randomId = useId();
    const componentId = id || randomId;
    const classNames = cx(className);

    const radioGroupProps = useMemo(
      () => ({
        id: componentId,
        'data-testid': dataTestId || getTestId(ComponentDefaultTestId.RADIO_GROUP),
        className: classNames,
        ref: mergedRef,
        value,
        name,
        onChange,
      }),
      [componentId, dataTestId, classNames, mergedRef, value, name, onChange],
    );

    return (
      <MUIFormControl error={error} variant={'standard'}>
        <MUIFormLabel id={componentId} required={required}>
          {label}
        </MUIFormLabel>
        <MUIRadioGroup {...radioGroupProps}>
          {items.map(radio => (
            <MUIFormControlLabel
              key={`radio-group-item-${radio.value}`}
              value={radio.value}
              label={radio.label}
              control={<MUIRadio />}
            />
          ))}
        </MUIRadioGroup>
        <MUIFormHelperText>{helperText}</MUIFormHelperText>
      </MUIFormControl>
    );
  },
);

RadioGroup.displayName = 'RadioGroup';

export default RadioGroup;
