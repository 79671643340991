import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export interface Country {
  uid: string;
  short: string;
  long: string;
  phonePrefix: string;
}

export const useCountryList = (): Country[] => {
  const { t } = useTranslation();

  return useMemo(() => {
    return [
      { uid: 'ch', short: 'CH', long: t('country_switzerland', { ns: 'country_list' }), phonePrefix: '+41' },
      { uid: 'de', short: 'DE', long: t('country_germany', { ns: 'country_list' }), phonePrefix: '+49' },
      { uid: 'at', short: 'AT', long: t('country_austria', { ns: 'country_list' }), phonePrefix: '+43' },
      { uid: 'fr', short: 'FR', long: t('country_france', { ns: 'country_list' }), phonePrefix: '+33' },
      { uid: 'it', short: 'IT', long: t('country_italy', { ns: 'country_list' }), phonePrefix: '+39' },
      { uid: 'li', short: 'LI', long: t('country_liechtenstein', { ns: 'country_list' }), phonePrefix: '+423' },
      { uid: 'al', short: 'AL', long: t('country_albania', { ns: 'country_list' }), phonePrefix: '+355' },
      { uid: 'ad', short: 'AD', long: t('country_andorra', { ns: 'country_list' }), phonePrefix: '+376' },
      { uid: 'be', short: 'BE', long: t('country_belgium', { ns: 'country_list' }), phonePrefix: '+32' },
      {
        uid: 'ba',
        short: 'BA',
        long: t('country_bosnia_and_herzegovina', { ns: 'country_list' }),
        phonePrefix: '+387',
      },
      { uid: 'bg', short: 'BG', long: t('country_bulgaria', { ns: 'country_list' }), phonePrefix: '+359' },
      { uid: 'dk', short: 'DK', long: t('country_denmark', { ns: 'country_list' }), phonePrefix: '+45' },
      { uid: 'ee', short: 'EE', long: t('country_estonia', { ns: 'country_list' }), phonePrefix: '+372' },
      { uid: 'fi', short: 'FI', long: t('country_finland', { ns: 'country_list' }), phonePrefix: '+358' },
      { uid: 'gr', short: 'GR', long: t('country_greece', { ns: 'country_list' }), phonePrefix: '+30' },
      { uid: 'ie', short: 'IE', long: t('country_ireland', { ns: 'country_list' }), phonePrefix: '+353' },
      { uid: 'is', short: 'IS', long: t('country_island', { ns: 'country_list' }), phonePrefix: '+354' },
      { uid: 'hr', short: 'HR', long: t('country_croatia', { ns: 'country_list' }), phonePrefix: '+385' },
      { uid: 'lv', short: 'LV', long: t('country_lativa', { ns: 'country_list' }), phonePrefix: '+371' },
      { uid: 'lt', short: 'LT', long: t('country_lithuania', { ns: 'country_list' }), phonePrefix: '+370' },
      { uid: 'lu', short: 'LU', long: t('country_luxembourg', { ns: 'country_list' }), phonePrefix: '+352' },
      { uid: 'mt', short: 'MT', long: t('country_malta', { ns: 'country_list' }), phonePrefix: '+356' },
      { uid: 'mk', short: 'MK', long: t('country_macedonia', { ns: 'country_list' }), phonePrefix: '+389' },
      { uid: 'md', short: 'MD', long: t('country_moldova', { ns: 'country_list' }), phonePrefix: '+373' },
      {
        uid: 'mc',
        short: 'MC',
        long: t('country_principality_of_monaco', { ns: 'country_list' }),
        phonePrefix: '+377',
      },
      { uid: 'me', short: 'ME', long: t('country_montenegro', { ns: 'country_list' }), phonePrefix: '+382' },
      { uid: 'nl', short: 'NL', long: t('country_netherlands', { ns: 'country_list' }), phonePrefix: '+31' },
      { uid: 'no', short: 'NO', long: t('country_norway', { ns: 'country_list' }), phonePrefix: '+47' },
      { uid: 'pl', short: 'PL', long: t('country_poland', { ns: 'country_list' }), phonePrefix: '+48' },
      { uid: 'pt', short: 'PT', long: t('country_portugal', { ns: 'country_list' }), phonePrefix: '+351' },
      { uid: 'ro', short: 'RO', long: t('country_romania', { ns: 'country_list' }), phonePrefix: '+40' },
      { uid: 'sm', short: 'SM', long: t('country_san_marino', { ns: 'country_list' }), phonePrefix: '+378' },
      { uid: 'se', short: 'SE', long: t('country_sweden', { ns: 'country_list' }), phonePrefix: '+46' },
      { uid: 'rs', short: 'RS', long: t('country_serbia', { ns: 'country_list' }), phonePrefix: '+381' },
      { uid: 'sk', short: 'SK', long: t('country_slovakia', { ns: 'country_list' }), phonePrefix: '+421' },
      { uid: 'si', short: 'SI', long: t('country_slovenia', { ns: 'country_list' }), phonePrefix: '+386' },
      { uid: 'es', short: 'ES', long: t('country_spain', { ns: 'country_list' }), phonePrefix: '+34' },
      { uid: 'cz', short: 'CZ', long: t('country_czech_republic', { ns: 'country_list' }), phonePrefix: '+420' },
      { uid: 'tr', short: 'TR', long: t('country_turkey', { ns: 'country_list' }), phonePrefix: '+90' },
      { uid: 'ua', short: 'UA', long: t('country_ukraine', { ns: 'country_list' }), phonePrefix: '+380' },
      { uid: 'hu', short: 'HU', long: t('country_hungary', { ns: 'country_list' }), phonePrefix: '+36' },
      { uid: 'va', short: 'VA', long: t('country_vatican_city', { ns: 'country_list' }), phonePrefix: '+379' },
      { uid: 'gb', short: 'GB', long: t('country_united_kingdom', { ns: 'country_list' }), phonePrefix: '+44' },
    ];
  }, [t]);
};

export default useCountryList;
