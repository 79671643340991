import { CoreComponentProps } from '@react-fe/core';
import { Skeleton as MUISkeleton } from '@mui/material';

export interface SkeletonProps extends CoreComponentProps {
  width?: number | string;
  height: number | string;
  variant: 'text' | 'rectangular' | 'rounded' | 'circular';
}

export function Skeleton(props: Readonly<SkeletonProps>) {
  return <MUISkeleton {...props} role={'presentation'} />;
}

export default Skeleton;
