import { StepButton as MUIStepButton, StepButtonProps as MUIStepButtonProps } from '@mui/material';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import cx from 'classnames';
import { forwardRef, useMemo, useRef } from 'react';

export type StepButtonProps = CoreComponentProps & Omit<MUIStepButtonProps, 'ref'>;

export const StepButton: CoreComponent<StepButtonProps, HTMLButtonElement> = forwardRef<
  HTMLButtonElement,
  StepButtonProps
>(({ id, 'data-testid': dataTestId, className, children, ...otherProps }, ref) => {
  const internalRef = useRef<HTMLButtonElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx(className);

  const stepButtonProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      ref: mergedRef,
      ...otherProps,
    }),
    [id, dataTestId, classNames, mergedRef, otherProps],
  );

  return <MUIStepButton {...stepButtonProps}>{children}</MUIStepButton>;
});

StepButton.displayName = 'StepButton';

export default StepButton;
