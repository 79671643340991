import React, { forwardRef, useMemo } from 'react';
import { CoreComponent } from '@react-fe/core';
import { ComponentDefaultTestId, getTestId } from '@react-fe/common-ui';
import { useField } from 'formik';
import { FormControlRootProps } from './form-control-root';
import { RadioGroup, RadioGroupProps } from '../radio-group';
import { FormError } from '../form-error';

type FormControlMergedProps = FormControlRootProps & RadioGroupProps;

export const FormControlRadioGroup: CoreComponent<FormControlMergedProps, HTMLInputElement> = forwardRef<
  HTMLInputElement,
  FormControlMergedProps
>(({ id, 'data-testid': dataTestId, className, name = '', items, ...otherProps }, ref) => {
  const [field, meta] = useField(name);
  const hasErrors = meta.touched && !!meta.error;

  const formControlMergedProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId || getTestId(ComponentDefaultTestId.FORM_CONTROL_RADIO_GROUP, id),
      className,
      items,
      ...otherProps,
    }),
    [id, dataTestId, className, items, otherProps],
  );

  return (
    <div className="flex flex-col">
      <RadioGroup {...formControlMergedProps} {...field} items={items} error={hasErrors} />
      <FormError field={field} />
    </div>
  );
});

FormControlRadioGroup.displayName = 'FormControl.RadioGroup';

export default FormControlRadioGroup;
