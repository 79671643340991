import { faBookmark as regularFaBookMark } from '@fortawesome/pro-regular-svg-icons';
import { faBookmark as solidFaBookMark } from '@fortawesome/pro-solid-svg-icons';
import { Button, ButtonType, Icon, Spinner, Text } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import cx from 'classnames';
import { forwardRef, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Namespaces } from '../../constants';

export interface FavouriteButtonProps extends CoreComponentProps {
  isFavourite?: boolean;
  isPending?: boolean;
  iconOnly?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const FavouriteButton: CoreComponent<FavouriteButtonProps, HTMLButtonElement> = forwardRef<
  HTMLButtonElement,
  FavouriteButtonProps
>(({ id, 'data-testid': dataTestId, className, isFavourite, isPending, iconOnly, onClick }, ref) => {
  const { t } = useTranslation();
  const internalRef = useRef<HTMLButtonElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);

  const buttonLabel = t('favourite_button_label', { ns: Namespaces.Common, defaultValue: 'Favourite' });
  const ariaLabel = isFavourite
    ? t('unfavourite', { ns: Namespaces.Common, defaultValue: 'Unfavourite' })
    : t('favourite', { ns: Namespaces.Common, defaultValue: 'Favourite' });

  const favouriteButtonProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId || 'test-favourite-button',
      className: cx(className, iconOnly ? '!w-[44px] !bg-transparent' : ''),
      ref: mergedRef,
      type: ButtonType.GHOST,
      disabled: isPending,
      'aria-label': ariaLabel,
      onClick,
    }),
    [id, dataTestId, className, mergedRef, isPending, iconOnly, ariaLabel, onClick],
  );

  if (iconOnly) {
    return (
      <div className="relative min-h-[44px] min-w-[44px]">
        {isPending ? (
          <Spinner
            className="absolute top-spacing-1 right-spacing-1"
            color={Spinner.colors.WARNING}
            size={Spinner.sizes.SMALL}
          />
        ) : (
          <Button.Root {...favouriteButtonProps}>
            {isFavourite ? (
              <Icon icon={solidFaBookMark} className="text-warning-light text-xl" />
            ) : (
              <Icon icon={regularFaBookMark} className="text-warning-light text-xl" />
            )}
          </Button.Root>
        )}
      </div>
    );
  }

  return (
    <Button.Root {...favouriteButtonProps}>
      {isPending ? (
        <Spinner size={Spinner.sizes.SMALL} />
      ) : isFavourite ? (
        <Button.Icon icon={solidFaBookMark} className="text-warning" />
      ) : (
        <Button.Icon icon={regularFaBookMark} className="text-warning" />
      )}
      <Text color={Text.colors.TEXT_PRIMARY}>{buttonLabel}</Text>
    </Button.Root>
  );
});

FavouriteButton.displayName = 'FavoriteButton';

export default FavouriteButton;
