import { faPhoneVolume } from '@fortawesome/pro-regular-svg-icons';
import { Icon, Skeleton, Text } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import { ContactCardPerson } from '@react-fe/expertunity-base/models';
import cx from 'classnames';
import { forwardRef, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactCardContactDetails } from './components/contact-card-contact-details';

export interface ContactCardProps extends CoreComponentProps {
  contact: ContactCardPerson;
  isMainContact?: boolean;
}

export const ContactCard: CoreComponent<ContactCardProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  ContactCardProps
>(({ id, 'data-testid': dataTestId, className, contact, isMainContact = false }, ref) => {
  const internalRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx(className);
  const { t } = useTranslation();

  const contactCardProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      ref: mergedRef,
    }),
    [id, dataTestId, classNames, mergedRef],
  );

  const containerBorder = useMemo(() => {
    return !isMainContact ? 'border-action-divider' : 'border-info-dark bg-info-state-hover';
  }, [isMainContact]);

  return (
    <div
      {...contactCardProps}
      className={cx(['rounded-lg py-spacing-2 px-spacing-3 border border-solid', containerBorder, classNames])}
    >
      {isMainContact && (
        <div className="flex items-center gap-spacing-1 mb-spacing-3">
          <Icon icon={faPhoneVolume} className="text-info-dark"></Icon>
          <Text variant={Text.variants.BODY2_BOLD} className="!text-info-dark">
            {t('contact_card_main_contact', { defaultValue: 'Main contact' })}
          </Text>
        </div>
      )}
      <div className="flex gap-spacing-2">
        {!contact?.firstName ? (
          <div className="w-full flex flex-col gap-spacing-1">
            {[...Array(5).keys()].map(value => (
              <Skeleton key={value} width={200} height={18} variant={'rectangular'} />
            ))}
          </div>
        ) : (
          <ContactCardContactDetails contact={contact} />
        )}
      </div>
    </div>
  );
});

ContactCard.displayName = 'ContactCard';

export default ContactCard;
