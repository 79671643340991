import { useTranslation } from 'react-i18next';
import { Button, Heading } from '@react-fe/common-ui';
import { Link } from 'react-router-dom';

export function Forbidden() {
  const { t } = useTranslation();

  return (
    <div className="h-full flex flex-col items-center justify-center gap-3">
      <div className="flex flex-col items-center">
        <Heading variant={Heading.variants.H3}>403</Heading>
        <Heading>{t('forbidden')}</Heading>
      </div>
      <h1>{t('forbidden_subtitle')}</h1>
      <Link to="/">
        <Button.Root>{t('return_to_dashboard')}</Button.Root>
      </Link>
    </div>
  );
}

export default Forbidden;
