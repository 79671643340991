import { StateCreator } from 'zustand';

export interface CategorizedAttachmentState {
  lastFocusedAttachmentIndex: number | null;
  isSubmittingNewAttachments: boolean;
  canSelectApplicationInformationCategory: boolean;
  setLastFocusedAttachmentIndex: (lastFocusedAttachmentIndex: number | null) => void;
  setIsSubmittingNewAttachments: (isSubmittingNewAttachments: boolean) => void;
  setCanSelectApplicationInformationCategory: (canSelectApplicationInformationCategory: boolean) => void;
}

export const createCategorizedAttachmentSlice: StateCreator<CategorizedAttachmentState> = set => ({
  lastFocusedAttachmentIndex: null,
  isSubmittingNewAttachments: false,
  canSelectApplicationInformationCategory: true,
  setLastFocusedAttachmentIndex: (lastFocusedAttachmentIndex: number | null) => {
    set({ lastFocusedAttachmentIndex: lastFocusedAttachmentIndex });
  },
  setIsSubmittingNewAttachments: (isSubmittingNewAttachments: boolean) => {
    set({ isSubmittingNewAttachments });
  },
  setCanSelectApplicationInformationCategory: (canSelectApplicationInformationCategory: boolean) => {
    set({ canSelectApplicationInformationCategory });
  },
});
