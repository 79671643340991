import React, { forwardRef, useMemo } from 'react';
import { CoreComponent } from '@react-fe/core';
import { ComponentDefaultTestId, getTestId } from '@react-fe/common-ui';
import { Field as FormikField, FieldProps } from 'formik';
import { FormControlRootProps } from './form-control-root';
import { TextEditorInput, TextEditorInputProps } from '../text-editor-input';
import { EditorEvents } from '@tiptap/core';
import { FormError } from '../form-error';

export interface FormControlTextEditorInputProps extends FormControlRootProps, TextEditorInputProps {
  onValueChange?: (value: string) => void;
  helperText?: string;
}

const FormikTextEditorInput: React.FC<FieldProps> = ({ field, form: { touched, errors, setFieldValue }, ...props }) => {
  const hasErrors = touched[field.name] && !!errors?.[field.name];

  const handleTextEditorUpdate = (changed: EditorEvents['update']) => {
    setFieldValue(field.name, changed.editor.getHTML());
  };

  return (
    <div className="flex flex-col">
      <TextEditorInput {...field} {...props} error={hasErrors} onUpdate={handleTextEditorUpdate} />
      <FormError field={field} />
    </div>
  );
};

export const FormControlTextEditor: CoreComponent<FormControlTextEditorInputProps, HTMLInputElement> = forwardRef<
  HTMLInputElement,
  FormControlTextEditorInputProps
>(
  (
    {
      id,
      'data-testid': dataTestId,
      className,
      value = '',
      onValueChange = e => {
        return e;
      },
      helperText,
      ...otherProps
    },
    ref,
  ) => {
    const formControlTextEditorInputProps = useMemo(
      () => ({
        id,
        'data-testid': dataTestId || getTestId(ComponentDefaultTestId.FORM_CONTROL_TEXT_EDITOR, id),
        className,
        helperText,
        ...otherProps,
      }),
      [id, dataTestId, className, helperText, otherProps],
    );
    return <FormikField {...formControlTextEditorInputProps} component={FormikTextEditorInput} />;
  },
);

FormControlTextEditor.displayName = 'FormControl.TextEditorInput';

export default FormControlTextEditor;
