import { StateCreator } from 'zustand';
import { ClientDetails, CurrentUser } from '../models';

export interface BaseStore {
  currentUser: CurrentUser;
  currentCountry: string;
  currentClientDetails: ClientDetails | null;
  setCurrentUser: (user: CurrentUser) => void;
  setCurrentCountry: (country: string) => void;
  setCurrentClientDetails: (clientDetails: ClientDetails) => void;
}

export const createBaseStore: StateCreator<BaseStore> = set => ({
  currentUser: {
    uid: '',
    name: '',
    client_uid: '',
    email: '',
  },
  currentCountry: '',
  currentClientDetails: null,
  setCurrentUser: currentUser => set({ currentUser }),
  setCurrentCountry: country => set({ currentCountry: country }),
  setCurrentClientDetails: clientDetails => set({ currentClientDetails: clientDetails }),
});
