import React, { forwardRef, useMemo, useRef } from 'react';
import styles from './rich-text-bar.module.scss';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import cx from 'classnames';
import { Button } from '../button';
import { Editor } from '@tiptap/react';
import { ComponentDefaultTestId, getTestId } from '@react-fe/common-ui';
import { faBold, faItalic, faUnderline, faListOl, faListUl } from '@fortawesome/pro-solid-svg-icons';

export interface RichTextBarProps extends CoreComponentProps {
  editor?: Editor | null;
  disabled?: boolean;
}

export const RichTextBar: CoreComponent<RichTextBarProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  RichTextBarProps
>(({ id, 'data-testid': dataTestId, className, editor, disabled }, ref) => {
  const internalRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx(className, styles.richTextBarContainer);

  const richTextBarProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId || getTestId(ComponentDefaultTestId.RICH_TEXT_BAR, id),
      className: classNames,
      ref: mergedRef,
    }),
    [id, dataTestId, classNames, mergedRef],
  );

  if (!editor) {
    return null;
  }

  const buttonActions = [
    { icon: faBold, action: () => editor.chain().focus().toggleBold().run() },
    { icon: faItalic, action: () => editor.chain().focus().toggleItalic().run() },
    { icon: faUnderline, action: () => editor.chain().focus().toggleUnderline().run() },
    { icon: faListOl, action: () => editor.chain().focus().toggleOrderedList().run() },
    { icon: faListUl, action: () => editor.chain().focus().toggleBulletList().run() },
  ];

  return (
    <div {...richTextBarProps}>
      {buttonActions.map((button, index) => (
        <Button.Root
          id={`richButton${index}`}
          key={`richButton${index}`}
          className={styles.richTextOptionButton}
          type={Button.Root.types?.GHOST}
          size={Button.Root.sizes?.SMALL}
          disabled={disabled}
          onClick={button.action}
        >
          <Button.Icon icon={button.icon} className="text-text-primary" />
        </Button.Root>
      ))}
    </div>
  );
});

RichTextBar.displayName = 'RichTextBar';

export default RichTextBar;
