import { useState } from 'react';
import classNames from 'classnames';
import { NavHeader } from './nav-header';
import { NavMenu } from './nav-menu';
import { CustomerType, renderMenuItems } from './nav.contants';

export interface NavProps {
  open?: boolean;
}

export function Nav({ open }: NavProps) {
  const [isOpen, setIsOpen] = useState(open ?? false);
  // TODO: Replace with actual customer type once it's available
  const [customer] = useState([CustomerType.ISSUER, CustomerType.BIDDER]);

  return (
    <aside
      className={classNames(
        'transition-all duration-300 ease-in-out px-spacing-2 py-spacing-3 flex md:flex-col items-center md:h-screen md:sticky md:top-0',
        'fixed bottom-0 left-0 right-0 z-20 flex-row',
        {
          'w-full md:w-[103px]': !isOpen,
          'w-full md:w-[200px]': isOpen,
        },
      )}
    >
      <NavHeader isOpen={isOpen} setIsOpen={setIsOpen} />
      <NavMenu isOpen={isOpen} menuItems={renderMenuItems(customer)} />
    </aside>
  );
}

export default Nav;
