import { Button, Heading, HeadingVariant } from '@react-fe/common-ui';
import { InternalComment } from '@react-fe/expertunity-base/models';
import { useTranslation } from 'react-i18next';
import { Comment } from '../comment';

export interface CommentListProps {
  comments?: InternalComment[];
  onClose: () => void;
  onAddComment: () => void;
  onEditComment: (comment: InternalComment) => void;
  onDeleteComment: (comment: InternalComment) => void;
  title: string;
}

export function CommentList({
  comments,
  onClose,
  onAddComment,
  onEditComment,
  onDeleteComment,
  title,
}: CommentListProps) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-spacing-3">
      <Heading variant={HeadingVariant.H4}>
        {title} ({comments?.length || 0})
      </Heading>
      <div className="flex flex-col">
        {comments?.map(comment => (
          <Comment
            key={comment.uuid}
            comment={comment}
            onEdit={() => onEditComment(comment)}
            onDelete={() => onDeleteComment(comment)}
          />
        ))}
      </div>
      <div className="flex gap-spacing-2 justify-end">
        <Button.Root type={Button.Root.types.OUTLINED} onClick={onAddComment}>
          {t('add_comment')}
        </Button.Root>
        <Button.Root onClick={onClose}>{t('close')}</Button.Root>
      </div>
    </div>
  );
}

export default CommentList;
