import { Heading, Text } from '@react-fe/common-ui';
import { Namespaces } from '@react-fe/expertunity-base';
import { useTranslation } from 'react-i18next';

export const WorkDescriptionAttachmentTitle = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-spacing-0.5">
      <Heading variant={Heading.variants.H5}>
        {t('create_offer_document_work_description_title', {
          ns: Namespaces.SellerCreateOfferPage,
          defaultValue: 'Work description template',
        })}
      </Heading>
      <Text color={Text.colors.TEXT_SECONDARY}>
        {t('create_offer_document_work_description_subtitle', {
          ns: Namespaces.SellerCreateOfferPage,
          defaultValue: 'You can fill out these work descriptions by downloading the file, then',
        })}
      </Text>
    </div>
  );
};
