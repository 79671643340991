import { API_URL } from '@react-fe/expertunity-base/constants';
import { CreateOffer } from '@react-fe/expertunity-base/models';

export enum CreateOfferModalStep {
  DOCUMENTS = 0,
  COVER_SHEET = 1,
}

type OfferOverwrittenTypes = '';

export type OfferFormFields = Partial<Omit<CreateOffer, OfferOverwrittenTypes>> & {
  date_time_received_field?: Date | null;
  created_by_field?: string | null;
};

export enum OfferFormMode {
  CREATE = 'create',
  EDIT = 'edit',
}

/**
 * Enum for form control names to ensure consistency and ease of maintenance.
 */
export enum OfferFormControlName {
  DESCRIPTION = 'description',
  CONTACT = 'contact',
  GROSS_EXCL_VAT = 'gross_excl_vat',
  DISCOUNT = 'discount',
  EARLY_PAYMENT_DISCOUNT = 'early_payment_discount',
  DATE_TIME_RECEIVED = 'date_time_received',
  DATE_TIME_RECEIVED_FIELD = 'date_time_received_field',
  CREATED_BY = 'created_by',
  CREATED_BY_FIELD = 'created_by_field',
  // PROJECT_REFERENCES = 'project_references',
  STATE = 'state',
  ATTACHMENTS = 'attachments',
  TERMS = 'terms',
}

export const CREATE_OFFER_FORM_ATTACHMENTS_UPLOAD_ENDPOINT = `${API_URL}/uploads2`;
