import { logger } from '@react-fe/core';
import {
  Attachment,
  CreateOffer,
  CurrentUser,
  Offer,
  OfferAttachment,
  OfferContact,
  OfferState,
  OfferTerm,
  UploadedResponse,
} from '@react-fe/expertunity-base/models';
import { OfferFormFields } from '../create-offer-modal.constants';
import { acceptTerms, getOfferTerms, getUpdatedOfferTerms } from './create-offer-mapper-utils';

class OfferBuilder {
  private offer: Partial<CreateOffer & OfferFormFields> = {};

  description(description?: string): OfferBuilder {
    this.offer.description = description;
    return this;
  }

  gross_excl_vat(gross_excl_vat?: number | null): OfferBuilder {
    this.offer.gross_excl_vat = gross_excl_vat;
    return this;
  }

  discount(discount?: number | null): OfferBuilder {
    this.offer.discount = discount;
    return this;
  }

  early_payment_discount(early_payment_discount?: number | null): OfferBuilder {
    this.offer.early_payment_discount = early_payment_discount;
    return this;
  }

  net_fixed_price_excl_vat(net_fixed_price_excl_vat?: number | null): OfferBuilder {
    this.offer.net_fixed_price_excl_vat = net_fixed_price_excl_vat;
    return this;
  }

  state(state?: OfferState): OfferBuilder {
    this.offer.state = state;
    return this;
  }

  // project_references(project_references?: string): OfferBuilder {
  //   this.offer.project_references = project_references;
  //   return this;
  // }

  contact(contact: OfferContact): OfferBuilder {
    this.offer.contact = contact;
    return this;
  }

  date_time_received(date_time_received?: string | null): OfferBuilder {
    this.offer.date_time_received = date_time_received;
    this.offer.date_time_received_field = date_time_received ? new Date(date_time_received) : null;
    return this;
  }

  created_by(created_by?: string | null): OfferBuilder {
    this.offer.created_by = created_by;
    this.offer.created_by_field = created_by;
    return this;
  }

  external(external?: boolean): OfferBuilder {
    this.offer.external = external;
    return this;
  }

  attachments(attachments: OfferAttachment[]): OfferBuilder {
    this.offer.attachments = attachments;
    return this;
  }

  terms(terms: OfferTerm[]): OfferBuilder {
    this.offer.terms = terms;
    return this;
  }

  acceptTerms(): OfferBuilder {
    this.offer.terms = acceptTerms(this.offer.terms || []);
    return this;
  }

  build(runCallback?: (offer: CreateOffer) => void): CreateOffer {
    if (runCallback) {
      runCallback(this.offer as CreateOffer);
    }
    return this.offer as CreateOffer;
  }
}

const initialValues = ({
  contact,
  tenderAttachments = [],
  currentUser,
}: {
  contact: OfferContact;
  tenderAttachments?: Attachment[];
  currentUser?: CurrentUser;
}): OfferFormFields => {
  return new OfferBuilder()
    .description('')
    .gross_excl_vat(null)
    .discount(null)
    .early_payment_discount(null)
    .net_fixed_price_excl_vat(null)
    .state(OfferState.DRAFT)
    .date_time_received(null)
    .external(false)
    .created_by(currentUser?.name || null)
    .contact(contact)
    .attachments([])
    .terms(getOfferTerms(tenderAttachments))
    .build();
};

const toFormFields = (offer: Offer): OfferFormFields => {
  return new OfferBuilder()
    .attachments(offer.attachments || [])
    .terms(offer.terms || [])
    .contact(offer.contact)
    .gross_excl_vat(offer.gross_excl_vat)
    .description(offer.description)
    .date_time_received(offer.date_time_received)
    .created_by(offer.created_by)
    .build(fields => {
      logger.debug('Processing form fields', fields);
    });
};

const toOffer = (formFields: OfferFormFields): CreateOffer => {
  return new OfferBuilder()
    .contact(formFields.contact as OfferContact)
    .description(formFields.description || '')
    .gross_excl_vat(formFields.gross_excl_vat || null)
    .discount(formFields.discount || null)
    .early_payment_discount(formFields.early_payment_discount || null)
    .net_fixed_price_excl_vat(formFields.net_fixed_price_excl_vat || null)
    .state(formFields.state || OfferState.DRAFT)
    .date_time_received(formFields.date_time_received_field?.toISOString() || null)
    .external(formFields.external || false)
    .created_by(formFields.created_by || null)
    .attachments(formFields.attachments || [])
    .terms(formFields.terms ? getUpdatedOfferTerms(formFields.terms) : [])
    .acceptTerms()
    .build(offer => {
      logger.debug('Processing offer', offer);
    });
};

const rawUploadToAttachment = ({ content: uploadedContent }: UploadedResponse): Partial<OfferAttachment> => {
  return {
    name: uploadedContent.files[0].name,
    extension: `.${uploadedContent.files[0].name.split('.').pop()}`,
    file: {
      uid: uploadedContent.files[0].uid,
      name: uploadedContent.files[0].name,
      length: uploadedContent.files[0].length,
      createdAt: uploadedContent.files[0].createdAt,
      href: uploadedContent.files[0].url,
    },
  };
};

export const OfferMapper = {
  initialValues,
  toFormFields,
  toOffer,
  rawUploadToAttachment: rawUploadToAttachment,
};
