import { ReactNode, useEffect } from 'react';
import { AuthProvider } from 'react-oidc-context';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { Icon } from '@react-fe/common-ui';
import useBaseStore from '@react-fe/expertunity-base/stores';
import { Config } from '@react-fe/expertunity-base/models';

interface ConfigProviderProps {
  children: ReactNode;
}

export function ConfigProvider({ children }: Readonly<ConfigProviderProps>) {
  const { setConfig, config } = useBaseStore();
  const isDev = import.meta.env.MODE === 'development';
  const { isLoading, data } = useQuery<Config>({
    queryKey: ['config'],
    queryFn: async (): Promise<Config> => {
      const response = await axios.get<any>('/config');
      return response.data;
    },
    enabled: !isDev,
  });

  useEffect(() => {
    if (isDev) {
      setConfig({
        oidc_authority: import.meta.env.VITE_OIDC_AUTHORITY,
        oidc_client_id: import.meta.env.VITE_OIDC_CLIENT_ID,
      });
      return;
    }

    if (data) {
      setConfig(data);
    }
  }, [setConfig, data, isDev]);

  if (isLoading || !config?.oidc_client_id || !config?.oidc_authority) {
    return (
      <div className={'flex items-center justify-center min-h-screen'}>
        <Icon icon={faSpinnerThird} className={'animate-spin size-40'} />
      </div>
    );
  }

  const oidcConfig = {
    authority: config.oidc_authority,
    client_id: config.oidc_client_id,
    redirect_uri: window.location.origin + window.location.pathname,
  };

  return <AuthProvider {...oidcConfig}>{children}</AuthProvider>;
}

export default ConfigProvider;
