import { Offer } from '@react-fe/expertunity-base/models';
import { StateCreator } from 'zustand';
import { AllBuyerState } from '.';

export interface FocusedContractorState {
  lastFocusedContractorUid: string;
  lastFocusedContractorOffers: Offer[] | null;
  setLastFocusedContractorData: (uid: string) => Offer[];
  setLastFocusedContractorOffers: (offers: Offer[] | null) => void;
}

export const createFocusedContractorSlice: StateCreator<FocusedContractorState> = (set, get) => ({
  lastFocusedContractorUid: '',
  lastFocusedContractorOffers: null,
  setLastFocusedContractorData: uid => {
    const offers: Offer[] = (get() as AllBuyerState).offers?.filter((offer: Offer) => offer.bidder_uid === uid) ?? [];
    set({ lastFocusedContractorUid: uid, lastFocusedContractorOffers: offers });
    return offers;
  },
  setLastFocusedContractorOffers: offers => set({ lastFocusedContractorOffers: offers }),
});
