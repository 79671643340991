import { useCallback } from 'react';
import { useLocale } from '../use-locale';

export const useCurrencyFormatter = (): { currencyFormatter: (value: number, displaySymbol?: boolean) => string } => {
  const { locale } = useLocale();

  const currencyFormatter = useCallback(
    (value: number, displayCurrency = true) => {
      const localeCode = locale ? (locale.code === 'de' ? `${locale.code}-CH` : locale.code) : 'en';
      return Intl.NumberFormat(new Intl.Locale(localeCode), {
        style: displayCurrency ? 'currency' : 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currency: 'CHF',
      }).format(value);
    },
    [locale],
  );

  return {
    currencyFormatter,
  };
};
