import { ConfigState, createConfigSlice } from '@react-fe/expertunity-base/stores/config-slice.ts';
import { create } from 'zustand';
import { AcceptRejectOfferState, createAcceptRejectOfferSlice } from './accept-reject-offer-slice';
import { BaseStore, createBaseStore } from './base-store';
import { CategorizedAttachmentState, createCategorizedAttachmentSlice } from './categorized-attachment-slice';
import { createOfferSlice, OfferState } from './create-offer-slice';
import { createModalState, ModalState } from './modal-slice';
import { createToastState, ToastState } from './toast-slice';

export * from './base-store';
export * from './accept-reject-offer-slice';
export * from './categorized-attachment-slice';
export * from './create-offer-slice';
export * from './modal-slice';
export * from './toast-slice';

type AllExpyBaseState = BaseStore &
  OfferState &
  AcceptRejectOfferState &
  CategorizedAttachmentState &
  ConfigState &
  ToastState &
  ModalState;

export const useBaseStore = create<AllExpyBaseState>((set, get, store) => ({
  ...createBaseStore(set, get, store),
  ...createOfferSlice(set, get, store),
  ...createAcceptRejectOfferSlice(set, get, store),
  ...createCategorizedAttachmentSlice(set, get, store),
  ...createConfigSlice(set, get, store),
  ...createToastState(set, get, store),
  ...createModalState(set, get, store),
}));

export function useTypedBaseStore<
  T extends
    | keyof AllExpyBaseState
    | BaseStore
    | OfferState
    | AcceptRejectOfferState
    | CategorizedAttachmentState
    | ConfigState
    | ToastState
    | ModalState,
>() {
  return useBaseStore((state): T extends keyof AllExpyBaseState ? Pick<AllExpyBaseState, T> : T => state as any);
}

export default useBaseStore;
