export enum PopoverAnchorOriginVertical {
  TOP = 'top',
  CENTER = 'center',
  BOTTOM = 'bottom',
}

export enum PopoverAnchorOriginHorizontal {
  LEFT = 'left',
  CENTER = 'center',
  BOTTOM = 'bottom',
}
