import { Text } from '@react-fe/common-ui';
import { HelperTextLink, HelperTextLinkProps } from '../components/helper-text-link';

export interface HelperTextProps {
  helperText: string;
  helperLink?: Omit<HelperTextLinkProps, 'helperText'>;
}

export const HelperText: React.FC<HelperTextProps> = ({ helperText, helperLink }) => {
  if (!helperLink)
    return (
      <Text variant={Text.variants.CAPTION} component={'span' as any}>
        {helperText}
      </Text>
    );

  return <HelperTextLink helperText={helperText} {...helperLink} />;
};
