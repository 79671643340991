import axios from 'axios';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Page, TypeOfWork } from '@react-fe/expertunity-base/models';
import { TYPES_OF_WORK_ENDPOINT } from '@react-fe/expertunity-base/constants';

export function useTypesOfWork(): UseQueryResult<Page<TypeOfWork>> {
  return useQuery<Page<TypeOfWork>>({
    queryKey: ['types-of-work'],
    queryFn: async (): Promise<Page<TypeOfWork>> => {
      const response = await axios.get<Page<TypeOfWork>>(TYPES_OF_WORK_ENDPOINT);
      return response.data;
    },
  });
}

export default useTypesOfWork;
