import React, { forwardRef, useMemo } from 'react';
import { CoreComponent, CoreComponentProps } from '@react-fe/core';
import cx from 'classnames';
import { Offer } from '@react-fe/expertunity-base/models';
import { useInternalCommentModal } from '../../hooks/use-internal-comments-modal';
import { Badge, Button } from '@react-fe/common-ui';
import { faCommentLines } from '@fortawesome/pro-regular-svg-icons';

export interface OfferListCardCommentButtonProps extends CoreComponentProps {
  offer: Offer;
  onlyIcon?: boolean;
}

export const OfferListCardCommentButton: CoreComponent<OfferListCardCommentButtonProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  OfferListCardCommentButtonProps
>(({ id, 'data-testid': dataTestId, className, offer, onlyIcon = false }, ref) => {
  const classNames = cx(className);
  const { openInternalCommentModal, commentCount } = useInternalCommentModal(offer);

  const offerListCardCommentButtonProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      offer,
      onlyIcon,
    }),
    [id, dataTestId, classNames, offer, onlyIcon],
  );

  return (
    <Badge {...offerListCardCommentButtonProps} variant="dot" invisible={commentCount === 0}>
      <Button.Root type={Button.Root.types.GHOST} className="!p-0 !h-min" onClick={() => openInternalCommentModal()}>
        <Button.Icon icon={faCommentLines} className="text-sm" />
      </Button.Root>
    </Badge>
  );
});

OfferListCardCommentButton.displayName = 'OfferListCardCommentButton';

export default OfferListCardCommentButton;
