import { Button } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, logger, useMergedRef } from '@react-fe/core';
import { Namespaces } from '@react-fe/expertunity-base';
import { OfferState } from '@react-fe/expertunity-base/models';
import useBaseStore from '@react-fe/expertunity-base/stores';
import cx from 'classnames';
import { useFormikContext } from 'formik';
import { forwardRef, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { OfferFormControlName } from '../../create-offer-modal.constants';

export type SaveOfferDraftButtonProps = CoreComponentProps;

export const SaveOfferDraftButton: CoreComponent<SaveOfferDraftButtonProps, HTMLButtonElement> = forwardRef<
  HTMLButtonElement,
  SaveOfferDraftButtonProps
>(({ id, 'data-testid': dataTestId, className }, ref) => {
  const { t } = useTranslation();
  const { touched, isSubmitting, values, errors, setFieldValue, submitForm } = useFormikContext();
  const { offerValidationSchema } = useBaseStore();
  const formIsTouched = Object.keys(touched).length;
  const internalRef = useRef<HTMLButtonElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx(className);

  const isInvalid = useMemo(() => {
    const errorKeys = Object.keys(errors);
    return errorKeys.length > 0 && !(errorKeys.length === 1 && errorKeys.includes('gross_excl_vat'));
  }, [errors]);

  const dropRequiredConstraintsForDraft = useCallback(() => {
    (offerValidationSchema as any).fields.gross_excl_vat.spec.nullable = true;
  }, [offerValidationSchema]);

  const handleSaveAsDraftClick = useCallback(() => {
    logger.debug(`Save offer as dr button clicked with Form Values:`, JSON.stringify(values));
    logger.debug('Errors: ', JSON.stringify(errors));

    dropRequiredConstraintsForDraft();
    setFieldValue(OfferFormControlName.STATE, OfferState.DRAFT).catch();

    submitForm().then(() => logger.debug('Create offer form submitted'));
  }, [values, errors, dropRequiredConstraintsForDraft, submitForm, setFieldValue]);

  const saveOfferDraftButtonProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      ref: mergedRef,
      onClick: handleSaveAsDraftClick,
    }),
    [id, dataTestId, classNames, mergedRef, handleSaveAsDraftClick],
  );

  return (
    <div className="flex items-center gap-spacing-1">
      <Button.Root
        type={Button.Root.types.OUTLINED}
        disabled={(formIsTouched && isInvalid) || isSubmitting}
        loading={isSubmitting}
        {...saveOfferDraftButtonProps}
      >
        {t('create_offer_modal_save_as_draft', {
          ns: Namespaces.SellerCreateOfferPage,
          defaultValue: 'Save as draft',
        })}
      </Button.Root>
    </div>
  );
});

SaveOfferDraftButton.displayName = 'SaveOfferDraftButton';

export default SaveOfferDraftButton;
