import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import {
  deDE as MUICoreDeDE,
  enUS as MUICoreEnUS,
  itIT as MUICoreItIT,
  ptPT as MUICorePtPT,
  svSE as MUICoreSvSE,
} from '@mui/material/locale';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import {
  deDE as MUICoreDatePickersDeDE,
  enUS as MUICoreDatePickersEnUS,
  itIT as MUICoreDatePickersItIT,
  ptBR as MUICoreDatePickersPtPT,
  svSE as MUICoreDatePickersSvSE,
} from '@mui/x-date-pickers/locales';
import { ElementContent, logger, useLocale } from '@react-fe/core';
import React from 'react';
import { globalApplicationThemeConfiguration } from '../config';

interface ThemeProviderProps {
  children: ElementContent;
}

const CommonUIThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const { locale } = useLocale();

  const localizedTheme = React.useMemo(() => {
    logger.info(locale?.code);
    let muiLocale, muiPickerLocale;
    switch (locale?.code) {
      case 'de':
        muiPickerLocale = MUICoreDatePickersDeDE;
        muiLocale = MUICoreDeDE;
        break;
      case 'pt':
        muiPickerLocale = MUICoreDatePickersPtPT;
        muiLocale = MUICorePtPT;
        break;
      case 'sv':
        muiPickerLocale = MUICoreDatePickersSvSE;
        muiLocale = MUICoreSvSE;
        break;
      case 'it':
        muiPickerLocale = MUICoreDatePickersItIT;
        muiLocale = MUICoreItIT;
        break;
      default:
        muiPickerLocale = MUICoreDatePickersEnUS;
        muiLocale = MUICoreEnUS;
    }
    return createTheme(globalApplicationThemeConfiguration, muiPickerLocale, muiLocale);
  }, [locale?.code]);

  return (
    <ThemeProvider theme={localizedTheme}>
      <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {children}
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export { CommonUIThemeProvider };
