import { Button } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, logger, useMergedRef } from '@react-fe/core';
import { Namespaces } from '@react-fe/expertunity-base';
import { OfferState } from '@react-fe/expertunity-base/models';
import useBaseStore from '@react-fe/expertunity-base/stores';
import cx from 'classnames';
import { useFormikContext } from 'formik';
import { forwardRef, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { OfferFormControlName } from '../../create-offer-modal.constants';

export type CreateOfferActionsProps = CoreComponentProps;

export const CreateOfferActions: CoreComponent<CreateOfferActionsProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  CreateOfferActionsProps
>(({ id, 'data-testid': dataTestId, className }, ref) => {
  const { t } = useTranslation();
  const {
    isLastStep,
    activeStep,
    setActiveStep,
    setIsOpenCreateOfferModal,
    clearCreateOfferState,
    setCurrentBidderUid,
  } = useBaseStore();
  const { isValid, isSubmitting, values, errors, touched, submitForm, setFieldValue } = useFormikContext();

  const internalRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx(className, 'flex w-full justify-between');

  const showSubmit = isLastStep();
  const showNext = !isLastStep();
  const showBack = activeStep > 0;

  const handleNextClick = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBackClick = () => {
    setActiveStep(activeStep - 1);
  };

  const handleCancelClick = () => {
    clearCreateOfferState();
    setIsOpenCreateOfferModal(false);
    setCurrentBidderUid(null);
  };

  const handleSubmitOfferClick = useCallback(() => {
    logger.debug(`Submit offer button clicked with Form Values:`, JSON.stringify(values));
    logger.debug('Errors: ', JSON.stringify(errors));

    setFieldValue(OfferFormControlName.STATE, OfferState.RECEIVED).catch();

    submitForm().then(() => logger.debug('Create offer form submitted'));
  }, [errors, submitForm, setFieldValue, values]);

  const createOfferActionsProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      ref: mergedRef,
    }),
    [id, dataTestId, classNames, mergedRef],
  );

  const requiredFields = useMemo(() => {
    if (!errors) return [];
    return Object.keys(errors);
  }, [errors]);

  const allRequiredFieldsTouched = useMemo(() => {
    return requiredFields.every(field => touched[field as keyof typeof touched]);
  }, [requiredFields, touched]);

  return (
    <div {...createOfferActionsProps}>
      <Button.Root type={Button.Root.types.OUTLINED} onClick={handleCancelClick}>
        {t('cancel', { ns: Namespaces.Common, defaultValue: 'Cancel' })}
      </Button.Root>

      {showNext && (
        <Button.Root onClick={handleNextClick}>
          {t('next', { ns: Namespaces.Common, defaultValue: 'Next' })}
        </Button.Root>
      )}

      {(showBack || showSubmit) && (
        <div className="flex gap-spacing-2">
          {showBack && (
            <Button.Root type={Button.Root.types.OUTLINED} onClick={handleBackClick}>
              {t('back', { ns: Namespaces.Common, defaultValue: 'Back' })}
            </Button.Root>
          )}
          {showSubmit && (
            <Button.Root
              disabled={(allRequiredFieldsTouched && !isValid) || isSubmitting}
              loading={isSubmitting}
              onClick={handleSubmitOfferClick}
            >
              {t('submit_offer', { ns: Namespaces.SellerCreateOfferPage, defaultValue: 'Submit offer' })}
            </Button.Root>
          )}
        </div>
      )}
    </div>
  );
});

CreateOfferActions.displayName = 'CreateOfferActions';

export default CreateOfferActions;
