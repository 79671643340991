import { StateCreator } from 'zustand';
import { ModalConfiguration } from '../hooks/use-modal';

export interface ModalState {
  contextModals: ModalConfiguration[];
  addModalConfiguration: (modalConfiguration: ModalConfiguration) => void;
  updateModalConfiguration: (modalConfiguration: ModalConfiguration) => void;
  showModal: (modalId: string) => void;
  closeModal: (modalId: string) => void;
}

export const createModalState: StateCreator<ModalState> = (set, get) => ({
  contextModals: [],
  addModalConfiguration: (modalConfiguration: ModalConfiguration) => {
    set({ contextModals: [...get().contextModals, modalConfiguration] });
  },
  updateModalConfiguration: (modalConfiguration: ModalConfiguration) => {
    const updatedModals = get().contextModals.map(modal =>
      modal.modalId === modalConfiguration.modalId ? modalConfiguration : modal,
    );
    set({ contextModals: updatedModals });
  },
  showModal: (modalId: string) => {
    set({ contextModals: get().contextModals.map(modal => ({ ...modal, isVisible: modal.modalId === modalId })) });
  },
  closeModal: (modalId: string) => {
    set({ contextModals: get().contextModals.filter(modal => modal.modalId !== modalId) });
  },
});
