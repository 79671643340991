import { StateCreator } from 'zustand';

export interface ContractorActionsState {
  isAcceptApplicationModalOpen: boolean;
  isRejectApplicationModalOpen: boolean;
  isAcceptApplicationSuccessToastOpen: boolean;
  isRejectApplicationSuccessToastOpen: boolean;
  setIsAcceptApplicationModalOpen: (isOpen: boolean) => void;
  setIsRejectApplicationModalOpen: (isOpen: boolean) => void;
  setIsAcceptApplicationSuccessToastOpen: (isOpen: boolean) => void;
  setIsRejectApplicationSuccessToastOpen: (isOpen: boolean) => void;
}

export const createContractorActionsSlice: StateCreator<ContractorActionsState> = set => ({
  isAcceptApplicationModalOpen: false,
  isRejectApplicationModalOpen: false,
  isAcceptApplicationSuccessToastOpen: false,
  isRejectApplicationSuccessToastOpen: false,
  isAcceptContractorModalOpen: false,
  isRejectContractorModalOpen: false,
  setIsAcceptApplicationModalOpen: (isOpen: boolean) => {
    set({ isAcceptApplicationModalOpen: isOpen });
  },
  setIsRejectApplicationModalOpen: (isOpen: boolean) => {
    set({ isRejectApplicationModalOpen: isOpen });
  },
  setIsAcceptApplicationSuccessToastOpen: (isOpen: boolean) => {
    set({ isAcceptApplicationSuccessToastOpen: isOpen });
  },
  setIsRejectApplicationSuccessToastOpen: (isOpen: boolean) => {
    set({ isRejectApplicationSuccessToastOpen: isOpen });
  },
});
