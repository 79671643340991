import { default as MUIBreadcrumbs } from '@mui/material/Breadcrumbs';
import { Link, useMatches } from 'react-router-dom';
import { Icon, IconSize, Text } from '@react-fe/common-ui';
import { useBreadcrumbs } from '@react-fe/core';
import { useTranslation } from 'react-i18next';
import { faChevronRight, IconDefinition } from '@fortawesome/pro-solid-svg-icons';

interface Handle {
  crumb: () => { to: string; name: string; namespace: string; icon?: IconDefinition };
}

export function Breadcrumbs() {
  const { t } = useTranslation();
  const { breadcrumbs } = useBreadcrumbs();
  const crumbs = useMatches()
    .filter(match => (match.handle as Handle)?.crumb)
    .map(match => (match.handle as Handle).crumb());

  let currentTo = '';

  return (
    <div>
      <MUIBreadcrumbs
        aria-label="breadcrumb"
        className="!mt-spacing-3"
        separator={<Icon icon={faChevronRight} size={IconSize.EXTRA_SMALL} />}
      >
        {crumbs.map((crumb, index) => {
          const last = index === crumbs.length - 1;
          const { to, name, namespace, icon } = crumb;
          currentTo += breadcrumbs[to]?.to || to;
          const currentName = breadcrumbs[to]?.name || t(name, { ns: namespace });
          return last ? (
            <div className="flex items-center" key={currentTo}>
              {icon && <Icon icon={icon} className="mr-spacing-1" />}
              <Text>{currentName}</Text>
            </div>
          ) : (
            <Link className={'flex items-center text-primary'} to={currentTo} key={currentTo}>
              {icon && <Icon icon={icon} className="mr-spacing-1" />}
              <Text color={Text.colors.INHERIT}>{currentName}</Text>
            </Link>
          );
        })}
      </MUIBreadcrumbs>
    </div>
  );
}

export default Breadcrumbs;
