import { File } from './file';
import { Link } from './link';

export interface AttachmentTranslation {
  attachment_category: AttachmentCategory;
  translation: string;
}

export interface Attachment {
  uid?: string;
  category: AttachmentCategory;
  name: string;
  new_since_offered?: boolean;
  description?: string;
  extension?: string;
  file: File;
  links?: Link[];
  _links?: {
    download_url: Link;
  };
}

export enum AttachmentCategory {
  GENERAL_TERMS = 'general_terms',
  SPECIAL_TERMS = 'special_terms',
  ATTACHMENT = 'attachment',
  PLANS = 'plans',
  WORK_DESCRIPTION = 'work_description',
  CANDIDATE_INFORMATION = 'candidate_information',
}
