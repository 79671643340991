import { forwardRef } from 'react';
import { CoreComponent, CoreComponentProps } from '@react-fe/core';
import { Icon } from '../icon';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { IconSize } from '../icon/icon.constant';
import cx from 'classnames';

export interface ButtonIconProps extends CoreComponentProps {
  icon: IconDefinition;
  color?: string;
  size?: IconSize;
}

export const ButtonIcon: CoreComponent<ButtonIconProps, SVGSVGElement> = forwardRef(
  ({ id, className, 'data-testid': dataTestId, icon, color, size }, ref) => {
    return (
      <Icon
        icon={icon}
        ref={ref}
        id={id}
        color={color}
        className={cx(className, 'cursor-pointer')}
        size={size || IconSize.SMALL}
        key={`buttonIcon${id}`}
        data-testid={dataTestId}
      />
    );
  },
);

ButtonIcon.displayName = 'Button.Icon';

export default ButtonIcon;
