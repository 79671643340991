import { Attachment, QueryKeys } from '@react-fe/expertunity-base/models';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { API_URL } from '../constants';

export function useTenderAttachments(tenderUid: string | undefined): UseQueryResult<Attachment[]> {
  return useQuery<Attachment[]>({
    queryKey: [QueryKeys.TENDER_ATTACHMENTS, tenderUid],
    queryFn: async (): Promise<Attachment[]> => {
      const response = await axios.get<Attachment[]>(`${API_URL}/tenders/${tenderUid}/attachments`);
      return response.data;
    },
  });
}

export default useTenderAttachments;
