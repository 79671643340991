import { StateCreator } from 'zustand';

export interface InviteContractorsState {
  contractorSearchTerm: string;
  typesOfWorkUids: string[];
  paginationData: {
    number: number;
    size: number;
    totalElements: number;
    totalPages: number;
  };
  clearInviteContractorsFilter: boolean;
  isInviteContractorsModalOpen: boolean;
  onlyFavourites: boolean;
  setContractorSearchTerm: (contractorSearchTerm: string) => void;
  setTypesOfWork: (typesOfWork: string[]) => void;
  setPaginationData: (paginationData?: {
    number: number;
    size: number;
    totalElements: number;
    totalPages: number;
  }) => void;
  clearFilters: () => void;
  setIsInviteContractorsModalOpen: (open: boolean) => void;
  setOnlyFavourites: (onlyFavourites: boolean) => void;
}

export const inviteContractorsSlice: StateCreator<InviteContractorsState> = (set: any) => ({
  contractorSearchTerm: '',
  typesOfWorkUids: [],
  paginationData: {
    number: 0,
    size: 20,
    totalElements: 0,
    totalPages: 0,
  },
  clearInviteContractorsFilter: false,
  isInviteContractorsModalOpen: false,
  onlyFavourites: false,
  setPaginationData: (paginationData?: { number: number; size: number; totalElements: number; totalPages: number }) => {
    set({ paginationData: paginationData });
  },
  setContractorSearchTerm: contractorSearchTerm => set({ contractorSearchTerm }),
  setTypesOfWork: typesOfWorkUids => set({ typesOfWorkUids }),
  clearFilters: () => {
    set({
      contractorSearchTerm: '',
      typesOfWorkUids: [],
      clearInviteContractorsFilter: true,
      onlyFavourites: false,
    });
    setTimeout(() => {
      set({ clearInviteContractorsFilter: false });
    }, 250);
  },
  setIsInviteContractorsModalOpen: open => set({ isInviteContractorsModalOpen: open }),
  setOnlyFavourites: onlyFavourites => set({ onlyFavourites }),
});

export default inviteContractorsSlice;
