import React, { useCallback } from 'react';
import { CoreComponentProps } from '@react-fe/core';
import { HierarchicalItem, TypeOfWork } from '@react-fe/expertunity-base/models';
import { useTypesOfWork } from '@react-fe/expertunity-base/hooks';
import HierarchicalItemsModalContent from '../hierarchical-items-modal/hierarchical-items-modal';
import { Namespaces } from '@react-fe/expertunity-base/constants';
import { useTranslation } from 'react-i18next';

export interface TypeOfWorkModalContentProps extends CoreComponentProps {
  handleClose: () => void;
  handleConfirm: (results: TypeOfWork[]) => void;
  currentSelectedTypes: TypeOfWork[];
  title: string;
  description: string;
}

export const TypesOfWorkModal: React.FC<TypeOfWorkModalContentProps> = ({
  'data-testid': dataTestId,
  handleClose,
  handleConfirm,
  currentSelectedTypes,
  title,
  description,
}: TypeOfWorkModalContentProps) => {
  const { t } = useTranslation();
  const { isLoading, data } = useTypesOfWork();

  const translateTypeOfWork = useCallback(
    (label: string): string => {
      return t(label.replace(/-/g, '_'), { ns: Namespaces.TypesOfWork });
    },
    [t],
  );

  return (
    <HierarchicalItemsModalContent
      data-testid={dataTestId}
      handleClose={handleClose}
      handleConfirm={handleConfirm as (results: HierarchicalItem[]) => void}
      currentSelectedTypes={currentSelectedTypes}
      title={title}
      description={description}
      isLoading={isLoading}
      items={data?._embedded.content}
      translateLabel={translateTypeOfWork}
    />
  );
};

export default TypesOfWorkModal;
