import React, { forwardRef, useId, useMemo, useCallback } from 'react';
import { CoreComponent } from '@react-fe/core';
import { ComponentDefaultTestId, getTestId } from '@react-fe/common-ui';
import { useField } from 'formik';
import { FormControlRootProps } from './form-control-root';
import { Dropdown, DropdownProps } from '../dropdown';
import { FormError } from '../form-error';
import { FormControl as MUIFormControl, InputLabel as MUIInputLabel } from '@mui/material';

type FormControlMergedProps = FormControlRootProps & DropdownProps;

export interface FormControlDropdownProps extends FormControlMergedProps {
  options?: { value: string | number; label: string }[];
}

export const FormControlDropdown: CoreComponent<FormControlDropdownProps, HTMLInputElement> = forwardRef<
  HTMLInputElement,
  FormControlDropdownProps
>(({ id, 'data-testid': dataTestId, className, name, label, required, options, onChange, ...otherProps }, ref) => {
  const elementId = useId();
  const [field, meta, helpers] = useField(name as string);
  const { setValue } = helpers;
  const hasErrors = meta.touched && !!meta.error;
  const labelId = label ? `form-control-dropdown-${elementId}` : '';

  const setSelectValue = useCallback(
    (value: React.ChangeEvent<{ value: string }>) => {
      const newValue = value.target.value;
      if (onChange) {
        onChange({ target: { value: newValue } } as any);
      }
      setValue(newValue).catch();
    },
    [onChange, setValue],
  );

  const formControlDropdownProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId || getTestId(ComponentDefaultTestId.FORM_CONTROL_TEXT_FIELD, id),
      className,
      options,
      ...otherProps,
    }),
    [id, dataTestId, className, options, otherProps],
  );

  return (
    <MUIFormControl error={hasErrors} required={required}>
      {label && (
        <MUIInputLabel id={labelId} sx={{ top: '4px', background: 'white', paddingLeft: '6px', paddingRight: '4px' }}>
          {label}
        </MUIInputLabel>
      )}
      <Dropdown
        {...field}
        {...formControlDropdownProps}
        labelId={labelId}
        error={hasErrors}
        value={field.value}
        onChange={setSelectValue}
      />
      <FormError field={field} />
    </MUIFormControl>
  );
});

FormControlDropdown.displayName = 'FormControl.Dropdown';

export default FormControlDropdown;
