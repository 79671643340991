import chalk from 'chalk';
import { format } from 'date-fns';

const levels = {
  info: chalk.hex('#226d7c'),
  warn: chalk.hex('#FB8B02'),
  error: chalk.hex('#AF130E'),
  debug: chalk.hex('#6f6b2d'),
  success: chalk.green,
};

const consoleMethods = {
  info: console.info,
  warn: console.warn,
  error: console.error,
  debug: console.debug,
  success: console.log,
};

const log = (level: keyof typeof levels, ...messages: (string | object)[]) => {
  const timestamp = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
  const formattedMessages = messages.map(message => (typeof message === 'string' ? message : JSON.stringify(message)));

  consoleMethods[level](levels[level](`[${timestamp}] [${level.toUpperCase()}] ${formattedMessages}`));
};

export const logger = {
  info: (...messages: (string | object)[]) => log('info', ...messages),
  warn: (...messages: (string | object)[]) => log('warn', ...messages),
  error: (...messages: (string | object)[]) => log('error', ...messages),
  debug: (...messages: (string | object)[]) => log('debug', ...messages),
  success: (...messages: (string | object)[]) => log('success', ...messages),
};
