import { ToastProps } from '@react-fe/common-ui';
import { useTypedBaseStore } from '../stores';
import { ToastState } from '../stores/toast-slice';

export type ToastConfiguration = Omit<ToastProps, 'open' | 'handleClose'>;

export const useToast = () => {
  const { setToastConfiguration, showToast, hideToast } = useTypedBaseStore<ToastState>();

  const displayToast = (toastProps: ToastConfiguration) => {
    setToastConfiguration(toastProps);
    showToast();

    setTimeout(() => {
      hideToast();
    }, toastProps.autoHideDuration || 3000);
  };

  return {
    displayToast: displayToast,
  };
};
