import { faEdit, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { InternalComment } from '@react-fe/buyer/models';
import { Button, Text } from '@react-fe/common-ui';
import { useDateFormatter } from '@react-fe/core';
import React from 'react';

interface CommentProps {
  comment: InternalComment;
  onEdit: () => void;
  onDelete: () => void;
}

export function Comment({ comment, onEdit, onDelete }: CommentProps) {
  const { dateFormatter } = useDateFormatter();
  const canEdit = comment.links.some(l => l.rel === 'update');
  const canDelete = comment.links.some(l => l.rel === 'delete');

  return (
    <div className="flex px-spacing-1 py-spacing-2 gap-spacing-2 border-b first:border-t">
      <div className="flex flex-col gap-spacing-1 grow">
        <div className="flex gap-spacing-1">
          <Text variant={Text.variants.BODY2_BOLD}>
            {comment.last_modified_by.first_name} {comment.last_modified_by.last_name}
          </Text>
          <Text>{dateFormatter(comment.last_modified, 'Pp')}</Text>
        </div>
        <Text className="whitespace-break-spaces">{comment.comment}</Text>
      </div>
      <div className="flex gap-spacing-2 items-center basis-[48px] shrink-0">
        {canEdit && (
          <Button.Root type={Button.Root.types.GHOST} size={Button.Root.sizes.XSMALL} onClick={onEdit}>
            <Button.Icon icon={faEdit} className="text-base text-info" />
          </Button.Root>
        )}
        {canDelete && (
          <Button.Root type={Button.Root.types.GHOST} size={Button.Root.sizes.XSMALL} onClick={onDelete}>
            <Button.Icon icon={faTrash} className="text-base text-error" />
          </Button.Root>
        )}
      </div>
    </div>
  );
}

export default Comment;
