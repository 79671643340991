import React, { forwardRef, useMemo, useRef } from 'react';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import { ComponentDefaultTestId, getTestId } from '@react-fe/common-ui';
import { Text } from '../text';
import cx from 'classnames';

export interface FormControlLabelProps extends CoreComponentProps {
  label: string;
  required?: boolean;
  htmlFor?: string;
}

export const FormControlLabel: CoreComponent<FormControlLabelProps, HTMLLabelElement> = forwardRef<
  HTMLLabelElement,
  FormControlLabelProps
>(({ id, 'data-testid': dataTestId, className, label, required = false, htmlFor }, ref) => {
  const internalRef = useRef<HTMLLabelElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx([className, 'flex flex-row gap-spacing-1']);

  const formControlLabelProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId || getTestId(ComponentDefaultTestId.FORM_CONTROL_LABEL, id),
      className: classNames,
      ref: mergedRef,
      htmlFor,
    }),
    [id, dataTestId, classNames, mergedRef, htmlFor],
  );

  return (
    <label {...formControlLabelProps}>
      <Text>{label}</Text>
      {required && <span className={'text-bfg-add-failure'}>*</span>}
    </label>
  );
});

FormControlLabel.displayName = 'FormControl.Label';

export default FormControlLabel;
