import React, { forwardRef, useMemo, useRef } from 'react';
import { CoreComponent, CoreComponentProps, useMergedRef, withStaticProps } from '@react-fe/core';
import { ProgressBarColor, ProgressBarVariant } from './progress-bar.constants';
import { LinearProgress as MUILinearProgress } from '@mui/material';
import cx from 'classnames';
import { ComponentDefaultTestId, getTestId } from '@react-fe/common-ui';

export interface ProgressBarProps extends CoreComponentProps {
  progress?: number;
  buffer?: number;
  variant?: ProgressBarVariant;
  color?: ProgressBarColor;
}

const ProgressBarComponent: CoreComponent<ProgressBarProps, HTMLDivElement> & {
  variants?: typeof ProgressBarVariant;
  colors?: typeof ProgressBarColor;
} = forwardRef<HTMLDivElement, ProgressBarProps>(
  (
    {
      id,
      'data-testid': dataTestId,
      className,
      progress = 0,
      buffer = 0,
      variant = ProgressBar.variants.DETERMINATE,
      color = ProgressBar.colors.PRIMARY,
    },
    ref,
  ) => {
    const internalRef = useRef<HTMLDivElement>(null);
    const mergedRef = useMergedRef(ref, internalRef);
    const classNames = cx(className);

    const progressBarProps = useMemo(
      () => ({
        id,
        'data-testid': dataTestId || getTestId(ComponentDefaultTestId.PROGRESS_BAR, id),
        className: classNames,
        ref: mergedRef,
        variant: variant !== ProgressBarVariant.INDETERMINATE ? variant : undefined,
        color,
      }),
      [id, dataTestId, classNames, mergedRef, variant, color],
    );

    return <MUILinearProgress {...progressBarProps} value={progress} valueBuffer={buffer} />;
  },
);

export const ProgressBar = withStaticProps(ProgressBarComponent, {
  variants: ProgressBarVariant,
  colors: ProgressBarColor,
});

ProgressBar.displayName = 'ProgressBar';

export default ProgressBar;
