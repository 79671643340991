import { Checkbox as MUICheckbox, CheckboxProps as MUICheckboxProps } from '@mui/material';
import { CoreComponent, CoreComponentProps } from '@react-fe/core';
import { ChangeEvent, forwardRef, useMemo } from 'react';
import { ComponentDefaultTestId, getTestId } from '../../tests';

export type CheckboxProps = CoreComponentProps &
  Omit<MUICheckboxProps, 'onChange' | 'ref'> & {
    value?: boolean;
    name?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>, checked?: boolean) => void;
  };

export const Checkbox: CoreComponent<CheckboxProps, HTMLButtonElement> = forwardRef<HTMLButtonElement, CheckboxProps>(
  ({ id, 'data-testid': dataTestId, className, value = false, name = '', disabled, onChange, ...otherProps }, ref) => {
    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (!disabled && onChange) {
        onChange(e, checked);
      }
    };

    const checkboxProps = useMemo(() => {
      return {
        id,
        'data-testid': dataTestId || getTestId(ComponentDefaultTestId.CHECKBOX, id),
        className,
        disabled,
        checked: value,
        name,
        ...otherProps,
      };
    }, [id, dataTestId, className, name, disabled, value, otherProps]);

    return <MUICheckbox {...checkboxProps} onChange={handleCheckboxChange} />;
  },
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
