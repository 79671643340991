import { Link, useLocation } from 'react-router-dom';
import { MenuLink } from './nav.contants';
import classNames from 'classnames';
import { Icon, Text, Tooltip } from '@react-fe/common-ui';
import { useTranslation } from 'react-i18next';

interface NavMenuItemProps {
  isOpen: boolean;
  item: MenuLink;
}

export function NavMenuItem({ isOpen, item }: NavMenuItemProps) {
  const location = useLocation();
  const { t } = useTranslation();
  const title = t(item.title.replace(/-/g, '_'));

  const isActive = item.link === '/' ? location.pathname === item.link : location.pathname.startsWith(item.link);

  return (
    <li
      key={item.id}
      className={classNames('group', {
        'w-full': !isOpen,
      })}
    >
      <Link
        to={item.link}
        className={classNames('cursor-pointer', {
          'flex items-center gap-spacing-2': isOpen,
        })}
      >
        <Tooltip title={!isOpen ? title : ''} placement={Tooltip.placement?.RIGHT} enterDelay={0} className="!w-full">
          <div
            className={classNames('group-hover:scale-105 w-10 h-10 ', {
              'rounded-full flex items-center justify-center transition-all ease-out': isOpen,
              'transition-all ease-in-out bg-secondary-dark': isActive,
              'p-spacing-1 rounded-xl hover:bg-secondary-dark flex items-center justify-center': !isOpen,
            })}
          >
            {!isOpen ? (
              <Icon icon={item.icon} className="text-xl md:text-base text-white" />
            ) : (
              <Icon
                icon={item.icon}
                className={classNames('text-base', {
                  'text-white': isActive,
                  'text-bfg-cta-secondary': !isActive,
                })}
              />
            )}
          </div>
        </Tooltip>
        <Text
          className={classNames('text-text-primary transform capitalize', {
            '!text-bfg-cta-secondary': isActive,
            'absolute opacity-0 z-[-1]': !isOpen,
            'static opacity-100 transition-opacity ease-linear duration-300 delay-100 z-[1]': isOpen,
          })}
        >
          {title}
        </Text>
      </Link>
    </li>
  );
}
