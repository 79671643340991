import { Toast } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, ElementContent, useMergedRef } from '@react-fe/core';
import { useTypedBaseStore } from '@react-fe/expertunity-base/stores';
import { ToastState } from '@react-fe/expertunity-base/stores/toast-slice';
import cx from 'classnames';
import { forwardRef, useMemo, useRef } from 'react';

export type ToastProviderProps = CoreComponentProps & { children: ElementContent };

export const ToastProvider: CoreComponent<ToastProviderProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  ToastProviderProps
>(({ id, 'data-testid': dataTestId, className, children }, ref) => {
  const { toastOpen, toastConfiguration, hideToast } = useTypedBaseStore<ToastState>();
  const internalRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx(className);

  const toastProviderProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      ref: mergedRef,
    }),
    [id, dataTestId, classNames, mergedRef],
  );

  return (
    <div {...toastProviderProps}>
      {children}
      <Toast
        open={toastOpen}
        handleClose={() => {
          hideToast();
        }}
        message={toastConfiguration?.message ?? ''}
        {...toastConfiguration}
      />
    </div>
  );
});

ToastProvider.displayName = 'ToastProvider';

export default ToastProvider;
