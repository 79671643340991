import { Icon, Text } from '@react-fe/common-ui';
import { faAddressCard } from '@fortawesome/pro-regular-svg-icons';

export interface ContractorPersonProps {
  onClick?: () => void;
  firstName?: string | null;
  lastName?: string | null;
  ellipsis?: boolean;
}

export function ContactPerson({ onClick, firstName, lastName, ellipsis }: Readonly<ContractorPersonProps>) {
  if (!firstName && !lastName) {
    return null;
  }

  return (
    <div
      className={`overflow-hidden flex gap-spacing-1 items-center ${onClick ? 'cursor-pointer' : ''}`}
      onClick={onClick}
    >
      <Icon icon={faAddressCard} className="text-text-secondary"></Icon>
      <Text maxLines={1} ellipsis={ellipsis} variant={Text.variants.BODY2} color={Text.colors.INFO}>
        {firstName} {lastName}
      </Text>
    </div>
  );
}

export default ContactPerson;
