import { createBrowserRouter } from 'react-router-dom';
import PrivateRoute from './private-route/private-route';
import Root from './root/root';
import NotFound from './not-found/not-found';
import Dashboard from './dashboard/dashboard';
import Forbidden from './forbidden/forbidden';
import { router as buyerRouter } from '@react-fe/buyer';
import { router as sellerRouter } from '@react-fe/seller';
import { router as settingsRouter } from '@react-fe/settings';
import { router as noticeResumeRouter } from '@react-fe/notice-resume';
import { Namespaces } from '@react-fe/expertunity-base/constants';
import { faHome } from '@fortawesome/pro-regular-svg-icons';

const router = createBrowserRouter([
  {
    path: '',
    element: (
      <PrivateRoute>
        <Root />
      </PrivateRoute>
    ),
    handle: {
      crumb: () => ({ to: '', name: 'home', namespace: Namespaces.Common, icon: faHome }),
    },
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: 'forbidden',
        element: <Forbidden />,
      },
      buyerRouter,
      sellerRouter,
      noticeResumeRouter,
      ...settingsRouter,
    ],
  },
  {
    path: 'not-found',
    element: <NotFound />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

export default router;
