import { useMemo } from 'react';
import { Attachment } from '../models';
import { API_URL } from '@react-fe/expertunity-base';

export const useForceFileName = (attachment: Attachment) => {
  return useMemo(() => {
    if (!attachment.file.href.includes('force_name') && attachment.file.name) {
      return `${API_URL}${attachment.file.href}`.concat(`?force_name=${attachment.file.name}`);
    }
    return `${API_URL}${attachment.file.href}`;
  }, [attachment.file]);
};

export default useForceFileName;
