import React, { forwardRef, useMemo } from 'react';
import { ComponentDefaultTestId, DigitalClock, DigitalClockProps, getTestId } from '@react-fe/common-ui';
import { CoreComponent } from '@react-fe/core';
import { useField } from 'formik';
import { FormError } from '../form-error';
import { FormControlRootProps } from './form-control-root';

type FormControlMergedProps = FormControlRootProps & DigitalClockProps;

export const FormControlDigitalClock: CoreComponent<FormControlMergedProps, HTMLInputElement> = forwardRef<
  HTMLInputElement,
  FormControlMergedProps
>(({ id, 'data-testid': dataTestId, className, name = '', onChange: parentOnChange, ...otherProps }, ref) => {
  const [field, , helpers] = useField(name);
  const { setValue, setTouched } = helpers;

  const onChange = useMemo(
    () => (value: Date) => {
      setValue(value, true).catch();
      setTimeout(() => {
        setTouched(true, true).catch();
      }, 0);

      if (parentOnChange) {
        parentOnChange(value);
      }
    },
    [parentOnChange, setValue, setTouched],
  );

  const formControlMergedProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId || getTestId(ComponentDefaultTestId.FORM_CONTROL_DIGITAL_CLOCK, id),
      className,
      ...otherProps,
    }),
    [id, dataTestId, className, otherProps],
  );

  return (
    <div className="flex flex-col gap-spacing-1">
      <DigitalClock {...formControlMergedProps} {...field} onChange={onChange} />
      <FormError field={field} />
    </div>
  );
});

FormControlDigitalClock.displayName = 'FormControl.Datepicker';

export default FormControlDigitalClock;
