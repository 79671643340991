import { Attachment } from '@react-fe/expertunity-base/models';
import { useCallback } from 'react';

export const useDownloadFile = (attachment: Attachment) => {
  return useCallback(() => {
    const link = document.createElement('a');
    if (attachment._links?.download_url?.href) {
      link.href = attachment._links.download_url.href;
      link.download = attachment.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [attachment]);
};
