import { StateCreator } from 'zustand';
import { Config } from '../models';

export interface ConfigState {
  config: Config | null;
  setConfig: (config: Config) => void;
}

export const createConfigSlice: StateCreator<ConfigState> = set => ({
  config: null,
  setConfig: (config: Config) => {
    set({ config });
  },
});
