import { Link, Text } from '@react-fe/common-ui';

export interface HelperTextLinkProps {
  helperText: string;
  linkText: string;
  href: string;
}

export const HelperTextLink: React.FC<HelperTextLinkProps> = ({ helperText, linkText, href }) => {
  return (
    <span className="flex items-center gap-spacing-0.5">
      <Text variant={Text.variants.CAPTION} component={'span' as any}>
        {helperText}
      </Text>
      <Link textClassName="!text-[12px]" href={href} component={'span' as any}>
        {linkText}
      </Link>
    </span>
  );
};
