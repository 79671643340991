import { ComponentDefaultTestId, Datepicker, DatepickerProps, getTestId } from '@react-fe/common-ui';
import { CoreComponent } from '@react-fe/core';
import { useField } from 'formik';
import { forwardRef, useCallback, useMemo } from 'react';
import { FormError } from '../form-error';
import { FormControlRootProps } from './form-control-root';

type FormControlMergedProps = FormControlRootProps & Omit<DatepickerProps, 'onBlur'>;

export const FormControlDatepicker: CoreComponent<FormControlMergedProps, HTMLInputElement> = forwardRef<
  HTMLInputElement,
  FormControlMergedProps
>(
  (
    { id, 'data-testid': dataTestId, className, name = '', helperText, onChange: parentOnChange, ...otherProps },
    ref,
  ) => {
    const [field, meta, helpers] = useField(name);
    const { setValue } = helpers;
    const hasErrors = meta.touched && !!meta.error;

    const onChange = useCallback(
      (value: Date) => {
        if (value.getHours() === 0 && value.getMinutes() === 0) {
          value.setHours(23);
          value.setMinutes(59);
        }

        setValue(value, true).catch();

        if (parentOnChange) {
          parentOnChange(value);
        }
      },
      [setValue, parentOnChange],
    );

    const formControlMergedProps = useMemo(
      () => ({
        id,
        'data-testid': dataTestId || getTestId(ComponentDefaultTestId.FORM_CONTROL_DATEPICKER, id),
        className,
        helperText: hasErrors ? '' : helperText,
        ...otherProps,
      }),
      [id, dataTestId, className, hasErrors, helperText, otherProps],
    );

    return (
      <div className="flex flex-col">
        <Datepicker {...formControlMergedProps} {...field} error={hasErrors} onChange={onChange} />
        <FormError field={field} />
      </div>
    );
  },
);

FormControlDatepicker.displayName = 'FormControl.Datepicker';

export default FormControlDatepicker;
