import { faBuilding, faBuildings, faUser } from '@fortawesome/pro-regular-svg-icons';
import { Namespaces } from '@react-fe/expertunity-base/constants';
import { Navigate, RouteObject } from 'react-router-dom';

export const router: RouteObject[] = [
  {
    path: 'settings',
    async lazy() {
      const { Settings } = await import('./settings');
      return { Component: Settings };
    },
    children: [
      {
        path: '',
        element: <Navigate to="my-profile" replace={true} />,
      },
      {
        path: 'my-profile',
        async lazy() {
          const { MyProfile } = await import('./settings/tabs/my-profile');
          return { Component: MyProfile };
        },
        handle: {
          crumb: () => ({ to: '/my-profile', name: 'my_profile', icon: faUser }),
        },
      },
      {
        path: 'company-profile',
        async lazy() {
          const { CompanyProfile } = await import('./settings/tabs/company-profile');
          return { Component: CompanyProfile };
        },
        handle: {
          crumb: () => ({ to: '/company-profile', name: 'company_profile', icon: faBuilding }),
        },
      },
    ],
  },
  {
    path: 'company-directory',
    handle: {
      crumb: () => ({
        to: '/company-directory',
        name: 'company_directory',
        namespace: Namespaces.Common,
        icon: faBuildings,
      }),
    },
    children: [
      {
        index: true,
        async lazy() {
          const { CompanyDirectory } = await import('./company-directory');
          return { Component: CompanyDirectory };
        },
      },
      {
        path: ':clientUid',
        async lazy() {
          const { CompanyDirectoryDetailsRoot: CompanyDirectoryDetails } = await import('./company-directory-details');
          return { Component: CompanyDirectoryDetails };
        },
        handle: {
          crumb: () => ({
            to: '/company',
            name: 'company',
            namespace: Namespaces.Common,
            icon: faBuilding,
          }),
        },
      },
    ],
  },
];
