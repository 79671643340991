export type TypographyStaticProps = {
  aligns?: typeof TypographyAlign;
  colors?: typeof TypographyColor;
};

export enum TypographyAlign {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
  JUSTIFY = 'justify',
  INHERIT = 'inherit',
}

export enum TypographyColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success.main',
  ERROR = 'error.main',
  INFO = 'info.main',
  WARNING = 'warning.main',
  TEXT_PRIMARY = 'text.primary',
  TEXT_SECONDARY = 'text.secondary',
  TEXT_DISABLED = 'text.disabled',
  LIGHT = 'common.white',
  INHERIT = 'inherit',
}

export const TypographyStaticValues = {
  aligns: TypographyAlign,
  colors: TypographyColor,
} as const;
