import { Heading, HeadingVariant } from '@react-fe/common-ui';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { CustomerType } from '../nav/nav.contants';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

export function DashboardContent() {
  const { t } = useTranslation();

  // TODO: Replace with actual customer type once it's available
  const [customer] = useState([CustomerType.ISSUER]);

  const isIssuer = customer.includes(CustomerType.ISSUER);
  const isBidder = customer.includes(CustomerType.BIDDER);

  return (
    <div
      className={cn({
        'flex flex-col gap-spacing-8': isIssuer && isBidder,
      })}
    >
      {isIssuer && (
        <div className="flex items-center gap-spacing-2">
          <Heading variant={HeadingVariant.H5} className="!font-semibold">
            {t('projects')}
          </Heading>

          <Link
            to="/projects"
            className="text-primary underline underline-offset-4 transform hover:scale-[1.02] transition-all mt-1"
          >
            {t('view_all', { defaultValue: 'View all' })} {''}
            {t('projects').toLowerCase()}
          </Link>
        </div>
      )}

      {isBidder && (
        <div className="flex items-center gap-spacing-2">
          <Heading variant={HeadingVariant.H5} className="!font-semibold">
            {t('tenders')}
          </Heading>

          <Link
            to="/tenders"
            className="text-primary underline underline-offset-4 transform hover:scale-[1.02] transition-all mt-1"
          >
            {t('view_all', { defaultValue: 'View all' })} {''}
            {t('tenders').toLowerCase()}
          </Link>
        </div>
      )}
    </div>
  );
}

export default DashboardContent;
