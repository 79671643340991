import { ModalProps } from '@react-fe/common-ui';
import { ElementContent } from '@react-fe/core';
import { ModalState, useTypedBaseStore } from '../stores';

export type ModalConfiguration = Omit<ModalProps, 'open' | 'children'> & {
  modalId: string;
  content: ElementContent;
  isVisible?: boolean;
};

export const useModal = () => {
  const {
    addModalConfiguration,
    updateModalConfiguration,
    showModal,
    closeModal: closeModalState,
    contextModals,
  } = useTypedBaseStore<ModalState>();

  const openModal = (modalProps: ModalConfiguration) => {
    addModalConfiguration(modalProps);
    showModal(modalProps.modalId);
  };

  const updateModalContent = (modalId: string, content: ElementContent) => {
    const modalToUpdate = contextModals.find(modal => modal.modalId === modalId);
    if (modalToUpdate) {
      updateModalConfiguration({ ...modalToUpdate, content });
    }
  };

  const closeModal = (modalId: string) => {
    closeModalState(modalId);
  };

  return {
    openModal,
    closeModal,
    updateModalContent,
  };
};
