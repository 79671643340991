import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import cx from 'classnames';
import { forwardRef, useMemo, useRef } from 'react';
import { CreateOfferContactDetails } from './components/create-offer-contact-details';
import { CreateOfferFields } from './components/create-offer-fields';
import { CreateOfferOnBehalfFields } from './components/create-offer-on-behalf-fields';

export type CreateOfferCoverSheetProps = CoreComponentProps;

export const CreateOfferCoverSheet: CoreComponent<CreateOfferCoverSheetProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  CreateOfferCoverSheetProps
>(({ id, 'data-testid': dataTestId, className }, ref) => {
  const internalRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx(className, 'flex gap-spacing-3 w-full max-h-[500px] overflow-y-auto');

  const createOfferCoverSheetProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      ref: mergedRef,
    }),
    [id, dataTestId, classNames, mergedRef],
  );

  return (
    <div {...createOfferCoverSheetProps}>
      <CreateOfferFields className="w-2/4" />
      <div className="flex flex-col gap-spacing-2 w-2/4">
        <CreateOfferOnBehalfFields />
        <CreateOfferContactDetails />
      </div>
    </div>
  );
});

CreateOfferCoverSheet.displayName = 'CreateOfferCoverSheet';

export default CreateOfferCoverSheet;
