import { Menu as MUIMenu, MenuProps as MUIMenuProps, SxProps } from '@mui/material';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import cx from 'classnames';
import { forwardRef, useMemo, useRef } from 'react';

export interface MenuProps extends CoreComponentProps, Omit<MUIMenuProps, 'ref'> {
  customSx?: SxProps;
}

export const Menu: CoreComponent<MenuProps, HTMLDivElement> = forwardRef<HTMLDivElement, MenuProps>(
  ({ id, 'data-testid': dataTestId, className, children, customSx, ...muiMenuProps }, ref) => {
    const internalRef = useRef<HTMLDivElement>(null);
    const mergedRef = useMergedRef(ref, internalRef);
    const classNames = cx(className, '!px-spacing-2 !py-spacing-1');

    const menuProps = useMemo(
      () => ({
        id,
        'data-testid': dataTestId,
        className: classNames,
        ref: mergedRef,
        ...muiMenuProps,
      }),
      [id, dataTestId, classNames, mergedRef, muiMenuProps],
    );

    return (
      <MUIMenu
        {...menuProps}
        sx={{
          '& .MuiMenu-list': {
            display: 'flex',
            flexDirection: 'column',
            paddingX: '1rem',
            paddingY: '0.5rem',
            gap: '0.5rem',
          },
          ...customSx,
        }}
      >
        {children}
      </MUIMenu>
    );
  },
);

Menu.displayName = 'Menu';

export default Menu;
