import React, { forwardRef, useMemo, useRef } from 'react';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import { Divider as MUIDivider } from '@mui/material';
import cx from 'classnames';

export interface DividerProps extends CoreComponentProps {
  variants?: 'fullWidth' | 'inset' | 'middle';
  orientation?: 'horizontal' | 'vertical';
}

export const Divider: CoreComponent<DividerProps, HTMLDivElement> = forwardRef<HTMLDivElement, DividerProps>(
  ({ id, 'data-testid': dataTestId, className, variants = 'fullWidth', orientation = 'horizontal' }, ref) => {
    const internalRef = useRef<HTMLDivElement>(null);
    const mergedRef = useMergedRef(ref, internalRef);
    const classNames = cx(className);

    const dividerProps = useMemo(
      () => ({
        id,
        'data-testid': dataTestId,
        className: classNames,
        ref: mergedRef,
        orientation,
        variants,
      }),
      [id, dataTestId, classNames, mergedRef, orientation, variants],
    );

    return <MUIDivider {...dividerProps} component={'div'} flexItem />;
  },
);

Divider.displayName = 'Divider';

export default Divider;
