import React from 'react';
import { MenuItem, Text } from '@react-fe/common-ui';
import { Language } from '@react-fe/i18n-utils';

export const renderMenuItem = (language: Language) => {
  return (
    <MenuItem
      key={`menu-item-${language.label}`}
      value={language.normalizedCountryCode}
      className="flex flex-row gap-spacing-2 items-center justify-between w-full"
    >
      <Text variant={Text.variants?.BODY1}>{language.label}</Text>
    </MenuItem>
  );
};
