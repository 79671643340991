import { StateCreator } from 'zustand';
import { Offer } from '../models';

export interface AcceptRejectOfferState {
  openAcceptModal: boolean;
  openRejectModal: boolean;
  offerToAcceptOrReject: Offer | null;
  setOpenAcceptModal: (open: boolean) => void;
  setOpenRejectModal: (open: boolean) => void;
  setOfferToAcceptOrReject: (offer: Offer) => void;
}

export const createAcceptRejectOfferSlice: StateCreator<AcceptRejectOfferState> = set => ({
  openAcceptModal: false,
  openRejectModal: false,
  offerToAcceptOrReject: null,
  setOpenAcceptModal: (open: boolean) => {
    set({ openAcceptModal: open });
  },
  setOpenRejectModal: (open: boolean) => {
    set({ openRejectModal: open });
  },
  setOfferToAcceptOrReject: (offer: Offer) => {
    set({ offerToAcceptOrReject: offer });
  },
});
