import axios from 'axios';
import router from '../routers/router';

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 404) {
      router.navigate('/not-found');
    }

    if (error.response && error.response.status === 403) {
      router.navigate('/forbidden');
    }

    return Promise.reject(error);
  },
);
