import React, { forwardRef, useMemo, useRef } from 'react';
import styles from './modal.module.scss';
import { Backdrop, Dialog, DialogActions, DialogContent, DialogTitle, Fade, SxProps } from '@mui/material';
import { CoreComponent, CoreComponentProps, useMergedRef, withStaticProps } from '@react-fe/core';
import { Button } from '../button';
import { ButtonType } from '../button/button.constants';
import { ModalVariant } from './modal.constants';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import cx from 'classnames';

export interface ModalProps extends CoreComponentProps {
  open: boolean;
  handleClose?: (event: any, reason?: string) => void;
  handleConfirm?: () => void;
  title?: string;
  shouldShowCloseButton?: boolean;
  showHeader?: boolean;
  children: React.ReactNode;
  fullWidth?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  customWidth?: number;
  showActionButtons?: boolean;
  customActionButtons?: React.ReactNode;
  scroll?: 'paper' | 'body';
  variant?: ModalVariant;
  customSx?: SxProps;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const ModalComponent: CoreComponent<ModalProps, HTMLDivElement> & {
  variant?: typeof ModalVariant;
} = forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      id,
      'data-testid': dataTestId,
      className,
      open,
      handleClose,
      title,
      children,
      handleConfirm,
      fullWidth = false,
      maxWidth = false,
      shouldShowCloseButton = true,
      showActionButtons = false,
      customWidth,
      scroll = 'paper',
      customActionButtons,
      showHeader = true,
      variant = ModalVariant.DEFAULT,
      customSx,
      onClick,
    },
    ref,
  ) => {
    const internalRef = useRef<HTMLDivElement>(null);
    const mergedRef = useMergedRef(ref, internalRef);

    const modalProps = useMemo(() => {
      return {
        id,
        'data-testid': dataTestId,
        ref: mergedRef,
        open,
        closeAfterTransition: true,
        BackdropComponent: Backdrop,
        fullWidth,
        maxWidth,
        scroll,
      };
    }, [id, dataTestId, mergedRef, open, fullWidth, maxWidth, scroll]);

    return (
      <Dialog
        onClick={onClick}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Fade}
        className={cx([className, styles.modalWrapper])}
        sx={
          customSx || {
            '& .MuiDialog-container': {
              '& .MuiPaper-root': {
                backgroundColor: 'background.default',
                width: customWidth
                  ? `${customWidth}px`
                  : maxWidth || fullWidth
                    ? '100%'
                    : variant === ModalVariant.LARGE
                      ? '80vw'
                      : '512px',
                maxWidth: ModalVariant.LARGE ? '80vw' : 'auto',
                padding: ModalVariant.LARGE ? '0' : '32px',
                borderRadius: '4px',
                border: '1px solid #E3E5E4',
                '& .MuiDialogContent-root': {
                  padding: variant === ModalVariant.LARGE ? '0' : 'auto',
                  height: variant === ModalVariant.LARGE ? '90vh' : '100%',
                },
              },
            },
          }
        }
        {...modalProps}
      >
        {showHeader && (
          <div
            className={cx(styles.modalHeader, {
              [styles.headerWithoutTitle]: !title,
            })}
          >
            {title && <DialogTitle className={styles.modalTitle}>{title}</DialogTitle>}
            {shouldShowCloseButton && (
              <Button.Root type={ButtonType.GHOST} onClick={handleClose} className={styles.modalCloseButton}>
                <Button.Icon icon={faTimes}></Button.Icon>
              </Button.Root>
            )}
          </div>
        )}

        <DialogContent
          className={cx({
            [styles.modalContent]: variant === ModalVariant.DEFAULT,
            [styles.withoutTitle]: !title && showHeader,
          })}
        >
          {children}
        </DialogContent>
        {(showActionButtons || handleConfirm) && (
          <DialogActions className={styles.modalActions}>
            {customActionButtons ? (
              customActionButtons
            ) : (
              <>
                <Button.Root type={Button.Root.types.OUTLINED} onClick={handleClose}>
                  Cancel
                </Button.Root>
                <Button.Root onClick={handleConfirm}>Confirm</Button.Root>
              </>
            )}
          </DialogActions>
        )}
      </Dialog>
    );
  },
);

export const Modal = withStaticProps(ModalComponent, {
  variant: ModalVariant,
});

Modal.displayName = 'Modal';

export default Modal;
