import React, { forwardRef, useMemo, useRef } from 'react';
import styles from './spinner.module.scss';
import { CoreComponent, CoreComponentProps, useMergedRef, withStaticProps } from '@react-fe/core';
import { SpinnerColors, SpinnerSize, SpinnerSizes } from './spinner.constants';
import { ComponentDefaultTestId, getTestId } from '@react-fe/common-ui';
import cx from 'classnames';

export interface SpinnerProps extends CoreComponentProps {
  size?: SpinnerSize;
  color?: SpinnerColors;
  hasBackground?: boolean;
}

const SpinnerComponent: CoreComponent<SpinnerProps, HTMLDivElement> & {
  sizes?: typeof SpinnerSizes;
  colors?: typeof SpinnerColors;
} = forwardRef<HTMLDivElement, SpinnerProps>(
  (
    {
      id,
      'data-testid': dataTestId,
      className,
      size = Spinner.sizes?.MEDIUM,
      color = Spinner.colors?.PRIMARY,
      hasBackground = false,
    },
    ref,
  ) => {
    const internalRef = useRef<HTMLDivElement>(null);
    const mergedRef = useMergedRef(ref, internalRef);

    const sizeStyle = useMemo(() => {
      if (typeof size === 'number') {
        return { width: size, height: size };
      }
      return undefined;
    }, [size]);

    const spinnerProps = useMemo(
      () => ({
        id,
        'data-testid': dataTestId || getTestId(ComponentDefaultTestId.SPINNER, id),
        className: cx(styles.spinnerContainer, color && styles[color], className),
        ref: mergedRef,
        style: sizeStyle,
        role: 'alert',
        title: 'loading',
      }),
      [id, dataTestId, className, mergedRef, color, sizeStyle],
    );

    return (
      <div {...spinnerProps}>
        <svg className={cx(styles.circleSpinner)} viewBox="0 0 50 50" aria-hidden>
          {hasBackground && (
            <circle className={styles.circleSpinnerBackground} cx="25" cy="25" r="20" strokeWidth="5" />
          )}
          <circle className={styles.circleSpinnerPath} cx="25" cy="25" r="20" strokeWidth="5" />
        </svg>
      </div>
    );
  },
);

export const Spinner = withStaticProps(SpinnerComponent, {
  sizes: SpinnerSizes,
  colors: SpinnerColors,
});

Spinner.displayName = 'Spinner';

export default Spinner;
