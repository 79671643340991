import { Button, Dropdown, DropdownItem, Title } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import { Namespaces } from '@react-fe/expertunity-base/constants';
import { AttachmentCategory } from '@react-fe/expertunity-base/models';
import cx from 'classnames';
import { forwardRef, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type EditCategoryModalProps = CoreComponentProps & {
  categories: DropdownItem[];
  categoryPreset: AttachmentCategory;
  onClose: (result?: AttachmentCategory) => void;
};

export const EditCategoryModal: CoreComponent<EditCategoryModalProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  EditCategoryModalProps
>(({ id, 'data-testid': dataTestId, className, categories, categoryPreset, onClose }, ref) => {
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState<AttachmentCategory>(categoryPreset);
  const internalRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx(className, 'flex flex-col gap-spacing-3');

  const editCategoryModalProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      ref: mergedRef,
    }),
    [id, dataTestId, classNames, mergedRef],
  );

  return (
    <div {...editCategoryModalProps}>
      <Title
        title={t('edit_category_modal_title', { ns: Namespaces.CategorizedAttachment, defaultValue: 'Edit category' })}
        subTitle={t('edit_category_modal_sub_title', {
          ns: Namespaces.CategorizedAttachment,
          defaultValue: 'Edit the category this document is grouped in',
        })}
      />

      <Dropdown
        options={categories}
        onChange={selected => setSelectedCategory(selected.target.value)}
        value={selectedCategory}
        label={t('categorized_attachment_form_description_label', {
          ns: Namespaces.CategorizedAttachment,
        })}
      />

      <div className="flex flex-row gap-spacing-2 self-end">
        <Button.Root type={Button.Root.types.OUTLINED} onClick={() => onClose()}>
          {t('cancel')}
        </Button.Root>
        <Button.Root type={Button.Root.types.PRIMARY} onClick={() => onClose(selectedCategory)}>
          {t('edit_category_modal_update_button', {
            ns: Namespaces.CategorizedAttachment,
            defaultValue: 'Update',
          })}
        </Button.Root>
      </div>
    </div>
  );
});

EditCategoryModal.displayName = 'EditCategoryModal';

export default EditCategoryModal;
