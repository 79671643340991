import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const useCountryName = (): {
  countryName: (code: string) => string;
} => {
  const { t } = useTranslation();

  const countryName = useCallback(
    (code: string) => {
      return t(`country_${code.toLowerCase()}`);
    },
    [t],
  );

  return { countryName };
};
