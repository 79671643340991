import COLORS from '@react-fe/constants';
import React from 'react';

import { AlaskaBoldTtf, AlaskaMediumTtf, AlaskaRegularTtf, AlaskaSemiBoldTtf } from '../assets/fonts/ttf';
import { AlaskaBoldWoff2, AlaskaMediumWoff2, AlaskaRegularWoff2, AlaskaSemiBoldWoff2 } from '../assets/fonts/woff2';

import '../styles/index.scss';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body1Bold: React.CSSProperties;
    body2Bold: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body1Bold?: React.CSSProperties;
    body2Bold?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body1Bold: true;
    body2Bold: true;
  }
}

const globalApplicationThemeConfiguration = {
  palette: {
    common: {
      white: COLORS.common.white,
      black: COLORS.common.black,
    },
    primary: {
      main: COLORS.primary.DEFAULT,
      light: COLORS.primary.light,
      dark: COLORS.primary.dark,
      contrastText: COLORS.primary.contrast,
    },
    secondary: {
      main: COLORS.secondary.DEFAULT,
      light: COLORS.secondary.light,
      dark: COLORS.secondary.dark,
      contrastText: COLORS.secondary.contrast,
    },
    error: {
      main: COLORS.error.DEFAULT,
      light: COLORS.error.light,
      dark: COLORS.error.dark,
      contrastText: COLORS.error.contrast,
    },
    warning: {
      main: COLORS.warning.DEFAULT,
      light: COLORS.warning.light,
      dark: COLORS.warning.dark,
      contrastText: COLORS.warning.contrast,
    },
    info: {
      main: COLORS.info.DEFAULT,
      light: COLORS.info.light,
      dark: COLORS.info.dark,
      contrastText: COLORS.info.contrast,
    },
    success: {
      main: COLORS.success.DEFAULT,
      light: COLORS.success.light,
      dark: COLORS.success.dark,
      contrastText: COLORS.success.contrast,
    },
    text: {
      primary: COLORS.text.primary,
      secondary: COLORS.text.secondary,
      disabled: COLORS.text.disabled,
    },
    background: {
      default: COLORS.background.default,
      paper: COLORS.background['paper-elevation-0'],
    },
    action: {
      active: COLORS.action.active,
      hover: COLORS.action.hover,
      hoverOpacity: 0.04,
      selected: COLORS.action.selected,
      selectedOpacity: 0.08,
      disabled: COLORS.action.disabled,
      disabledBackground: COLORS.action.disabledBackground,
      disabledOpacity: 0.38,
      focus: COLORS.action.focus,
      focusOpacity: 0.12,
      activatedOpacity: 0.56,
    },
    divider: COLORS.action.divider,
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: ['Alaska', 'sans-serif'].join(','),
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: '96px',
      fontWeight: 300,
      lineHeight: 1.167,
      letterSpacing: '-1.5px',
    },
    h2: {
      fontSize: '60px',
      fontWeight: 300,
      lineHeight: 1.2,
      letterSpacing: '-0.5px',
    },
    h3: {
      fontSize: '48px',
      fontWeight: 400,
      lineHeight: 1.167,
      letterSpacing: '0px',
    },
    h4: {
      fontSize: '34px',
      fontWeight: 400,
      lineHeight: 1.235,
      letterSpacing: '0.3px',
    },
    h5: {
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: 1.334,
      letterSpacing: '0px',
    },
    h6: {
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: 1.6,
      letterSpacing: '0.2px',
    },
    body1: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0.2px',
    },
    body1Bold: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: 1.5,
      letterSpacing: '0.2px',
    },
    body2: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.2px',
    },
    body2Bold: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: 1.43,
      letterSpacing: '0.2px',
    },
    overline: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: 2.66,
      letterSpacing: '1px',
    },
    caption: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: 1.66,
      letterSpacing: '0.4px',
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: 1.75,
      letterSpacing: '0.2px',
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: 1.57,
      letterSpacing: '0.1px',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
       @font-face {
          font-family: 'Alaska';
          font-style: normal;
          font-weight: 400;
          src:
            url(${AlaskaRegularWoff2}) format('woff2'),
            url(${AlaskaRegularTtf}) format('truetype');
        }

        @font-face {
          font-family: 'Alaska';
          font-style: normal;
          font-weight: 500;
          src:
            url(${AlaskaMediumWoff2}) format('woff2'),
            url(${AlaskaMediumTtf}) format('truetype');
        }

        @font-face {
          font-family: 'Alaska';
          font-style: normal;
          font-weight: 600;
          src:
            url(${AlaskaSemiBoldWoff2}) format('woff2'),
            url(${AlaskaSemiBoldTtf}) format('truetype');
        }

        @font-face {
          font-family: 'Alaska';
          font-style: normal;
          font-weight: 700;
          src:
            url(${AlaskaBoldWoff2}) format('woff2'),
            url(${AlaskaBoldTtf}) format('truetype');
        }
      `,
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root': {
            fontSize: '0.75rem',
            fontWeight: 400,
            letterSpacing: '0.009375rem',
            lineHeight: '0.75rem',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            fontSize: '0.75rem',
            fontWeight: 400,
            letterSpacing: '0.009375rem',
            lineHeight: '0.75rem',
          },
        },
      },
    },
  },
};

export { globalApplicationThemeConfiguration };
