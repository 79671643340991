import { TreeViewBaseItem } from '@mui/x-tree-view';

export function flattenTreeItems(items: TreeViewBaseItem[]): TreeViewBaseItem[] {
  return items.reduce((acc: TreeViewBaseItem[], item) => {
    acc.push(item);
    if (item.children) {
      acc.push(...flattenTreeItems(item.children));
    }
    return acc;
  }, []);
}
