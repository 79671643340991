import { RouteObject } from 'react-router-dom';

export const router: RouteObject = {
  path: 'notice-resume',
  handle: {
    crumb: () => ({ to: '/notice-resume', name: 'Notice Resume' }),
  },
  children: [
    {
      index: true,
      async lazy() {
        const { NoticeResume } = await import('./notice-resume');
        return { Component: NoticeResume };
      },
    },
  ],
};
