import { FormControl as MuiFormControl, InputLabel as MUIInputLabel } from '@mui/material';
import { DropdownItem, MultiSelectDropdown, MultiSelectDropdownRef } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import { Namespaces } from '@react-fe/expertunity-base';
import cx from 'classnames';
import { ChangeEvent, forwardRef, useCallback, useEffect, useId, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useTypesOfWork from './hooks/use-types-of-work';

export interface TenderTypeOfWorkFilterProps extends CoreComponentProps {
  filtersCleared: boolean;
  valuesFromSettings?: {
    filter_criteria: {
      types_of_work: string[];
    };
  };
  setTypesOfWork: (typesOfWork: string[]) => void;
}

export const TypeOfWorkFilter: CoreComponent<TenderTypeOfWorkFilterProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  TenderTypeOfWorkFilterProps
>(({ id, 'data-testid': dataTestId, className, filtersCleared, valuesFromSettings, setTypesOfWork }, ref) => {
  const { t } = useTranslation();
  const { data } = useTypesOfWork();
  const [selectedTypes, setSelectedTypes] = useState<DropdownItem[] | string[]>(['']);

  const dropdownRef = useRef<MultiSelectDropdownRef>(null);
  const internalRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx(className);
  const inputId = useId();

  const defaultOption = useMemo(
    () => ({
      label: t('tender_types_of_work_filter_all_option', { ns: Namespaces.SellerTendersPage, defaultValue: 'All' }),
      value: '',
      disabled: false,
    }),
    [t],
  );

  const enhancedTypesOfWork = useMemo(() => {
    return [
      defaultOption,
      ...(data?.map(typeOfWork => ({
        label: t(`${typeOfWork.label.replace(/-/g, '_')}`, { ns: Namespaces.TypesOfWork }),
        value: typeOfWork.uid,
        disabled: false,
      })) || []),
    ];
  }, [defaultOption, t, data]);

  const handleChange = useCallback(
    (event: ChangeEvent<{ value: any }>) => {
      const selectedTypes = event.target.value;
      if (!selectedTypes.length || (selectedTypes.length === 1 && selectedTypes[0] === '')) {
        defaultOption.disabled = false;
        setTypesOfWork([]);
        setSelectedTypes(['']);
        event.target.value = [''];
      } else {
        const filteredTypes = selectedTypes.filter((area: string) => area !== '');
        defaultOption.disabled = true;
        setTypesOfWork(filteredTypes);
        setSelectedTypes(filteredTypes);
        event.target.value = filteredTypes;
      }
    },
    [defaultOption, setTypesOfWork, setSelectedTypes],
  );

  useEffect(() => {
    if (!valuesFromSettings || enhancedTypesOfWork.length <= 1) return;

    const typesOfWork = valuesFromSettings.filter_criteria.types_of_work;
    if (typesOfWork.length === 0) return;

    defaultOption.disabled = true;
    dropdownRef.current?.handleChange({
      target: { value: typesOfWork },
    } as React.ChangeEvent<{ value: unknown }>);
  }, [valuesFromSettings, defaultOption, enhancedTypesOfWork]);

  useEffect(() => {
    if (filtersCleared) {
      setSelectedTypes(['']);
    }
  }, [filtersCleared]);

  const tenderTypeOfWorkFilterProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      ref: mergedRef,
    }),
    [id, dataTestId, classNames, mergedRef],
  );

  return (
    <div {...tenderTypeOfWorkFilterProps}>
      <MuiFormControl>
        <MUIInputLabel
          id={inputId}
          sx={{
            top: '4px',
            left: '6px',
            background: 'white',
            paddingLeft: '6px',
            paddingRight: '6px',
          }}
        >
          {t('tender_types_of_work_filter_label', { ns: Namespaces.SellerTendersPage, defaultValue: 'Type of work' })}
        </MUIInputLabel>
        <MultiSelectDropdown
          ref={dropdownRef}
          labelId={inputId}
          grow={false}
          className="min-w-[15rem] !max-w-[15rem]"
          options={enhancedTypesOfWork}
          value={selectedTypes}
          onChange={handleChange}
          customSx={{
            '& .MuiOutlinedInput-notchedOutline': {
              borderRadius: '100px !important',
            },
          }}
        />
      </MuiFormControl>
    </div>
  );
});

TypeOfWorkFilter.displayName = 'TypeOfWorkFilter';

export default TypeOfWorkFilter;
