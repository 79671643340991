import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import Nav from '../../components/nav/nav';
import Header from '../../components/header/header';
import { Button, Heading } from '@react-fe/common-ui';
import { Link } from 'react-router-dom';

export function NotFound() {
  const { t } = useTranslation();
  const auth = useAuth();

  const NotFoundContent = () => (
    <div className="grow flex flex-col items-center justify-center gap-3">
      <div className="flex flex-col items-center">
        <Heading variant={Heading.variants.H3}>404</Heading>
        <Heading>{t('not_found')}</Heading>
      </div>
      <h1>{t('not_found_subtitle')}</h1>
      <Link to="/">
        <Button.Root>{t('return_to_dashboard')}</Button.Root>
      </Link>
    </div>
  );

  if (auth.isAuthenticated) {
    return (
      <div className="min-h-screen flex bg-[#F7F9FC]">
        <Nav />
        <main className={'grow flex flex-col pr-spacing-4 pb-spacing-4 pl-spacing-3 overflow-x-hidden'}>
          <div className="sticky top-0 z-10 pt-spacing-4 pb-spacing-3 bg-[#F7F9FC]">
            <Header />
          </div>
          <NotFoundContent />
        </main>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex bg-[#F7F9FC]">
      <NotFoundContent />
    </div>
  );
}

export default NotFound;
