import { FormControl } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import { Namespaces } from '@react-fe/expertunity-base/constants';
import cx from 'classnames';
import { forwardRef, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressFormControlNames } from './address-form.constants';
import { CountryInput } from './components/country-input';
import { ZipInput } from './components/zip-input/zip-input';
import { CityInput } from './components/city-input/city-input';

export type AddressFormProps = CoreComponentProps & {
  required?: boolean;
};

export const AddressForm: CoreComponent<AddressFormProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  AddressFormProps
>(({ id, 'data-testid': dataTestId, className, required = true }, ref) => {
  const { t } = useTranslation();
  const internalRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx(className, 'flex flex-col gap-spacing-3');

  const addressFormProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      ref: mergedRef,
    }),
    [id, dataTestId, classNames, mergedRef],
  );

  return (
    <div {...addressFormProps}>
      <FormControl.Label label={t('address_form_title', { ns: Namespaces.ProjectsPage })} required={required} />

      <div className="flex flex-col gap-spacing-3">
        <FormControl.Root name={AddressFormControlNames.STREET}>
          <FormControl.TextField
            required={required}
            label={t('address_form_street_label', { ns: Namespaces.ProjectsPage })}
            placeholder={t('address_form_street_placeholder', { ns: Namespaces.ProjectsPage })}
          />
        </FormControl.Root>

        <div className="w-full flex gap-spacing-3">
          <ZipInput required={required} />

          <CityInput required={required} />
        </div>

        <CountryInput required={required} />
      </div>
    </div>
  );
});

AddressForm.displayName = 'AddressForm';

export default AddressForm;
