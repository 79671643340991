import { Button, UploadItem, uploadMultipleFiles } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, logger, useMergedRef } from '@react-fe/core';
import { Namespaces } from '@react-fe/expertunity-base/constants';
import { UploadedContent } from '@react-fe/expertunity-base/models';
import useBaseStore from '@react-fe/expertunity-base/stores';
import { AxiosProgressEvent } from 'axios';
import cx from 'classnames';
import { useField } from 'formik';
import React, { forwardRef, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export type UploadAttachmentsButtonProps = CoreComponentProps & {
  transformAttachment: (uploadedContent: any) => any;
  uploadEndpoint: string;
  name: string;
};

export const UploadAttachmentsButton: CoreComponent<UploadAttachmentsButtonProps, HTMLInputElement> = forwardRef<
  HTMLInputElement,
  UploadAttachmentsButtonProps
>(({ id, 'data-testid': dataTestId, className, transformAttachment, uploadEndpoint, name }, ref) => {
  const { t } = useTranslation();
  const { setIsSubmittingNewAttachments } = useBaseStore();
  const [field, , helpers] = useField(name);
  const internalRef = useRef<HTMLInputElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx(className, 'w-fit');

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    setIsSubmittingNewAttachments(true);

    if (files) {
      const uploadItems: UploadItem[] = Array.from(files).map(file => ({
        file,
        onProgress: (progressEvent: AxiosProgressEvent) => {
          logger.debug('upload progress: ', JSON.stringify(progressEvent));
        },
        onSuccess: (data: UploadedContent) => {
          logger.debug('upload success: ', JSON.stringify(data));
          setIsSubmittingNewAttachments(false);
        },
        onError: error => {
          logger.error(`Error occured while trying to upload new file: ${JSON.stringify(error)}`);
          setIsSubmittingNewAttachments(false);
        },
      }));

      const results = await uploadMultipleFiles(uploadItems, uploadEndpoint);
      const transformedAttachments = results.map(result => transformAttachment(result));
      const newAttachments = [...field.value, ...transformedAttachments];
      helpers.setValue(newAttachments, true);
    }
  };

  return (
    <>
      <input
        type="file"
        id={id}
        data-testid={dataTestId}
        className="hidden"
        multiple
        onChange={handleFileChange}
        ref={mergedRef}
      />
      <Button.Root className={classNames} onClick={() => internalRef.current?.click()}>
        {t('categorized_attachments_form_upload_button', {
          ns: Namespaces.CategorizedAttachment,
          defaultValue: 'Upload attachment',
        })}
      </Button.Root>
    </>
  );
});

UploadAttachmentsButton.displayName = 'UploadAttachmentsButton';

export default UploadAttachmentsButton;
