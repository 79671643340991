import { Attachment } from '@react-fe/expertunity-base/models';
import { OtherAttachments } from './other-attachments';
import { WorkDescriptionAttachments } from './work-description-attachments';

export const AttachmentsList = ({
  workDescriptionTemplates,
  otherAttachments,
}: {
  workDescriptionTemplates: Attachment[];
  otherAttachments: Attachment[];
}) => {
  return (
    <div className="flex flex-col gap-spacing-4">
      <WorkDescriptionAttachments workDescriptionTemplates={workDescriptionTemplates} />
      <OtherAttachments otherAttachments={otherAttachments} />
    </div>
  );
};
