import { CoreComponent, CoreComponentProps, ElementContent, useMergedRef } from '@react-fe/core';
import cx from 'classnames';
import { Form as FormikForm, useFormikContext } from 'formik';
import { forwardRef, useEffect, useMemo, useRef } from 'react';

export interface FormProps extends CoreComponentProps {
  children?: ElementContent;
}

export const Form: CoreComponent<FormProps, HTMLFormElement> = forwardRef<HTMLFormElement, FormProps>(
  ({ id, 'data-testid': dataTestId, className, children }, ref) => {
    const internalRef = useRef<HTMLFormElement>(null);
    const mergedRef = useMergedRef(ref, internalRef);
    const classNames = cx(className, 'flex flex-col gap-spacing-3');
    const { submitCount, isValid } = useFormikContext();

    useEffect(() => {
      if (!isValid && submitCount > 0 && internalRef.current) {
        const firstErrorInput = internalRef.current.querySelector<HTMLElement>(
          'input[aria-invalid="true"], textarea[aria-invalid="true"], select[aria-invalid="true"]',
        );
        firstErrorInput?.focus();
      }
    }, [submitCount, isValid]);

    const formProps = useMemo(
      () => ({
        id,
        'data-testid': dataTestId,
        className: classNames,
        ref: mergedRef,
      }),
      [id, dataTestId, classNames, mergedRef],
    );

    return (
      <FormikForm noValidate={true} {...formProps}>
        {children}
      </FormikForm>
    );
  },
);

Form.displayName = 'Form';

export default Form;
