import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { API_URL } from '@react-fe/expertunity-base/constants';
import { InternalComment, QueryKeys } from '@react-fe/expertunity-base/models';

export function useOfferInternalComments(tenderUid: string, offerUid: string): UseQueryResult<InternalComment[]> {
  return useQuery<InternalComment[]>({
    queryKey: [QueryKeys.OFFER_COMMENTS, tenderUid, offerUid],
    queryFn: async (): Promise<InternalComment[]> => {
      const response = await axios.get<InternalComment[]>(
        `${API_URL}/tenders/${tenderUid}/offers/${offerUid}/comments?include_history=true`,
      );
      return response.data;
    },
  });
}

export default useOfferInternalComments;
