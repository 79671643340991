import { Skeleton } from '@react-fe/common-ui';

export const OfferFormSkeleton = () => {
  return (
    <div className="flex flex-col gap-spacing-2">
      <div className="flex flex-col gap-spacing-1">
        <Skeleton height={42} width={200} variant={'rectangular'} />
        <Skeleton height={24} width={450} variant={'rectangular'} />
      </div>

      <div className="flex flex-row justify-between">
        <Skeleton height={44} width={300} variant={'rectangular'} />
        <Skeleton height={44} width={150} variant={'rectangular'} />
      </div>

      <div className="w-full">
        <Skeleton height={88} variant={'rectangular'} />
      </div>

      <div className="flex flex-row gap-spacing-3">
        <div className="flex flex-col gap-spacing-2 w-2/4">
          <div className="flex flex-col gap-spacing-2">
            <div className="flex flex-col gap-spacing-1">
              <Skeleton height={44} width={350} variant={'rectangular'} />
              <Skeleton height={24} width={650} variant={'rectangular'} />
            </div>

            <Skeleton height={88} variant={'rectangular'} />
          </div>

          <div className="flex flex-col gap-spacing-2">
            <div className="flex flex-col gap-spacing-1">
              <Skeleton height={44} width={350} variant={'rectangular'} />
              <Skeleton height={24} width={650} variant={'rectangular'} />
            </div>

            <div className="flex flex-col gap-spacing-1">
              <Skeleton height={88} variant={'rectangular'} />
              <Skeleton height={88} variant={'rectangular'} />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-spacing-2 w-2/4">
          <Skeleton className="w-1/4" height={16} variant={'rectangular'} />
          <Skeleton height={450} variant={'rectangular'} />
        </div>
      </div>

      <div className="flex flex-row justify-between">
        <Skeleton height={44} width={150} variant={'rectangular'} />
        <Skeleton height={44} width={150} variant={'rectangular'} />
      </div>
    </div>
  );
};
