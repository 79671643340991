import { Switch } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import { Namespaces } from '@react-fe/expertunity-base';
import cx from 'classnames';
import { forwardRef, useId, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export interface OnlyFavouriteSwitchProps extends CoreComponentProps {
  filtersCleared: boolean;
  value?: boolean;
  setValue: (value: boolean) => void;
}

export const OnlyFavouriteSwitch: CoreComponent<OnlyFavouriteSwitchProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  OnlyFavouriteSwitchProps
>(({ id, 'data-testid': dataTestId, className, filtersCleared, value = false, setValue }, ref) => {
  const { t } = useTranslation();
  const internalRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const switchId = useId();
  const classNames = cx(className);

  const onSwitchChange = () => {
    setValue(!value);
  };

  const tenderFavoriteFilterProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      ref: mergedRef,
    }),
    [id, dataTestId, classNames, mergedRef],
  );

  return (
    <div {...tenderFavoriteFilterProps}>
      <label htmlFor={switchId} className={'flex flex-row items-center cursor-pointer w-fit'}>
        <Switch id={switchId} value={value} onChange={onSwitchChange} />
        <span>
          {t('only_favourite_switch_label', {
            ns: Namespaces.Common,
            defaultValue: 'Only favourites',
          })}
        </span>
      </label>
    </div>
  );
});

OnlyFavouriteSwitch.displayName = 'OnlyFavouriteSwitch';

export default OnlyFavouriteSwitch;
