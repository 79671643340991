import { ToastType } from '@react-fe/common-ui';
import { ElementContent, logger, withStaticProps } from '@react-fe/core';
import { Namespaces } from '@react-fe/expertunity-base/constants';
import useBaseStore, { BaseStore, useTypedBaseStore } from '@react-fe/expertunity-base/stores';
import { Offer, OfferState } from '@react-fe/expertunity-base/models';
import { Formik, FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { OfferFormFields, OfferFormMode } from '../../create-offer-modal.constants';
import useCreateOffer from '../../hooks/use-create-offer';
import { OfferMapper } from '../../utils/create-offer-mapper';
import { OfferFormSkeleton } from './create-offer-skeleton';
import { useTenderAttachments, useToast } from '@react-fe/expertunity-base/hooks';
import useEditOffer from '@react-fe/expertunity-base/components/create-offer-modal/hooks/use-edit-offer.ts';

export interface OfferFormFormikProps {
  offer?: Offer;
  children: ElementContent;
  mode?: OfferFormMode;
}

const OfferFormFormikComponent: React.FC<OfferFormFormikProps> & { modes?: typeof OfferFormMode } = ({
  offer,
  children,
  mode = OfferFormMode.CREATE,
}) => {
  const { t } = useTranslation();
  const { tenderUid } = useParams();
  const { data: tenderAttachments, isLoading: isLoadingTenderAttachments } = useTenderAttachments(tenderUid as string);
  const {
    offerValidationSchema,
    tenderDetails,
    setTenderAttachments,
    setIsOpenCreateOfferModal,
    clearCreateOfferState,
    setCurrentBidderUid,
  } = useBaseStore();
  const createOffer = useCreateOffer();
  const editOffer = useEditOffer(offer?.uid as string, offer?.state as OfferState);
  const { currentUser } = useTypedBaseStore<BaseStore>();
  const [initialValues, setInitialValues] = useState<OfferFormFields>();
  const { displayToast } = useToast();

  const openSuccessToast = () => {
    displayToast({
      type: ToastType.SUCCESS,
      message: t('offer_form_created_success_toast_message', {
        ns: Namespaces.SellerCreateOfferPage,
        defaultValue: 'The offer was saved successfully',
      }),
    });
  };

  const openErrorToast = () => {
    displayToast({
      type: ToastType.ERROR,
      message: t('offer_form_created_error_toast_message', {
        ns: Namespaces.SellerCreateOfferPage,
        defaultValue: 'A problem occurred while creating the offer.',
      }),
    });
  };

  const handleCreateOffer = (
    offerData: Partial<OfferFormFields>,
    successCallback: () => void,
    errorCallback?: (error: any) => void,
  ) => {
    createOffer.mutate(offerData, {
      onSuccess: data => {
        logger.debug('Offer created successfully:', data);
        successCallback();
        openSuccessToast();
        setCurrentBidderUid(null);
        setTimeout(() => {
          clearCreateOfferState();
          setIsOpenCreateOfferModal(false);
        }, 800);
      },
      onError: error => {
        logger.error('Error creating offer:', error);
        openErrorToast();
        if (errorCallback) {
          errorCallback(error);
        }
      },
    });
  };

  const handleEditOffer = (
    offerData: Partial<OfferFormFields>,
    successCallback: () => void,
    errorCallback?: (error: any) => void,
  ) => {
    editOffer.mutate(offerData, {
      onSuccess: data => {
        logger.debug('Offer edited successfully:', data);
        successCallback();
        openSuccessToast();
        setTimeout(() => {
          clearCreateOfferState();
          setIsOpenCreateOfferModal(false);
        }, 800);
      },
      onError: error => {
        logger.error('Error editing offer:', error);
        openErrorToast();
        if (errorCallback) {
          errorCallback(error);
        }
      },
    });
  };

  const handleFormSubmit = (
    values: Partial<OfferFormFields>,
    successCallback: () => void,
    errorCallback?: (error: any) => void,
  ) => {
    const offerPayload = OfferMapper.toOffer(values);
    if (mode === OfferFormMode.CREATE) {
      handleCreateOffer(offerPayload, successCallback, errorCallback);
    } else {
      handleEditOffer(offerPayload, successCallback, errorCallback);
    }
  };

  useEffect(() => {
    if (mode === OfferFormMode.EDIT && offer) {
      setInitialValues(OfferMapper.toFormFields(offer));
    }

    if (mode === OfferFormMode.CREATE && tenderAttachments) {
      setInitialValues(
        OfferMapper.initialValues({
          contact: tenderDetails?.contact_person as any,
          tenderAttachments,
          currentUser,
        }),
      );
    }

    setTenderAttachments(tenderAttachments || []);
  }, [offer, mode, tenderDetails, tenderAttachments, currentUser, setTenderAttachments]);

  if (!initialValues || isLoadingTenderAttachments) {
    return <OfferFormSkeleton />;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={offerValidationSchema}
      validateOnBlur={true}
      validateOnChange={true}
      onSubmit={(values, { setSubmitting }: FormikHelpers<OfferFormFields>) => {
        handleFormSubmit(
          values,
          () => {
            setSubmitting(false);
          },
          error => {
            logger.error('Resetting form submission state because of error:', error);
            setSubmitting(false);
          },
        );
      }}
    >
      {children}
    </Formik>
  );
};

export const OfferFormFormik = withStaticProps(OfferFormFormikComponent, {
  modes: OfferFormMode,
});

export default OfferFormFormik;
