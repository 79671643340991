import { StateCreator } from 'zustand';
import { ContractorOfferAction } from '../pages/tender/tabs/contractors/components/contractor-card/components/accept-reject-contractors-modal/components/accept-reject-contractors-stepper/accept-reject-contractors-stepper.constants';

export interface AcceptRejectContractorsState {
  acceptRejectContractorsCurrentStep: number;
  contractorOfferActions: ContractorOfferAction[];
  markedToAcceptOffers: string[];
  markedToRejectOffers: string[];
  setAcceptRejectContractorsCurrentStep: (step: number) => void;
  setContractorOfferActions: (actions: ContractorOfferAction[]) => void;
  setMarkedToAcceptOffers: (offers: string[]) => void;
  setMarkedToRejectOffers: (offers: string[]) => void;
  clearAcceptRejectContractorsState: () => void;
}

export const createAcceptRejectContractorSlice: StateCreator<AcceptRejectContractorsState> = set => ({
  acceptRejectContractorsCurrentStep: 0,
  contractorOfferActions: [],
  markedToAcceptOffers: [],
  markedToRejectOffers: [],
  setAcceptRejectContractorsCurrentStep: step => set({ acceptRejectContractorsCurrentStep: step }),
  setContractorOfferActions: actions => set({ contractorOfferActions: actions }),
  setMarkedToAcceptOffers: offers => set({ markedToAcceptOffers: offers }),
  setMarkedToRejectOffers: offers => set({ markedToRejectOffers: offers }),
  clearAcceptRejectContractorsState: () =>
    set({
      acceptRejectContractorsCurrentStep: 0,
      contractorOfferActions: [],
      markedToAcceptOffers: [],
      markedToRejectOffers: [],
    }),
});
