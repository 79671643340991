import React, { forwardRef, useMemo, useRef } from 'react';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import { InputAdornment as MUIInputAdornment, InputAdornmentProps as MUIInputAdornmentProps } from '@mui/material';
import cx from 'classnames';

export type InputAdornmentProps = CoreComponentProps & Omit<MUIInputAdornmentProps, 'ref' | 'component'>;

export const InputAdornment: CoreComponent<InputAdornmentProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  InputAdornmentProps
>(({ id, 'data-testid': dataTestId, className, children, position = 'end', ...otherProps }, ref) => {
  const internalRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx(className);

  const inputAdornmentProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      ref: mergedRef,
      position,
      ...otherProps,
    }),
    [id, dataTestId, classNames, mergedRef, position, otherProps],
  );

  return <MUIInputAdornment {...inputAdornmentProps}>{children}</MUIInputAdornment>;
});

InputAdornment.displayName = 'InputAdornment';

export default InputAdornment;
