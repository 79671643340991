import { Heading, Skeleton, useHorizontalScrolling } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import { Namespaces } from '@react-fe/expertunity-base';
import cx from 'classnames';
import { forwardRef, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyDirectoryDetailsContactCard } from './components/company-details-contact-card';
import { ClientUser } from '@react-fe/expertunity-base/models';

export type CompanyDetailsContactsProps = CoreComponentProps & {
  clientUsers?: ClientUser[] | null;
  isPending?: boolean;
};

export const CompanyDetailsContacts: CoreComponent<CompanyDetailsContactsProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  CompanyDetailsContactsProps
>(({ id, 'data-testid': dataTestId, className, clientUsers, isPending }, ref) => {
  const { t } = useTranslation();

  const internalRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx(className, 'flex flex-col gap-spacing-2');
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const { checkScroll, ScrollButtons } = useHorizontalScrolling({
    scrollRef: scrollContainerRef,
    disabled: isPending,
  });

  const companyDirectoryContactsProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      ref: mergedRef,
    }),
    [id, dataTestId, classNames, mergedRef],
  );

  return (
    <div {...companyDirectoryContactsProps}>
      <Heading variant={Heading.variants.H6}>
        {t('company_contacts_title', {
          ns: Namespaces.Settings,
          defaultValue: 'Contacts ({{ count }})',
          count: clientUsers?.length,
        })}
      </Heading>
      <div className="relative w-full">
        <div
          ref={scrollContainerRef}
          className="flex flex-row gap-spacing-2 overflow-x-auto scroll-smooth !no-scrollbar w-full"
          onScroll={checkScroll}
        >
          {clientUsers
            ? clientUsers.map(user => (
                <CompanyDirectoryDetailsContactCard user={user} key={`company-directory-contact-${user.uid}`} />
              ))
            : Array.from({ length: 4 }).map((_, index) => (
                <Skeleton key={index} height={180} variant="rectangular" className="w-full" />
              ))}
        </div>
        <ScrollButtons />
      </div>
    </div>
  );
});

CompanyDetailsContacts.displayName = 'CompanyDetailsContacts';

export default CompanyDetailsContacts;
