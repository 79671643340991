import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import { Attachment } from '@react-fe/expertunity-base/models';
import cx from 'classnames';
import { forwardRef, useMemo, useRef } from 'react';
import { useAttachmentsCategoryList } from '../../hooks/use-attachments-category-list';
import { CategorizedAttachmentFields } from './components/categorized-attachment-fields';
import { CategorizedAttachmentName } from './components/categorized-attachment-name';

export interface CategorizedAttachmentFormProps extends CoreComponentProps {
  index: number;
  name: string;
  attachment: Attachment;
  form: { values: any };
  showOnlyDescription?: boolean;
  remove: (index: number) => void;
}

export const CategorizedAttachmentForm: CoreComponent<CategorizedAttachmentFormProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  CategorizedAttachmentFormProps
>(({ id, 'data-testid': dataTestId, className, index, name, attachment, form, remove, showOnlyDescription }, ref) => {
  const categories = useAttachmentsCategoryList();
  const internalRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx(className, 'flex gap-spacing-2');

  const categorizedAttachmentFormProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      ref: mergedRef,
    }),
    [id, dataTestId, classNames, mergedRef],
  );

  return (
    <div {...categorizedAttachmentFormProps}>
      <CategorizedAttachmentName name={name} index={index} form={form} attachment={attachment} />
      <CategorizedAttachmentFields
        name={name}
        index={index}
        categories={categories}
        form={form}
        remove={remove}
        showOnlyDescription={showOnlyDescription}
      />
    </div>
  );
});

CategorizedAttachmentForm.displayName = 'CategorizedAttachmentForm';

export default CategorizedAttachmentForm;
