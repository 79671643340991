import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { logger } from '@react-fe/core';
import { useParams } from 'react-router-dom';
import { CreateOffer, OfferState } from '@react-fe/expertunity-base/models';
import { API_URL } from '@react-fe/expertunity-base/constants';
import useBaseStore from '@react-fe/expertunity-base/stores';

export const useEditOffer = (offerUid: string, state: OfferState) => {
  const { tenderUid } = useParams();
  const queryClient = useQueryClient();
  const { currentUser } = useBaseStore();

  if (!tenderUid) {
    logger.error(
      'useEditOffer - useParams could not find tenderUid, check the route or the context that the hook is being called',
    );
  }

  return useMutation({
    mutationFn: async (offerData: Partial<CreateOffer>) => {
      const urlBase = `${API_URL}/tenders/${tenderUid}/bidders/${currentUser.client_uid}/offers/${offerUid}`;
      const url = state === OfferState.DRAFT ? urlBase : `${urlBase}/version`;

      const response = await axios({
        method: state === OfferState.DRAFT ? 'put' : 'post',
        url,
        data: offerData,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      return response.data;
    },
    onSuccess: data => {
      logger.debug('Offer edited successfully:', data);

      queryClient
        .invalidateQueries({ queryKey: ['my-offers'] })
        .then(() => logger.debug("invalidateQueries run with params { queryKey: ['my-offers'] }."));
    },
    onError: error => {
      logger.error('Error editing offer:', error);
    },
  });
};

export default useEditOffer;
