import { DropdownItem } from '@react-fe/common-ui';
import { CategorizedAttachmentState, useTypedBaseStore } from '@react-fe/expertunity-base/stores';
import { useTranslation } from 'react-i18next';
import { Namespaces } from '../../../constants/namespaces';

export const useAttachmentsCategoryList = (): DropdownItem[] => {
  const { t } = useTranslation(Namespaces.CategorizedAttachment);
  const { canSelectApplicationInformationCategory } = useTypedBaseStore<CategorizedAttachmentState>();

  return [
    { label: t('category_work_description', { defaultValue: 'Work description' }), value: 'work_description' },
    { label: t('category_general_terms', { defaultValue: 'General Terms' }), value: 'general_terms' },
    { label: t('category_plans', { defaultValue: 'Plans' }), value: 'plans' },
    { label: t('category_attachments', { defaultValue: 'Attachments' }), value: 'attachment' },
    ...(canSelectApplicationInformationCategory
      ? [
          {
            label: t('category_application_information', { defaultValue: 'Application information' }),
            value: 'candidate_information',
          },
        ]
      : []),
  ];
};
