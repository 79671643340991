import React, { forwardRef, useMemo } from 'react';
import { CoreComponent } from '@react-fe/core';
import { ComponentDefaultTestId, getTestId } from '@react-fe/common-ui';
import { FormControlRootProps } from './form-control-root';
import { Checkbox, CheckboxProps } from '../checkbox';
import { FormError } from '../form-error';
import { useField } from 'formik';

type FormControlMergedProps = FormControlRootProps & CheckboxProps & { label?: string };

export const FormControlCheckbox: CoreComponent<FormControlMergedProps, HTMLInputElement> = forwardRef<
  HTMLInputElement,
  FormControlMergedProps
>(({ id, 'data-testid': dataTestId, className, name = '', label, ...otherProps }, ref) => {
  const [field, , helpers] = useField(name);
  const { setValue } = helpers;
  const toggleCheckboxValue = () => {
    setValue(!field.value).catch();
  };

  const formControlMergedProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId || getTestId(ComponentDefaultTestId.FORM_CONTROL_CHECKBOX, id),
      className,
      label,
      ...otherProps,
    }),
    [id, dataTestId, className, label, otherProps],
  );

  return (
    <div className="flex flex-col">
      <div className={'flex flex-row gap-spacing-1 items-center leading-none w-fit'}>
        <Checkbox {...field} {...formControlMergedProps} />
        <span className="cursor-pointer" onClick={toggleCheckboxValue}>
          {label}
        </span>
      </div>

      <FormError field={field} />
    </div>
  );
});

FormControlCheckbox.displayName = 'FormControl.Checkbox';

export default FormControlCheckbox;
