export enum TooltipWidthType {
  DEFAULT = 'DEFAULT',
  CUSTOM_WIDTH = 'CUSTOM_WIDTH',
  NO_WRAP = 'NO_WRAP',
}

export enum TooltipPlacement {
  TOP = 'top',
  TOP_START = 'top-start',
  TOP_END = 'top-end',
  RIGHT = 'right',
  RIGHT_START = 'right-start',
  RIGHT_END = 'right-end',
  LEFT = 'left',
  LEFT_START = 'left-start',
  LEFT_END = 'left-end',
  BOTTOM = 'bottom',
  BOTTOM_START = 'bottom-start',
  BOTTOM_END = 'bottom-end',
}

export enum TooltipAnchorDistance {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  EXTRA_LARGE = 'EXTRA_LARGE',
}

export const ANCHOR_DISTANCE_SIZES = {
  SMALL: '-4',
  MEDIUM: '0',
  LARGE: '4',
  EXTRA_LARGE: '8',
};

export enum CustomTooltipWidth {
  MAX_W_XS = '!max-w-xs',
  MAX_W_SM = '!max-w-sm',
  MAX_W_MD = '!max-w-md',
  MAX_W_LG = '!max-w-lg',
  MAX_W_XL = '!max-w-xl',
  MAX_W_2XL = '!max-w-2xl',
  MAX_W_3XL = '!max-w-3xl',
  MAX_W_4XL = '!max-w-4xl',
  MAX_W_5XL = '!max-w-5xl',
  MAX_W_6XL = '!max-w-6xl',
  MAX_W_FULL = '!max-w-full',
  MAX_W_MIN = '!max-w-min',
  MAX_W_MAX = '!max-w-max',
  MAX_W_PROSE = '!max-w-prose',
  MAX_W_SCREEN_SM = '!max-w-screen-sm',
  MAX_W_SCREEN_MD = '!max-w-screen-md',
  MAX_W_SCREEN_LG = '!max-w-screen-lg',
  MAX_W_SCREEN_XL = '!max-w-screen-xl',
  MAX_W_SCREEN_2XL = '!max-w-screen-2xl',
}
