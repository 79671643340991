import { CoreComponent, logger, useMergedRef, withStaticProps } from '@react-fe/core';
import cx from 'classnames';
import React, { forwardRef, useMemo, useRef } from 'react';
import { Typography, TypographyProps } from '../typography';
import { TypographyStaticProps, TypographyStaticValues } from '../typography/typography.constants';
import { TextComponentEnum, TextVariant } from './text.constant';

const isTextNode = (node: React.ReactNode) => typeof node === 'string' || typeof node === 'number';

const validateChildren = (children: React.ReactNode) => {
  React.Children.forEach(children, (child: any) => {
    if (!isTextNode(child)) {
      if (child?._source) {
        logger.error(`Error: Non-text element found in TextComponent: ${JSON.stringify(child._source)}`);
      } else {
        logger.error(`Error: Non-text element found in TextComponent: ${child}`);
      }
    }
  });
};

export type TextProps = TypographyProps<TextVariant>;

const TextComponent: CoreComponent<TextProps> &
  TypographyStaticProps & {
    components?: typeof TextComponentEnum;
    variants?: typeof TextVariant;
  } = forwardRef<HTMLElement, TextProps>(
  (
    { component = Text.components.PARAGRAPH, variant = Text.variants.BODY1, children, className, ...otherProps },
    ref,
  ) => {
    validateChildren(children);

    const internalRef = useRef<HTMLDivElement>(null);
    const mergedRef = useMergedRef(ref, internalRef);

    const textProps = useMemo(
      () => ({
        variant,
        component,
        ref: mergedRef,
        className: cx(className),
        ...otherProps,
      }),
      [className, component, mergedRef, otherProps, variant],
    );

    return <Typography {...textProps}>{children}</Typography>;
  },
);

export const Text = withStaticProps(TextComponent, {
  components: TextComponentEnum,
  variants: TextVariant,
  ...TypographyStaticValues,
});

Text.displayName = 'Text';

export default Text;
