import React from 'react';
import { CoreComponent } from './core-component';

type Required<T> = {
  [P in keyof T]-?: T[P];
};

export const withStaticProps = <T, P, S>(
  forwarded: (CoreComponent<T, P> & Partial<S>) | (React.FC<T> & Partial<S>),
  staticProps: Required<S>,
) => Object.assign(forwarded, staticProps);
