import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import {
  DatePicker as MuiDatepicker,
  DatePickerProps as MUIDatepickerProps,
  DateTimePicker as MuiDateTimePicker,
  DateTimePickerProps as MUIDateTimePickerProps,
} from '@mui/x-date-pickers';
import { Button, ComponentDefaultTestId, getTestId } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps } from '@react-fe/core';
import { forwardRef, useMemo, useState, useRef } from 'react';

type InnerMUIDateTimePickerProps = MUIDateTimePickerProps<Date, false>;
type InnerMUIDatePickerProps = MUIDatepickerProps<Date>;

export interface DatepickerProps extends CoreComponentProps {
  name?: string;
  label?: string;
  helperText?: string;
  required?: boolean;
  dateTime?: boolean;
  value?: Date;
  onChange?: any;
  error?: boolean;
  disabled?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  maxDate?: Date;
  minDate?: Date;
  fieldSlotProps?: any;
  onBlur?: (e?: any) => void;
}

export const Datepicker: CoreComponent<DatepickerProps, HTMLElement> = forwardRef<HTMLElement, DatepickerProps>(
  (
    {
      id,
      'data-testid': dataTestId,
      className,
      name,
      label,
      helperText,
      required,
      dateTime,
      value,
      onChange,
      error,
      disabled,
      fieldSlotProps,
      ...otherProps
    },
    ref,
  ) => {
    const [open, setOpen] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleDateTimeClose = () => {
      setOpen(false);

      // This fix the issue caused by the set of 23:59 time, that causes the focus to be trapped in the input
      if (dateTime) {
        setTimeout(() => {
          inputRef.current?.blur();
          const nextElement = inputRef.current?.parentElement?.nextElementSibling;
          (nextElement as HTMLElement)?.focus();
        }, 250);
      }
    };

    const datepickerProps = useMemo(() => {
      const baseProps = {
        id,
        'data-testid': dataTestId || getTestId(ComponentDefaultTestId.DATEPICKER, id),
        className,
        label,
        value,
        error,
        open,
        disabled,
        onClose: () => setOpen(false),
        slotProps: {
          inputAdornment: {
            sx: {
              display: 'none',
            },
          },
          textField: {
            name,
            error,
            required,
            helperText,
            disabled,
            onBlur: otherProps?.onBlur,
            InputProps: {
              startAdornment: (
                <Button.Root
                  type={Button.Root.types.GHOST}
                  className="!pl-0 text-grey-600"
                  onClick={() => setOpen(true)}
                >
                  <Button.Icon icon={faCalendar} className="w-[1rem] h-[1rem]" />
                </Button.Root>
              ),
            },
            inputRef: inputRef,
            ...fieldSlotProps,
          },
        },
        ...otherProps,
      };

      return dateTime ? (baseProps as InnerMUIDateTimePickerProps) : (baseProps as InnerMUIDatePickerProps);
    }, [
      id,
      dataTestId,
      className,
      label,
      value,
      error,
      open,
      disabled,
      name,
      required,
      helperText,
      fieldSlotProps,
      otherProps,
      dateTime,
    ]);

    if (dateTime) {
      return (
        <MuiDateTimePicker
          {...(datepickerProps as InnerMUIDateTimePickerProps)}
          onClose={handleDateTimeClose}
          onChange={onChange}
          disableOpenPicker={true}
        />
      );
    }

    return <MuiDatepicker {...(datepickerProps as InnerMUIDatePickerProps)} onChange={onChange} />;
  },
);

Datepicker.displayName = 'Datepicker';

export default Datepicker;
