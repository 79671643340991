export enum TextComponentEnum {
  PARAGRAPH = 'p',
  SPAN = 'span',
  DIV = 'div',
}

export enum TextVariant {
  BODY1 = 'body1',
  BODY1_BOLD = 'body1Bold',
  BODY2 = 'body2',
  BODY2_BOLD = 'body2Bold',
  OVERLINE = 'overline',
  CAPTION = 'caption',
  SUBTITLE1 = 'subtitle1',
  SUBTITLE2 = 'subtitle2',
  BUTTON = 'button',
  INHERIT = 'inherit',
}
