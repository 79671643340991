export enum ButtonSize {
  XSMALL = 'xsmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  OUTLINED = 'outlined',
  WARNING = 'warning',
  GHOST = 'ghost',
}

export enum ButtonInputType {
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset',
}
