import { CommonUIThemeProvider } from '@react-fe/common-ui';
import { BreadcrumbProvider, LocaleProvider } from '@react-fe/core';
import { YupLocaleProvider } from '@react-fe/expertunity-base';
import '@react-fe/expertunity-base/i18n-configuration';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import ConfigProvider from './config-provider';
import './interceptors/oauth-interceptor';
import './interceptors/response-interceptor';
import router from './routers/router';
import { ToastProvider } from './components/toast-provider';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <LocaleProvider>
      <CommonUIThemeProvider>
        <BreadcrumbProvider>
          <QueryClientProvider client={queryClient}>
            <YupLocaleProvider>
              <ToastProvider>
                <ConfigProvider>
                  <RouterProvider router={router} />
                </ConfigProvider>
              </ToastProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </YupLocaleProvider>
          </QueryClientProvider>
        </BreadcrumbProvider>
      </CommonUIThemeProvider>
    </LocaleProvider>
  </StrictMode>,
);
