import { Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material';
import { CoreComponent, CoreComponentProps, withStaticProps } from '@react-fe/core';
import cx from 'classnames';
import { forwardRef, useMemo } from 'react';
import { ChipColor } from './chip.constants';

export interface ChipProps extends CoreComponentProps, Omit<MuiChipProps, 'ref' | 'color'> {
  color?: ChipColor | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'white';
}

const ChipComponent: CoreComponent<ChipProps, HTMLDivElement> & {
  colors?: typeof ChipColor;
} = forwardRef<HTMLDivElement, ChipProps>(
  (
    {
      id,
      'data-testid': dataTestId,
      className,
      label,
      color = ChipColor.DEFAULT,
      deleteIcon,
      variant,
      ...muiChipProps
    },
    ref,
  ) => {
    const internalColor = useMemo(() => {
      if (color === 'white') {
        return ChipColor.DEFAULT;
      }
      return color;
    }, [color]);

    const chipProps: MuiChipProps = useMemo(() => {
      return {
        id,
        'data-testid': dataTestId,
        className: cx(className, { 'bg-white': internalColor === ChipColor.DEFAULT }),
        ref,
        label,
        color: internalColor,
        deleteIcon,
        variant,
        ...muiChipProps,
      };
    }, [id, dataTestId, className, ref, label, internalColor, deleteIcon, variant, muiChipProps]);

    return <MuiChip {...chipProps} />;
  },
);

export const Chip = withStaticProps(ChipComponent, {
  colors: ChipColor,
});

Chip.displayName = 'Chip';

export default Chip;
