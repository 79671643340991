import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { logger } from '@react-fe/core';
import { Offer, QueryKeys } from '@react-fe/expertunity-base/models';
import { API_URL } from '@react-fe/expertunity-base/constants';

export const useAcceptRejectOffer = (mode: string, offer: Offer | null) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (message: string) => {
      const response = await axios.post(
        `${API_URL}/tenders/${offer?.tender_uid}/bidders/${offer?.bidder_uid}/${mode}-offer/${offer?.uid}`,
        { message },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: [QueryKeys.TENDER_OFFERS, offer?.tender_uid] })
        .then(() => logger.debug("invalidateQueries run with params { queryKey: ['tender_offers'] }."));

      queryClient
        .invalidateQueries({ queryKey: ['bidders', offer?.tender_uid] })
        .then(() => logger.debug("invalidateQueries run with params { queryKey: ['bidders'] }."));
    },
    onError: error => {
      logger.error('Error accepting/rejecting offer', error);
    },
  });
};

export default useAcceptRejectOffer;
