import classNames from 'classnames';
import { MenuLink } from './nav.contants';
import { NavMenuItem } from './nav-menu-item';

interface NavMenuProps {
  isOpen: boolean;
  menuItems: MenuLink[];
}

export function NavMenu({ isOpen, menuItems }: NavMenuProps) {
  return (
    <nav className="flex flex-1 flex-col justify-center items-center">
      <ul
        className={classNames('flex flex-row md:flex-col justify-center items-start p-spacing-2', {
          'rounded-full bg-secondary gap-spacing-2 md:gap-spacing-1 transition-all ease-linear': !isOpen,
          'gap-spacing-1': isOpen,
        })}
      >
        {menuItems.map(menu => (
          <NavMenuItem key={menu.id} item={menu} isOpen={isOpen} />
        ))}
      </ul>
    </nav>
  );
}
