import { faBuildings, faFileInvoice, faHome, faLayerGroup, IconDefinition } from '@fortawesome/pro-regular-svg-icons';

export enum CustomerType {
  ISSUER = 'issuer',
  BIDDER = 'bidder',
}

export const ISSUER_MENU_LINKS: MenuLink[] = [
  {
    id: 1,
    title: 'home',
    icon: faHome,
    link: '/',
  },
  {
    id: 2,
    title: 'projects',
    icon: faLayerGroup,
    link: '/projects',
  },
];

export const BIDDER_MENU_LINKS: MenuLink[] = [
  {
    id: 3,
    title: 'home',
    icon: faHome,
    link: '/',
  },
  {
    id: 4,
    title: 'tenders',
    icon: faFileInvoice,
    link: '/tenders',
  },
  {
    id: 6,
    title: 'company-directory',
    icon: faBuildings,
    link: '/company-directory',
  },
];

export const renderMenuItems = (roles: CustomerType[]): MenuLink[] => {
  let combinedLinks: MenuLink[] = [];

  if (roles.includes(CustomerType.ISSUER)) {
    combinedLinks = [...combinedLinks, ...ISSUER_MENU_LINKS];
  }

  if (roles.includes(CustomerType.BIDDER)) {
    combinedLinks = [...combinedLinks, ...BIDDER_MENU_LINKS];
  }

  return combinedLinks.filter((link, index, self) => index === self.findIndex(t => t.title === link.title));
};

export interface MenuLink {
  id: number;
  title: string;
  link: string;
  icon: IconDefinition;
}
