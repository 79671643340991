import React, { forwardRef, useMemo, useRef } from 'react';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import { Switch as MuiSwitch, SwitchProps as MuiSwitchProps } from '@mui/material';
import { ComponentDefaultTestId, getTestId } from '@react-fe/common-ui';

export interface SwitchProps extends CoreComponentProps {
  value?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export const Switch: CoreComponent<SwitchProps, HTMLButtonElement> = forwardRef<HTMLButtonElement, SwitchProps>(
  ({ id, 'data-testid': dataTestId, className, value = false, disabled = false, onChange }, ref) => {
    const internalRef = useRef<HTMLButtonElement>(null);
    const mergedRef = useMergedRef(ref, internalRef);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!disabled && onChange) {
        onChange(event);
      }
    };

    const switchProps: MuiSwitchProps = useMemo(() => {
      return {
        id,
        'data-testid': dataTestId || getTestId(ComponentDefaultTestId.SWITCH, id),
        ref: mergedRef,
        disableRipple: true,
        inputProps: { 'aria-label': 'controlled' },
      };
    }, [id, dataTestId, mergedRef]);

    return <MuiSwitch onChange={handleChange} checked={value} disabled={disabled} {...switchProps} />;
  },
);

Switch.displayName = 'Switch';

export default Switch;
