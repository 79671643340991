import { Heading } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import { CategorizedAttachmentsArea } from '@react-fe/expertunity-base';
import { AttachmentCategory } from '@react-fe/expertunity-base/models';
import useBaseStore from '@react-fe/expertunity-base/stores';
import cx from 'classnames';
import { forwardRef, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CREATE_OFFER_FORM_ATTACHMENTS_UPLOAD_ENDPOINT } from '../../create-offer-modal.constants';
import { OfferMapper } from '../../utils/create-offer-mapper';
import { AttachmentsList } from './components/attachments-list';

export type CreateOfferDocumentProps = CoreComponentProps;

export const CreateOfferDocument: CoreComponent<CreateOfferDocumentProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  CreateOfferDocumentProps
>(({ id, 'data-testid': dataTestId, className }, ref) => {
  const { t } = useTranslation();
  const { tenderAttachments } = useBaseStore();

  const internalRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx(className, 'flex gap-spacing-3 w-full max-h-[500px] overflow-y-auto');

  const workDescriptionTemplates = tenderAttachments?.filter(
    attachment => attachment.category === AttachmentCategory.WORK_DESCRIPTION,
  );

  const otherAttachments = tenderAttachments?.filter(
    attachment => attachment.category !== AttachmentCategory.WORK_DESCRIPTION,
  );

  const createOfferDocumentProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      ref: mergedRef,
    }),
    [id, dataTestId, classNames, mergedRef],
  );

  return (
    <div {...createOfferDocumentProps}>
      {tenderAttachments?.length ? (
        <div className="flex flex-col gap-spacing-2 max-w-[45%] overflow-y-auto">
          <div className="flex flex-col gap-spacing-2 pb-[1px] pr-spacing-1">
            <AttachmentsList workDescriptionTemplates={workDescriptionTemplates} otherAttachments={otherAttachments} />
          </div>
        </div>
      ) : null}

      <div className="flex flex-col gap-spacing-2 grow overflow-y-auto">
        <Heading variant={Heading.variants.H5}>{t('attachments')}</Heading>
        <CategorizedAttachmentsArea
          className="h-full"
          name="attachments"
          allowedFileExtensions={['PDF', 'XLS', 'SVG', 'PNG', 'JPG']}
          uploadEndpoint={CREATE_OFFER_FORM_ATTACHMENTS_UPLOAD_ENDPOINT}
          transformAttachment={OfferMapper.rawUploadToAttachment}
        />
      </div>
    </div>
  );
});

CreateOfferDocument.displayName = 'CreateOfferDocument';

export default CreateOfferDocument;
