import axios from 'axios';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { API_URL } from '@react-fe/expertunity-base/constants';
import { TenderSearchTypeOfWork } from '@react-fe/expertunity-base/models';

export function useTypesOfWork(): UseQueryResult<TenderSearchTypeOfWork[]> {
  return useQuery<TenderSearchTypeOfWork[]>({
    queryKey: ['tender-search-types-of-work'],
    queryFn: async (): Promise<TenderSearchTypeOfWork[]> => {
      const response = await axios.get<TenderSearchTypeOfWork[]>(`${API_URL}/tenders/search-metadata/types-of-work`);
      return response.data;
    },
  });
}

export default useTypesOfWork;
