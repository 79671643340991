import { useModal } from '@react-fe/expertunity-base/hooks';
import { useCallback, useEffect, useState } from 'react';
import { InternalCommentModal, InternalCommentModalMode } from '@react-fe/expertunity-base';
import { ModalKeys, Offer, QueryKeys } from '@react-fe/expertunity-base/models';
import useOfferInternalComments from './use-offer-internal-comments';
import { Modal } from '@react-fe/common-ui';
import { useTranslation } from 'react-i18next';

export function useInternalCommentModal(offer: Offer) {
  const { t } = useTranslation();
  const { data: comments } = useOfferInternalComments(offer.tender_uid, offer.uid);
  const { openModal, closeModal, updateModalContent } = useModal();
  const [mode, setMode] = useState<InternalCommentModalMode>(
    comments?.length ? InternalCommentModalMode.VIEW : InternalCommentModalMode.ADD,
  );
  const mutationConfig = {
    url: `/tenders/${offer.tender_uid}/offers/${offer.uid}/comments`,
    invalidateQueries: [
      [QueryKeys.MY_OFFERS, offer.tender_uid, offer.uid],
      [QueryKeys.OFFER_COMMENTS, offer.tender_uid, offer.uid],
    ],
  };

  useEffect(() => {
    setMode(comments?.length ? InternalCommentModalMode.VIEW : InternalCommentModalMode.ADD);
  }, [comments]);

  const openInternalCommentModal = useCallback(
    (commentsMode = mode) => {
      setMode(commentsMode);
      openModal({
        modalId: ModalKeys.INTERNAL_COMMENT_MODAL,
        content: (
          <InternalCommentModal
            listTitle={t('offer_comments', { defaultValue: 'Offer Comments' })}
            comments={comments}
            initialModalMode={commentsMode}
            onClose={() => closeModal(ModalKeys.INTERNAL_COMMENT_MODAL)}
            mutationsConfig={mutationConfig}
          />
        ),
        showActionButtons: false,
        showHeader: false,
        shouldShowCloseButton: false,
        variant: Modal.variant?.CUSTOM,
        customWidth: 485,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [closeModal, comments, offer, mode, openModal],
  );

  useEffect(() => {
    updateModalContent(
      ModalKeys.INTERNAL_COMMENT_MODAL,
      <InternalCommentModal
        listTitle={t('offer_comments', { defaultValue: 'Offer Comments' })}
        comments={comments}
        initialModalMode={mode}
        onClose={() => closeModal(ModalKeys.INTERNAL_COMMENT_MODAL)}
        mutationsConfig={mutationConfig}
      />,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments, offer, mode]);

  return { openInternalCommentModal, commentCount: comments?.length };
}
