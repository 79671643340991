import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { Button, DropdownItem, FormControl, IconSize, Modal } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import { Namespaces } from '@react-fe/expertunity-base/constants';
import { AttachmentCategory } from '@react-fe/expertunity-base/models';
import cx from 'classnames';
import { useFormikContext } from 'formik';
import React, { forwardRef, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CategorizedAttachmentFormControlNames } from '../../../../categorized-attachments-area.constants';
import { CategorizedAttachmentMenu } from '../categorized-attachment-menu/categorized-attachment-menu';
import { EditCategoryModal } from '../edit-category-modal/edit-category-modal';
import { CategorizedAttachmentState, useTypedBaseStore } from '@react-fe/expertunity-base/stores';

export type CategorizedAttachmentFieldsProps = CoreComponentProps & {
  name: string;
  index: number;
  categories: DropdownItem[];
  form: { values: any };
  showOnlyDescription?: boolean;
  remove: (index: number) => void;
};

export const CategorizedAttachmentFields: CoreComponent<CategorizedAttachmentFieldsProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  CategorizedAttachmentFieldsProps
>(({ id, 'data-testid': dataTestId, className, name, index, categories, form, showOnlyDescription, remove }, ref) => {
  const { t } = useTranslation();
  const { setLastFocusedAttachmentIndex } = useTypedBaseStore<CategorizedAttachmentState>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isOpenEditCategoryModal, setIsOpenEditCategoryModal] = useState<boolean>(false);
  const { setFieldValue } = useFormikContext();
  const internalRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx(className, 'flex flex-row grow gap-[3.5rem] items-center justify-end');
  const open = Boolean(anchorEl);

  const openMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget as any);
    setLastFocusedAttachmentIndex(index);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleRemove = () => {
    remove(index);
    closeMenu();
  };

  const handleEditCategoryInput = () => {
    closeMenu();
    setIsOpenEditCategoryModal(true);
  };

  const onEditCategoryClose = (result?: AttachmentCategory) => {
    setIsOpenEditCategoryModal(false);
    setLastFocusedAttachmentIndex(null);

    if (result) {
      setFieldValue(`${name}[${index}].${CategorizedAttachmentFormControlNames.CATEGORY}`, result);
    }
  };

  const categorizedAttachmentFieldsProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      ref: mergedRef,
    }),
    [id, dataTestId, classNames, mergedRef],
  );

  return (
    <React.Fragment>
      <div {...categorizedAttachmentFieldsProps}>
        {!showOnlyDescription && (
          <FormControl.Root
            name={`${name}[${index}].${CategorizedAttachmentFormControlNames.CATEGORY}`}
            className="w-[50%]"
          >
            <FormControl.Dropdown
              label={t('categorized_attachment_form_category_label', {
                ns: Namespaces.CategorizedAttachment,
                defaultValue: 'Category',
              })}
              options={categories}
              required={true}
            />
          </FormControl.Root>
        )}

        {showOnlyDescription && (
          <FormControl.Root
            className="w-full"
            name={`${name}[${index}].${CategorizedAttachmentFormControlNames.DESCRIPTION}`}
          >
            <FormControl.TextField
              label={t('categorized_attachment_form_description_label', {
                ns: Namespaces.CategorizedAttachment,
                defaultValue: 'Description',
              })}
              placeholder={t('categorized_attachment_form_description_placeholder', {
                ns: Namespaces.CategorizedAttachment,
                defaultValue: 'Enter a description...',
              })}
            />
          </FormControl.Root>
        )}

        <Button.Root className="!h-[4.5rem]" type={Button.Root.types.GHOST} onClick={openMenu}>
          <Button.Icon icon={faEllipsisVertical} size={IconSize.LARGE} />
        </Button.Root>
      </div>

      <CategorizedAttachmentMenu
        anchorEl={anchorEl}
        open={open}
        index={index}
        onClose={closeMenu}
        remove={handleRemove}
        handleEditCategoryInput={handleEditCategoryInput}
      />

      <Modal
        open={isOpenEditCategoryModal}
        handleClose={() => setIsOpenEditCategoryModal(false)}
        showActionButtons={false}
        showHeader={false}
        shouldShowCloseButton={false}
        variant={Modal.variant?.CUSTOM}
        customWidth={485}
      >
        <EditCategoryModal
          categories={categories}
          categoryPreset={form.values[name]?.[index]?.[CategorizedAttachmentFormControlNames.CATEGORY]}
          onClose={onEditCategoryClose}
        />
      </Modal>
    </React.Fragment>
  );
});

CategorizedAttachmentFields.displayName = 'CategorizedAttachmentFields';

export default CategorizedAttachmentFields;
