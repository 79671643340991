import { FormControl, FormControlFileUploadProps } from '@react-fe/common-ui';
import { CoreComponent, useMergedRef } from '@react-fe/core';
import cx from 'classnames';
import { FieldArray, useField } from 'formik';
import { forwardRef, useMemo, useRef } from 'react';
import { AttachmentsList } from './components/attachments-list';
import { UploadAttachmentsButton } from './components/upload-attachments-button';

export interface CategorizedAttachmentsAreaProps extends FormControlFileUploadProps<string> {
  name: string;
  transformAttachment: (uploadedContent: any) => any;
}

export const CategorizedAttachmentsArea: CoreComponent<CategorizedAttachmentsAreaProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  CategorizedAttachmentsAreaProps
>(
  (
    {
      id,
      'data-testid': dataTestId,
      className,
      name,
      label,
      uploadEndpoint = '',
      allowedFileExtensions,
      transformAttachment,
    },
    ref,
  ) => {
    const [field] = useField(name);
    const hasFields = field?.value?.length > 0;
    const internalRef = useRef<HTMLDivElement>(null);
    const mergedRef = useMergedRef(ref, internalRef);
    const classNames = cx(className, 'flex flex-col gap-spacing-3');

    const categorizedAttachmentsAreaProps = useMemo(
      () => ({
        id,
        'data-testid': dataTestId,
        className: classNames,
        ref: mergedRef,
      }),
      [id, dataTestId, classNames, mergedRef],
    );

    return (
      <div {...categorizedAttachmentsAreaProps}>
        {hasFields && (
          <UploadAttachmentsButton
            uploadEndpoint={uploadEndpoint}
            transformAttachment={transformAttachment}
            name={name}
          />
        )}

        {hasFields ? (
          <FieldArray name={name}>
            {({ remove, form }) => (
              <AttachmentsList
                attachments={field.value}
                form={form}
                fieldName={name}
                removeAttachmentByIndex={remove}
              />
            )}
          </FieldArray>
        ) : null}

        {!hasFields && (
          <FormControl.Root name={name} className="!h-full !w-full">
            <FormControl.FileUpload
              className="grow"
              uploadEndpoint={uploadEndpoint}
              label={label}
              maxFileSizeMb={3}
              allowedFileExtensions={allowedFileExtensions}
              transformAttachment={transformAttachment}
              hideFileDisplay={true}
            />
          </FormControl.Root>
        )}
      </div>
    );
  },
);

CategorizedAttachmentsArea.displayName = 'CategorizedAttachmentsArea';

export default CategorizedAttachmentsArea;
