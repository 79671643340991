import React, { forwardRef, HTMLAttributes, MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { CoreComponent, CoreComponentProps, ElementContent, useMergedRef, withStaticProps } from '@react-fe/core';
import { TypographyAlign, TypographyColor } from './typography.constants';
import { ComponentDefaultTestId, getTestId, useEllipsisClass } from '@react-fe/common-ui';
import { CustomWidthTooltip } from '../tooltip';
import { Typography as MUITypography, TypographyProps as MUITypographyProps } from '@mui/material';
import cx from 'classnames';

export interface TypographyProps<T> extends CoreComponentProps, HTMLAttributes<HTMLElement> {
  variant?: T;
  component?: T;
  color?: TypographyColor;
  align?: TypographyAlign;
  ellipsis?: boolean;
  maxLines?: number;
  withoutTooltip?: boolean;
  children?: ElementContent;
  onTruncatedText?: (isTruncated: boolean) => void;
}

const TypographyComponent: CoreComponent<TypographyProps<any>> & {
  aligns?: typeof TypographyAlign;
  colors?: typeof TypographyColor;
} = forwardRef<HTMLElement, TypographyProps<any>>(
  (
    {
      id,
      'data-testid': dataTestId,
      className,
      variant,
      component,
      align = Typography.aligns.INHERIT,
      color = Typography.colors.TEXT_PRIMARY,
      ellipsis = false,
      maxLines = 1,
      withoutTooltip = false,
      children,
      onTruncatedText,
      ...htmlAttributes
    },
    ref,
  ) => {
    const textRef = useRef(null);
    const mergedRef = useMergedRef(ref, textRef) as MutableRefObject<HTMLElement>;
    const { ref: overrideRef, class: ellipsisClass } = useEllipsisClass(mergedRef, ellipsis, maxLines);
    const classNames = cx(className, ellipsisClass);
    const [isTruncated, setIsTruncated] = useState(false);

    useEffect(() => {
      if (onTruncatedText) {
        onTruncatedText(isTruncated);
      }
    }, [isTruncated, onTruncatedText]);

    const typographyProps: MUITypographyProps = useMemo(() => {
      const props: Record<string, unknown> = {
        id,
        'data-testid': dataTestId || getTestId(ComponentDefaultTestId.TEXT, id),
        ref: overrideRef,
        variant,
        component,
        align,
        color,
        className: classNames,
        ...htmlAttributes,
      };
      return props;
    }, [id, dataTestId, overrideRef, variant, component, align, color, classNames, htmlAttributes]);

    useEffect(() => {
      const element = mergedRef.current;
      if (element) {
        const scroll = maxLines > 1 ? element.scrollHeight : element.scrollWidth;
        const client = maxLines > 1 ? element.clientHeight : element.clientWidth;
        setIsTruncated(scroll > client);
      }
    }, [children, mergedRef, maxLines]);

    const textElement = <MUITypography {...typographyProps}>{children}</MUITypography>;

    if (withoutTooltip || !isTruncated) return textElement;

    return (
      <CustomWidthTooltip title={String(children)} maxWidthClass={CustomWidthTooltip.customWidthValues?.MAX_W_2XL}>
        {textElement}
      </CustomWidthTooltip>
    );
  },
);

export const Typography = withStaticProps(TypographyComponent, {
  aligns: TypographyAlign,
  colors: TypographyColor,
});

Typography.displayName = 'Typography';

export default Typography;
