import { Step as MUIStep, StepProps as MUIStepProps } from '@mui/material';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import cx from 'classnames';
import { forwardRef, useMemo, useRef } from 'react';

export type StepProps = CoreComponentProps & Omit<MUIStepProps, 'ref'>;

export const Step: CoreComponent<StepProps, HTMLDivElement> = forwardRef<HTMLDivElement, StepProps>(
  ({ id, 'data-testid': dataTestId, className, children, ...otherProps }, ref) => {
    const internalRef = useRef<HTMLDivElement>(null);
    const mergedRef = useMergedRef(ref, internalRef);
    const classNames = cx(className);

    const stepProps = useMemo(
      () => ({
        id,
        'data-testid': dataTestId,
        className: classNames,
        ref: mergedRef,
        ...otherProps,
      }),
      [id, dataTestId, classNames, mergedRef, otherProps],
    );

    return <MUIStep {...stepProps}>{children}</MUIStep>;
  },
);

Step.displayName = 'Step';

export default Step;
