import { Attachment } from '@react-fe/expertunity-base/models';
import { useEffect, useReducer } from 'react';
import { Text } from '@react-fe/common-ui';
import { Namespaces } from '@react-fe/expertunity-base/constants';
import { useTranslation } from 'react-i18next';
import { attachmentReducer, INITIAL_STATE } from '@react-fe/expertunity-base/reducers';
import { AttachmentCard } from '@react-fe/expertunity-base/components';

interface TenderAttachmentsProps {
  attachments: Attachment[];
  iconClassname?: string;
}

export function TenderAttachments({ attachments, iconClassname }: Readonly<TenderAttachmentsProps>) {
  const { t } = useTranslation();
  const [attachmentsByCategory, dispatch] = useReducer(attachmentReducer, INITIAL_STATE);

  useEffect(() => {
    dispatch({ type: 'SET_ATTACHMENTS', payload: attachments });
  }, [attachments]);

  return (
    <div className="flex flex-col gap-spacing-3">
      {Object.entries(attachmentsByCategory).map(([key, value]) => {
        if (!value.attachments.length) {
          return null;
        }

        return (
          <div key={key} className="flex flex-col gap-spacing-3">
            <Text variant={Text.variants.BODY1_BOLD}>{t(value.label, { ns: Namespaces.Common })}</Text>
            <div className="flex flex-col gap-spacing-2">
              {value.attachments.map(attachment => (
                <AttachmentCard key={attachment.uid} attachment={attachment} iconClassname={iconClassname} />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default TenderAttachments;
