import useBaseStore from '@react-fe/expertunity-base/stores';
import { useMemo } from 'react';
import { array, date, mixed, number, object, Schema, string } from 'yup';
import { OfferFormFields } from '../create-offer-modal.constants';

export const useOfferValidationSchema = (): Schema<OfferFormFields> => {
  const { isCreateOfferOnBehalf } = useBaseStore();
  const today = useMemo(() => new Date(), []);

  const schema = useMemo(
    () =>
      object().shape({
        gross_excl_vat: number().required(),
        description: string(),
        date_time_received_field: isCreateOfferOnBehalf ? date().max(today).required() : date().nullable(),
        attachments: array()
          .of(
            object().shape({
              category: string().required(),
              name: string().required(),
              description: string(),
              file: object().shape({
                uid: string(),
                name: string(),
                length: number(),
                href: string(),
                createdAt: mixed().nullable() as any,
              }),
            }),
          )
          .required() as any,
      }),
    [isCreateOfferOnBehalf, today],
  );

  return schema;
};
