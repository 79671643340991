import { Autocomplete as MuiAutocomplete, AutocompleteProps as MuiAutocompleteProps, TextField } from '@mui/material';

export type AutocompleteProps<
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
> = Omit<MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> & {
  label?: string;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  required?: boolean;
  renderInput?: MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>['renderInput'];
};

export const Autocomplete = <
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>({
  label,
  placeholder,
  error,
  helperText,
  required,
  renderInput,
  ...props
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const defaultRenderInput: MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>['renderInput'] = params => (
    <TextField
      {...params}
      label={label}
      placeholder={placeholder}
      error={error}
      helperText={helperText}
      required={required}
    />
  );

  return <MuiAutocomplete {...props} renderInput={renderInput || defaultRenderInput} />;
};

export default Autocomplete;
