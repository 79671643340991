import { Tooltip, TooltipProps } from './tooltip';
import { forwardRef } from 'react';
import { CoreComponent, withStaticProps } from '@react-fe/core';
import { TooltipClasses as MuiTooltipClasses, tooltipClasses as muiTooltipClasses } from '@mui/material';
import { CustomTooltipWidth } from './tooltip.constant';
import cx from 'classnames';

export interface CustomWidthTooltipProps extends TooltipProps {
  maxWidthClass?: CustomTooltipWidth;
}

const CustomWidthTooltipComponent: CoreComponent<CustomWidthTooltipProps> & {
  customWidthValues?: typeof CustomTooltipWidth;
} = forwardRef<HTMLElement, CustomWidthTooltipProps>(({ ...props }, ref) => {
  const { children, maxWidthClass = CustomWidthTooltip.customWidthValues?.MAX_W_FULL, ...otherProps } = props;

  const modifiedClass = cx(muiTooltipClasses.tooltip, ` ${maxWidthClass} `);
  const classes: Partial<MuiTooltipClasses> = {
    tooltip: modifiedClass,
  };

  return (
    <Tooltip {...otherProps} classes={classes}>
      {children}
    </Tooltip>
  );
});

export const CustomWidthTooltip = withStaticProps(CustomWidthTooltipComponent, {
  customWidthValues: CustomTooltipWidth,
});

CustomWidthTooltip.displayName = 'CustomWidthTooltip';

export default CustomWidthTooltip;
