import { OfferTerm, Attachment, AttachmentCategory } from '@react-fe/expertunity-base/models';

export const getOfferTerms = (attachments: Attachment[]): OfferTerm[] => {
  if (attachments) {
    return attachments
      .filter(
        attachment =>
          attachment.category === AttachmentCategory.GENERAL_TERMS ||
          attachment.category === AttachmentCategory.SPECIAL_TERMS,
      )
      .map(
        data =>
          ({
            date_of_acceptance: null,
            terms_attachment: data,
            file_uid: data.file.uid,
          }) as OfferTerm,
      );
  }

  return [];
};

export const getUpdatedOfferTerms = (attachments: OfferTerm[]): OfferTerm[] => {
  if (attachments) {
    return attachments
      .filter(
        attachment =>
          attachment.terms_attachment.category === AttachmentCategory.GENERAL_TERMS ||
          attachment.terms_attachment.category === AttachmentCategory.SPECIAL_TERMS,
      )
      .map(data => {
        const offerTerm: OfferTerm = { ...data };

        if (!offerTerm.date_of_acceptance) {
          offerTerm.terms_attachment.new_since_offered = true;
        }

        return offerTerm;
      });
  }

  return [];
};

export const acceptTerms = (offerTerms: OfferTerm[]): OfferTerm[] => {
  return offerTerms && offerTerms.length > 0
    ? offerTerms.map(offerTerm => {
        const term: OfferTerm = { ...offerTerm };
        term.date_of_acceptance = new Date().toISOString();
        return offerTerm;
      })
    : [];
};
