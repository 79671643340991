import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { logger } from '@react-fe/core';
import { API_URL } from '@react-fe/expertunity-base/constants';
import { MutationConfig } from '@react-fe/expertunity-base/models';

export const useCreateInternalComment = ({ url, invalidateQueries }: MutationConfig) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: { comment: string }) => {
      const response = await axios.post(`${API_URL}${url}`, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    },
    onSuccess: data => {
      logger.debug('Comment created successfully:', data);

      invalidateQueries.forEach(queryKey => {
        queryClient
          .invalidateQueries({ queryKey })
          .then(() => logger.debug(`invalidateQueries run with params { queryKey: ${JSON.stringify(queryKey)} }.`));
      });
    },
    onError: error => {
      logger.error('Error creating comment:', error);
    },
  });
};

export default useCreateInternalComment;
