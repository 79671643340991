import React, { forwardRef, useMemo, useRef } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { CoreComponent, CoreComponentProps, useMergedRef, withStaticProps } from '@react-fe/core';
import { ToastType } from './toast.constants';

export interface ToastProps extends CoreComponentProps {
  open: boolean;
  type?: ToastType;
  message: string;
  closable?: boolean;
  vertical?: 'top' | 'bottom';
  horizontal?: 'left' | 'center' | 'right';
  handleClose: () => void;
  autoHideDuration?: number | null;
}

const ToastComponent: CoreComponent<ToastProps, HTMLDivElement> & {
  type?: typeof ToastType;
} = forwardRef<HTMLDivElement, ToastProps>(
  (
    {
      id,
      'data-testid': dataTestId,
      className,
      open,
      type = Toast.type?.SUCCESS,
      message,
      closable = false,
      vertical = 'top',
      horizontal = 'center',
      handleClose,
      autoHideDuration = 5000,
    },
    ref,
  ) => {
    const internalRef = useRef<HTMLDivElement>(null);
    const mergedRef = useMergedRef(ref, internalRef);

    const toastProps = useMemo(
      () => ({
        id,
        'data-testid': dataTestId,
        className,
        ref: mergedRef,
        open,
        type,
        message,
        vertical,
        horizontal,
        autoHideDuration,
      }),
      [id, dataTestId, className, mergedRef, open, type, message, vertical, horizontal, autoHideDuration],
    );

    return (
      <Snackbar
        {...toastProps}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiAlert-colorSuccess': {
            backgroundColor: '#4BB45A !important',
            color: '#fff',
          },
          '& .MuiAlert-colorInfo': {
            backgroundColor: '#0F0A30 !important',
            color: '#fff',
          },
          '& .MuiAlert-colorError': {
            backgroundColor: '#AF130E !important',
            color: '#fff',
          },
        }}
      >
        <Alert
          className="!p-spacing-2 !border-transparent"
          icon={false}
          severity={type}
          onClose={closable ? handleClose : undefined}
        >
          {message}
        </Alert>
      </Snackbar>
    );
  },
);

export const Toast = withStaticProps(ToastComponent, {
  type: ToastType,
});

Toast.displayName = 'Toast';

export default Toast;
