import { Button } from '@react-fe/common-ui';
import Logo from '../../assets/logo.svg';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';

interface NavHeaderProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  logo?: string;
}

export function NavHeader({ isOpen, setIsOpen, logo = Logo }: NavHeaderProps) {
  return (
    <div className="hidden md:flex flex-col gap-spacing-6 items-center justify-center">
      <img src={logo} alt="Expertunity" />
      <Button.Root onClick={() => setIsOpen(!isOpen)} type={Button.Root.types?.SECONDARY} className="!h-16 !w-16">
        {isOpen ? <Button.Icon icon={faArrowLeft} /> : <Button.Icon icon={faArrowRight} />}
      </Button.Root>
    </div>
  );
}
