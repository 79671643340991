import { Autocomplete, FormControl, FormError, TextField } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import { Namespaces } from '@react-fe/expertunity-base/constants';
import { useGeonames } from '@react-fe/expertunity-base/hooks';
import { Geoname } from '@react-fe/expertunity-base/models';
import cx from 'classnames';
import { useFormikContext } from 'formik';
import { forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressFormControlNames } from '../../address-form.constants';

export type ZipInputProps = CoreComponentProps & { required: boolean };

export const ZipInput: CoreComponent<ZipInputProps, HTMLInputElement> = forwardRef<HTMLInputElement, ZipInputProps>(
  ({ id, 'data-testid': dataTestId, className, required }, ref) => {
    const { t } = useTranslation();
    const internalRef = useRef<HTMLInputElement>(null);
    const mergedRef = useMergedRef(ref, internalRef);
    const classNames = cx(className, 'w-1/2');

    const { setFieldValue, getFieldMeta, setFieldTouched, getFieldProps, values } = useFormikContext();
    const { data } = useGeonames(
      getFieldMeta(AddressFormControlNames.ZIP).value as string,
      getFieldMeta(AddressFormControlNames.COUNTRY).value as string,
      getFieldMeta(AddressFormControlNames.COUNTRY).value as string,
      'postalcode',
    );

    const [inputValue, setInputValue] = useState('');
    const fieldValue = (values as any)[AddressFormControlNames.ZIP] as string;

    const extractZipCode = useCallback((input: string): string => {
      if (!input) return '';

      const trimmedInput = input.trim();

      if (!trimmedInput.includes(',')) return trimmedInput;

      const parts = trimmedInput.split(',');
      const zipPart = parts[0].trim();
      return zipPart || trimmedInput;
    }, []);

    const handleInputChange = useCallback(
      (newInputValue: string) => {
        const zipCode = extractZipCode(newInputValue);
        setInputValue(zipCode);
        setFieldValue(AddressFormControlNames.ZIP, zipCode, true);
      },
      [setFieldValue, extractZipCode],
    );

    const handleChange = useCallback(
      (newValue: string | Geoname | null) => {
        if (newValue && typeof newValue !== 'string') {
          setFieldValue(AddressFormControlNames.ZIP, newValue.postalCode, true);
          if (newValue.placeName) {
            setFieldValue(AddressFormControlNames.CITY, newValue.placeName, true);
          }
        } else {
          setFieldValue(AddressFormControlNames.ZIP, newValue || '', true);
        }
      },
      [setFieldValue],
    );

    const getOptionLabel = useCallback((option: Geoname | string) => {
      if (typeof option === 'string') return option;
      return `${option.postalCode}, ${option.placeName}`;
    }, []);

    const isOptionEqualToValue = useCallback((option: string | Geoname, value: string | Geoname) => {
      if (typeof option === 'string' || typeof value === 'string') {
        return (
          (typeof option === 'string' ? option : option.postalCode) ===
          (typeof value === 'string' ? value : value.postalCode)
        );
      }
      return option.postalCode === value.postalCode && option.placeName === value.placeName;
    }, []);

    const uniquePostalCodes = useMemo(() => {
      if (!data?.postalCodes) return [];
      return Array.from(new Map(data.postalCodes.map(item => [`${item.postalCode}-${item.placeName}`, item])).values());
    }, [data?.postalCodes]);

    const zipInputProps = useMemo(
      () => ({
        id,
        'data-testid': dataTestId,
        className: classNames,
        ref: mergedRef,
      }),
      [id, dataTestId, classNames, mergedRef],
    );

    return (
      <FormControl.Root name={AddressFormControlNames.ZIP} {...zipInputProps}>
        <Autocomplete
          value={fieldValue}
          inputValue={inputValue}
          onInputChange={(_, newInputValue) => handleInputChange(newInputValue)}
          onChange={(_, newValue) => handleChange(newValue)}
          options={uniquePostalCodes}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqualToValue}
          freeSolo
          renderInput={params => (
            <TextField
              {...params}
              required={required}
              label={t('address_form_zip_label', { ns: Namespaces.ProjectsPage })}
              placeholder={t('address_form_zip_placeholder', { ns: Namespaces.ProjectsPage })}
              onBlur={() => {
                setFieldTouched(AddressFormControlNames.ZIP, true, true).catch();
              }}
            />
          )}
        />
        <FormError field={getFieldProps(AddressFormControlNames.ZIP)} />
      </FormControl.Root>
    );
  },
);

ZipInput.displayName = 'ZipInput';

export default ZipInput;
