import { Modal } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, ElementContent, useMergedRef } from '@react-fe/core';
import { ModalConfiguration } from '@react-fe/expertunity-base/hooks';
import { ModalState, useTypedBaseStore } from '@react-fe/expertunity-base/stores';
import cx from 'classnames';
import { forwardRef, useCallback, useMemo, useRef } from 'react';

export type ModalProviderProps = CoreComponentProps & { children: ElementContent };

export const ModalProvider: CoreComponent<ModalProviderProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  ModalProviderProps
>(({ id, 'data-testid': dataTestId, className, children }, ref) => {
  const { contextModals, closeModal } = useTypedBaseStore<ModalState>();
  const internalRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx(className, 'h-full');

  const handleCloseModal = useCallback(
    (modalConfiguration: ModalConfiguration, e: React.MouseEvent<HTMLButtonElement>, reason?: string) => {
      if (modalConfiguration.handleClose) {
        modalConfiguration.handleClose(e, reason);
      } else {
        closeModal(modalConfiguration.modalId);
      }
    },
    [closeModal],
  );

  const modalProviderProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      ref: mergedRef,
    }),
    [id, dataTestId, classNames, mergedRef],
  );

  return (
    <div {...modalProviderProps}>
      {children}
      {contextModals.map(modal => {
        const { content, ...modalProps } = modal;
        return (
          <Modal
            key={modal.modalId}
            {...modalProps}
            open={modal.isVisible ?? false}
            handleClose={(e, reason) => handleCloseModal(modal, e, reason)}
          >
            {content}
          </Modal>
        );
      })}
    </div>
  );
});

ModalProvider.displayName = 'ModalProvider';

export default ModalProvider;
