import { CoreComponent, CoreComponentProps, ElementContent, useMergedRef } from '@react-fe/core';
import cx from 'classnames';
import { forwardRef, useMemo, useRef } from 'react';

export interface PageLayoutProps extends CoreComponentProps {
  children?: ElementContent;
}

export const PageLayout: CoreComponent<PageLayoutProps, HTMLDivElement> = forwardRef<HTMLDivElement, PageLayoutProps>(
  ({ id, 'data-testid': dataTestId, className, children }, ref) => {
    const internalRef = useRef<HTMLDivElement>(null);
    const mergedRef = useMergedRef(ref, internalRef);
    const classNames = cx(className, 'flex flex-col gap-spacing-3 grow');

    const pagelayoutProps = useMemo(
      () => ({
        id,
        'data-testid': dataTestId,
        className: classNames,
        ref: mergedRef,
      }),
      [id, dataTestId, classNames, mergedRef],
    );

    return <div {...pagelayoutProps}>{children}</div>;
  },
);

PageLayout.displayName = 'PageLayout';

export default PageLayout;
