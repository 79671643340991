import { Autocomplete, FormControl, FormError, TextField } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import { Namespaces } from '@react-fe/expertunity-base/constants';
import { useGeonames } from '@react-fe/expertunity-base/hooks';
import { Geoname } from '@react-fe/expertunity-base/models';
import cx from 'classnames';
import { useFormikContext } from 'formik';
import { forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressFormControlNames } from '../../address-form.constants';

export type CityInputProps = CoreComponentProps & { required: boolean };

export const CityInput: CoreComponent<CityInputProps, HTMLInputElement> = forwardRef<HTMLInputElement, CityInputProps>(
  ({ id, 'data-testid': dataTestId, className, required }, ref) => {
    const { t } = useTranslation();
    const internalRef = useRef<HTMLInputElement>(null);
    const mergedRef = useMergedRef(ref, internalRef);
    const classNames = cx(className, 'w-1/2');

    const { setFieldValue, getFieldMeta, setFieldTouched, getFieldProps, values } = useFormikContext();
    const { data } = useGeonames(
      getFieldMeta(AddressFormControlNames.CITY).value as string,
      getFieldMeta(AddressFormControlNames.COUNTRY).value as string,
      getFieldMeta(AddressFormControlNames.COUNTRY).value as string,
      'placename',
    );

    const [inputValue, setInputValue] = useState('');
    const fieldValue = (values as any)[AddressFormControlNames.CITY] as string;

    const getOptionLabel = useCallback((option: Geoname | string) => {
      if (typeof option === 'string') return option;
      return `${option.postalCode}, ${option.placeName}`;
    }, []);

    const isOptionEqualToValue = useCallback((option: string | Geoname, value: string | Geoname) => {
      if (typeof option === 'string' || typeof value === 'string') {
        return (
          (typeof option === 'string' ? option : option.placeName) ===
          (typeof value === 'string' ? value : value.placeName)
        );
      }
      return option.placeName === value.placeName && option.postalCode === value.postalCode;
    }, []);

    const extractCityName = useCallback((input: string): string => {
      if (!input) return '';

      const trimmedInput = input.trim();

      if (!trimmedInput.includes(',')) return trimmedInput;

      const parts = trimmedInput.split(',');
      if (parts.length < 2) return trimmedInput;

      const cityPart = parts.slice(1).join(',').trim();
      return cityPart || trimmedInput;
    }, []);

    const handleInputChange = useCallback(
      (newInputValue: string) => {
        const cityName = extractCityName(newInputValue);
        setInputValue(cityName);
        setFieldValue(AddressFormControlNames.CITY, cityName, true);
      },
      [setFieldValue, extractCityName],
    );

    const handleChange = useCallback(
      (newValue: string | Geoname | null) => {
        if (newValue && typeof newValue !== 'string') {
          setFieldValue(AddressFormControlNames.CITY, newValue.placeName, true);
          if (newValue.postalCode) {
            setFieldValue(AddressFormControlNames.ZIP, newValue.postalCode, true);
          }
        } else {
          setFieldValue(AddressFormControlNames.CITY, newValue || '', true);
        }
      },
      [setFieldValue],
    );

    const handleBlur = useCallback(() => {
      setFieldTouched(AddressFormControlNames.CITY, true, true).catch();
    }, [setFieldTouched]);

    const cityInputProps = useMemo(
      () => ({
        id,
        'data-testid': dataTestId,
        className: classNames,
        ref: mergedRef,
      }),
      [id, dataTestId, classNames, mergedRef],
    );

    return (
      <FormControl.Root name={AddressFormControlNames.CITY} {...cityInputProps}>
        <Autocomplete
          value={fieldValue}
          inputValue={inputValue}
          onInputChange={(_, newInputValue) => handleInputChange(newInputValue)}
          onChange={(_, newValue) => handleChange(newValue)}
          options={data?.postalCodes || []}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqualToValue}
          freeSolo
          renderInput={params => (
            <TextField
              {...params}
              required={required}
              label={t('address_form_city_label', { ns: Namespaces.ProjectsPage })}
              placeholder={t('address_form_city_placeholder', { ns: Namespaces.ProjectsPage })}
              onBlur={handleBlur}
            />
          )}
        />
        <FormError field={getFieldProps(AddressFormControlNames.CITY)} />
      </FormControl.Root>
    );
  },
);

CityInput.displayName = 'CityInput';

export default CityInput;
