import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { MenuItem as MUIMenuItem, MenuItemProps as MUIMenuItemProps } from '@mui/material';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import cx from 'classnames';
import { forwardRef, useMemo, useRef } from 'react';
import { Icon } from '../icon';

export type MenuItemProps = CoreComponentProps &
  Omit<MUIMenuItemProps, 'ref'> & {
    icon?: IconDefinition;
    color?: 'success' | 'error' | 'info' | 'warning';
  };

export const MenuItem: CoreComponent<MenuItemProps, HTMLLIElement> = forwardRef<HTMLLIElement, MenuItemProps>(
  ({ id, 'data-testid': dataTestId, className, children, icon, color, ...muiMenuItemProps }, ref) => {
    const internalRef = useRef<HTMLLIElement>(null);
    const mergedRef = useMergedRef(ref, internalRef);
    const classNames = cx(className, 'flex flex-row gap-spacing-1', `!text-${color}`);

    const menuItemProps = useMemo(
      () => ({
        id,
        'data-testid': dataTestId,
        className: classNames,
        ref: mergedRef,
        ...muiMenuItemProps,
      }),
      [id, dataTestId, classNames, mergedRef, muiMenuItemProps],
    );

    const menuItemContent = icon ? (
      <div className="flex flex-row items-center gap-spacing-1">
        <Icon icon={icon} />
        {children}
      </div>
    ) : (
      children
    );

    return (
      <MUIMenuItem
        {...menuItemProps}
        sx={{
          padding: '0',
        }}
      >
        {menuItemContent}
      </MUIMenuItem>
    );
  },
);

MenuItem.displayName = 'MenuItem';

export default MenuItem;
