import { Divider, Heading } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import { Namespaces } from '@react-fe/expertunity-base/constants';
import cx from 'classnames';
import { forwardRef, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAttachmentsCategoryList } from '../../hooks/use-attachments-category-list';
import { AttachmentWithIndex } from '../attachments-list/attachments-list.constants';
import { CategorizedAttachmentForm } from '../categorized-attachment-form';

export type WithCategoryListProps = CoreComponentProps & {
  fieldName: string;
  attachments: AttachmentWithIndex[];
  form: { values: any };
  removeAttachmentByIndex: (index: number) => void;
};

export const WithCategoryList: CoreComponent<WithCategoryListProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  WithCategoryListProps
>(({ id, 'data-testid': dataTestId, className, fieldName, attachments, form, removeAttachmentByIndex }, ref) => {
  const { t } = useTranslation();
  const categories = useAttachmentsCategoryList();
  const internalRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx(className, 'flex flex-col gap-spacing-2');

  const categorizedAttachments = useMemo(() => {
    const categoryOrder = categories.map(category => category.value);
    const attachmentsByCategory = attachments.reduce(
      (acc, attachmentWithIndex) => {
        const category = attachmentWithIndex.attachment.category || 'Uncategorized';
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(attachmentWithIndex);
        return acc;
      },
      {} as Record<string, AttachmentWithIndex[]>,
    );

    return Object.fromEntries(
      [...categoryOrder, 'Uncategorized']
        .filter(category => attachmentsByCategory[category])
        .map(category => [category, attachmentsByCategory[category]]),
    );
  }, [attachments, categories]);

  const withCategoryListProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      ref: mergedRef,
    }),
    [id, dataTestId, classNames, mergedRef],
  );

  return (
    <div {...withCategoryListProps}>
      {Object.entries(categorizedAttachments).map(([category, attachments]) => (
        <div className="flex flex-col gap-spacing-2" key={category}>
          <Heading variant={Heading.variants.H6}>{t(category)}</Heading>

          <div className="flex flex-col gap-spacing-1">
            <div className="flex w-full gap-spacing-2">
              <div className="w-[30%] pl-spacing-1">
                {t('categorized_attachment_list_file_label', {
                  ns: Namespaces.CategorizedAttachment,
                  defaultValue: 'File',
                })}
              </div>
              <div>
                {t('categorized_attachment_list_description_label', {
                  ns: Namespaces.CategorizedAttachment,
                  defaultValue: 'Description',
                })}
              </div>
            </div>
            <Divider />
          </div>

          {(attachments as AttachmentWithIndex[]).map(attachmentWithIndex => (
            <CategorizedAttachmentForm
              key={`with-category-attachment-form-group-${attachmentWithIndex.index}`}
              index={attachmentWithIndex.index}
              name={fieldName}
              attachment={attachmentWithIndex.attachment}
              form={form}
              remove={removeAttachmentByIndex}
              showOnlyDescription={true}
            />
          ))}
        </div>
      ))}
    </div>
  );
});

WithCategoryList.displayName = 'WithCategoryList';

export default WithCategoryList;
