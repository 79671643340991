import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import { Attachment } from '@react-fe/expertunity-base/models';
import cx from 'classnames';
import { forwardRef, useMemo, useRef } from 'react';
import { ToCategorizeList } from '../to-categorize-list';
import { WithCategoryList } from '../with-category-list';
import { AttachmentWithIndex } from './attachments-list.constants';

export type AttachmentsListProps = CoreComponentProps & {
  fieldName: string;
  attachments: Attachment[];
  form: { values: any };
  removeAttachmentByIndex: (index: number) => void;
};

export const AttachmentsList: CoreComponent<AttachmentsListProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  AttachmentsListProps
>(({ id, 'data-testid': dataTestId, className, fieldName, attachments, form, removeAttachmentByIndex }, ref) => {
  const internalRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx(className, 'flex flex-col gap-spacing-2');

  const withoutCategory: AttachmentWithIndex[] = [];
  const withCategory: AttachmentWithIndex[] = [];

  attachments.forEach((attachment: Attachment, index: number) => {
    if (!attachment.category) {
      withoutCategory.push({ attachment, index });
    } else {
      withCategory.push({ attachment, index });
    }
  });

  const attachmentslistProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      ref: mergedRef,
    }),
    [id, dataTestId, classNames, mergedRef],
  );

  const listProps = useMemo(
    () => ({
      fieldName,
      form,
      removeAttachmentByIndex,
    }),
    [fieldName, form, removeAttachmentByIndex],
  );

  return (
    <div {...attachmentslistProps}>
      {withoutCategory.length > 0 ? <ToCategorizeList attachments={withoutCategory} {...listProps} /> : null}
      {withCategory.length > 0 ? <WithCategoryList attachments={withCategory} {...listProps} /> : null}
    </div>
  );
});

AttachmentsList.displayName = 'AttachmentsList';

export default AttachmentsList;
