export const HIERARCHICAL_ITEMS_MODAL_SX_STYLES = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      maxWidth: 'unset',
      width: '650px',
      padding: '24px',
      borderRadius: '4px',
      border: '1px solid #E3E5E4',
      '& .MuiDialogContent-root': {
        padding: '0',
        height: '72vh',
      },
    },
  },
};
