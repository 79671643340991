import { Namespaces } from '@react-fe/expertunity-base';
import { useTranslation } from 'react-i18next';
import { CreateOfferModalStep } from '../create-offer-modal.constants';
import { useMemo } from 'react';

export const useCreateOfferSteps = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        index: CreateOfferModalStep.DOCUMENTS,
        label: t('create_offer_step_documents', { ns: Namespaces.SellerCreateOfferPage, defaultValue: 'Documents' }),
      },
      {
        index: CreateOfferModalStep.COVER_SHEET,
        label: t('create_offer_step_cover_sheet', {
          ns: Namespaces.SellerCreateOfferPage,
          defaultValue: 'Cover sheet',
        }),
      },
    ],
    [t],
  );
};
