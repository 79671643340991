import { MultiSectionDigitalClock as MUIMultiSectionDigitalClock } from '@mui/x-date-pickers';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import cx from 'classnames';
import { forwardRef, memo, useMemo, useRef } from 'react';

export interface DigitalClockProps extends CoreComponentProps {
  value?: Date;
  onChange?: (value: Date) => void;
}

const DigitalClockComponent: CoreComponent<DigitalClockProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  DigitalClockProps
>(({ id, 'data-testid': dataTestId, className, value, onChange }, ref) => {
  const internalRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx(className);

  const digitalClockProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      ref: mergedRef,
      onChange,
      autoFocus: false,
      sx: {
        borderBottom: 'none',
        '& .MuiMultiSectionDigitalClockSection-root': {
          borderColor: 'transparent !important',
        },
        '& .MuiMultiSectionDigitalClockSection-item': {
          borderRadius: '0.5rem',
          paddingTop: '0.75rem',
          paddingBottom: '0.75rem',
        },
      },
    }),
    [id, dataTestId, classNames, mergedRef, onChange],
  );

  return <MUIMultiSectionDigitalClock value={value} {...digitalClockProps} />;
});

DigitalClockComponent.displayName = 'DigitalClock';

export const DigitalClock = memo(DigitalClockComponent);

export default DigitalClock;
