import React, { forwardRef, useMemo } from 'react';
import { ComponentDefaultTestId, getTestId } from '@react-fe/common-ui';
import { CoreComponent } from '@react-fe/core';
import { useField } from 'formik';
import { FormError } from '../form-error';
import { TextField, TextFieldProps } from '../text-field';
import { FormControlRootProps } from './form-control-root';

type FormControlMergedProps = FormControlRootProps & TextFieldProps;

export const FormControlTextField: CoreComponent<FormControlMergedProps, HTMLInputElement> = forwardRef<
  HTMLInputElement,
  FormControlMergedProps
>(({ id, 'data-testid': dataTestId, name = '', helperText, ...otherProps }, ref) => {
  const [field, meta] = useField(name);
  const hasErrors = meta.touched && !!meta.error;

  const formControlMergedProps = useMemo(
    () => ({
      'data-testid': dataTestId || getTestId(ComponentDefaultTestId.FORM_CONTROL_TEXT_FIELD, id),
      helperText: hasErrors ? '' : helperText,
      ...otherProps,
    }),
    [dataTestId, hasErrors, helperText, id, otherProps],
  );

  return (
    <div className="flex flex-col">
      <TextField {...field} {...formControlMergedProps} error={hasErrors} />
      <FormError field={field} />
    </div>
  );
});

FormControlTextField.displayName = 'FormControl.TextField';

export default FormControlTextField;
