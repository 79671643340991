import { ToastType } from '@react-fe/common-ui';
import React, { useMemo, useState, useCallback } from 'react';
import { AddComment } from './components/add-comment';
import EditComment from './components/edit-comment/edit-comment';
import { DeleteComment } from './components/delete-comment';
import { CommentList } from './components/comment-list';
import { InternalCommentModalMode, Namespaces } from '@react-fe/expertunity-base';
import { useTranslation } from 'react-i18next';
import { useToast } from '@react-fe/expertunity-base/hooks';
import { InternalComment, MutationConfig } from '@react-fe/expertunity-base/models';

export interface InternalCommentModalProps {
  initialModalMode: InternalCommentModalMode;
  comments?: InternalComment[];
  onClose: () => void;
  mutationsConfig: MutationConfig;
  listTitle: string;
}

export function InternalCommentModal({
  initialModalMode,
  onClose,
  comments,
  mutationsConfig,
  listTitle,
}: InternalCommentModalProps) {
  const { t } = useTranslation();
  const { displayToast } = useToast();
  const [selectedComment, setSelectedComment] = useState<InternalComment>();
  const [modalContent, setModalContent] = useState<InternalCommentModalMode>(initialModalMode);
  const [previousModalContent, setPreviousModalContent] = useState<string>(initialModalMode);

  const handleOnClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleAddComment = useCallback(() => {
    setModalContent(InternalCommentModalMode.ADD);
  }, []);

  const handleOnCancelEditComment = useCallback(() => {
    setModalContent(InternalCommentModalMode.VIEW);
  }, []);

  const handleOnCancelAddComment = useCallback(() => {
    if (!comments?.length) {
      onClose();
      return;
    }

    if (previousModalContent === InternalCommentModalMode.ADD) {
      onClose();
      return;
    }

    setModalContent(InternalCommentModalMode.VIEW);
  }, [comments?.length, previousModalContent, onClose]);

  const handleOnEditComment = useCallback((comment: InternalComment) => {
    setSelectedComment(comment);
    setModalContent(InternalCommentModalMode.EDIT);
  }, []);

  const handleOnDeleteComment = useCallback((comment: InternalComment) => {
    setSelectedComment(comment);
    setModalContent(InternalCommentModalMode.DELETE);
  }, []);

  const handleOnSuccessEditComment = useCallback(() => {
    displayToast({
      message: t('edit_comment_success', {
        ns: Namespaces.TenderPage,
      }),
      type: ToastType.SUCCESS,
    });
    setModalContent(InternalCommentModalMode.VIEW);
  }, [displayToast, t]);

  const handleOnErrorEditComment = useCallback(() => {
    displayToast({
      message: t('edit_comment_error', {
        ns: Namespaces.TenderPage,
      }),
      type: ToastType.ERROR,
    });
  }, [displayToast, t]);

  const handleOnSuccessAddComment = useCallback(() => {
    displayToast({
      message: t('add_comment_success'),
      type: ToastType.SUCCESS,
    });
    setPreviousModalContent(InternalCommentModalMode.VIEW);
    setModalContent(InternalCommentModalMode.VIEW);
  }, [displayToast, t]);

  const handleOnErrorAddComment = useCallback(() => {
    displayToast({
      message: t('add_comment_error'),
      type: ToastType.ERROR,
    });
  }, [displayToast, t]);

  const handleOnCancelDeleteComment = useCallback(() => {
    setModalContent(InternalCommentModalMode.VIEW);
  }, []);

  const handleOnSuccessDeleteComment = useCallback(() => {
    displayToast({
      message: t('delete_comment_success', {
        ns: Namespaces.TenderPage,
      }),
      type: ToastType.SUCCESS,
    });
    setModalContent(InternalCommentModalMode.VIEW);
  }, [displayToast, t]);

  const handleOnErrorDeleteComment = useCallback(() => {
    displayToast({
      message: t('delete_comment_error', {
        ns: Namespaces.TenderPage,
      }),
      type: ToastType.ERROR,
    });
  }, [displayToast, t]);

  const modalContentComponent = useMemo(() => {
    if (modalContent === InternalCommentModalMode.ADD) {
      return (
        <AddComment
          mutationConfig={mutationsConfig}
          onCancel={handleOnCancelAddComment}
          onSuccess={handleOnSuccessAddComment}
          onError={handleOnErrorAddComment}
        />
      );
    }

    if (modalContent === InternalCommentModalMode.EDIT) {
      return (
        <EditComment
          mutationConfig={mutationsConfig}
          comment={selectedComment as InternalComment}
          onCancel={handleOnCancelEditComment}
          onSuccess={handleOnSuccessEditComment}
          onError={handleOnErrorEditComment}
        />
      );
    }

    if (modalContent === InternalCommentModalMode.DELETE) {
      return (
        <DeleteComment
          mutationConfig={mutationsConfig}
          comment={selectedComment as InternalComment}
          onCancel={handleOnCancelDeleteComment}
          onSuccess={handleOnSuccessDeleteComment}
          onError={handleOnErrorDeleteComment}
        />
      );
    }

    return (
      <CommentList
        title={listTitle}
        comments={comments}
        onClose={handleOnClose}
        onAddComment={handleAddComment}
        onEditComment={handleOnEditComment}
        onDeleteComment={handleOnDeleteComment}
      />
    );
  }, [
    modalContent,
    comments,
    mutationsConfig,
    selectedComment,
    handleAddComment,
    handleOnCancelAddComment,
    handleOnCancelDeleteComment,
    handleOnCancelEditComment,
    handleOnClose,
    handleOnDeleteComment,
    handleOnEditComment,
    handleOnErrorAddComment,
    handleOnErrorDeleteComment,
    handleOnErrorEditComment,
    handleOnSuccessAddComment,
    handleOnSuccessDeleteComment,
    handleOnSuccessEditComment,
    listTitle,
  ]);

  return <div>{modalContentComponent}</div>;
}

export default InternalCommentModal;
