export enum SpinnerColors {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  WARNING = 'warning',
  BLACK = 'black',
  WHITE = 'white',
}

export enum SpinnerSizes {
  XS = 16,
  SMALL = 24,
  MEDIUM = 32,
  LARGE = 40,
}

export type SpinnerSize = typeof SpinnerSizes | number;
