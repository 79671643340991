import { create } from 'zustand';
import { CommonBuyerState, createCommonBuyerSlice } from './common-buyer-slice';
import { ContractorActionsState, createContractorActionsSlice } from './contractor-actions-slice';
import { createFocusedContractorSlice, FocusedContractorState } from './focused-contractor-slice';
import { inviteContractorsSlice, InviteContractorsState } from './invite-contractors-slice';
import { AcceptRejectContractorsState, createAcceptRejectContractorSlice } from './accept-reject-contractors-slice';
import { ProjectsState, createProjectsSlice } from './projects-slice';

export * from './common-buyer-slice';
export * from './contractor-actions-slice';
export * from './focused-contractor-slice';
export * from './invite-contractors-slice';
export * from './accept-reject-contractors-slice';
export * from './projects-slice';

export type AllBuyerState = CommonBuyerState &
  ContractorActionsState &
  InviteContractorsState &
  FocusedContractorState &
  AcceptRejectContractorsState &
  ProjectsState;

export const useBuyerStore = create<AllBuyerState>((set, get, store) => ({
  ...createCommonBuyerSlice(set, get, store),
  ...createContractorActionsSlice(set, get, store),
  ...inviteContractorsSlice(set, get, store),
  ...createFocusedContractorSlice(set, get, store),
  ...createAcceptRejectContractorSlice(set, get, store),
  ...createProjectsSlice(set, get, store),
}));

export function useTypedBuyerStore<
  T extends
    | keyof AllBuyerState
    | CommonBuyerState
    | ContractorActionsState
    | InviteContractorsState
    | FocusedContractorState
    | AcceptRejectContractorsState
    | ProjectsState,
>() {
  return useBuyerStore((state): T extends keyof AllBuyerState ? Pick<AllBuyerState, T> : T => state as any);
}

export default useBuyerStore;
