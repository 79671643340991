import { FormControl, Heading, InputAdornment } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import { Namespaces } from '@react-fe/expertunity-base';
import cx from 'classnames';
import { forwardRef, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { OfferFormControlName } from '../../../../create-offer-modal.constants';

export type CreateOfferFieldsProps = CoreComponentProps;

export const CreateOfferFields: CoreComponent<CreateOfferFieldsProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  CreateOfferFieldsProps
>(({ id, 'data-testid': dataTestId, className }, ref) => {
  const { t } = useTranslation();
  const internalRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx(className, 'flex flex-col gap-spacing-2');

  const createOfferFieldsProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      ref: mergedRef,
    }),
    [id, dataTestId, classNames, mergedRef],
  );

  return (
    <div {...createOfferFieldsProps}>
      <Heading variant={Heading.variants.H5}>
        {t('create_offer_cover_sheet_title', { ns: Namespaces.SellerCreateOfferPage, defaultValue: 'Cover sheet' })}
      </Heading>

      <FormControl.Root name={OfferFormControlName.GROSS_EXCL_VAT}>
        <FormControl.TextField
          label={t('create_offer_form_gross_excl_vat', {
            ns: Namespaces.SellerCreateOfferPage,
            defaultValue: 'Gross excl. VAT',
          })}
          required={true}
          placeholder={t('create_offer_cover_sheet_gross_excl_vat_placeholder', {
            ns: Namespaces.SellerCreateOfferPage,
            defaultValue: 'Enter the gross excl. VAT...',
          })}
          type="number"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {t('tender_form_tender_quotation_input_addon', {
                  ns: Namespaces.CreateTenderPage,
                  defaultValue: 'CHF',
                })}
              </InputAdornment>
            ),
          }}
        />
      </FormControl.Root>

      <FormControl.Root name={OfferFormControlName.DESCRIPTION} className="flex !grow !h-full">
        <FormControl.TextField
          label={t('create_offer_form_description', {
            ns: Namespaces.SellerCreateOfferPage,
            defaultValue: 'Comments',
          })}
          placeholder={t('create_offer_cover_sheet_description_placeholder', {
            ns: Namespaces.SellerCreateOfferPage,
            defaultValue: 'Enter your offer comments...',
          })}
          customSx={{
            '.MuiInputBase-root': {
              maxHeight: '375px !important',
            },
          }}
          multiline={true}
          rows={15}
        />
      </FormControl.Root>
    </div>
  );
});

CreateOfferFields.displayName = 'CreateOfferFields';

export default CreateOfferFields;
