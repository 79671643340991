export enum Namespaces {
  Common = 'common',
  Validation = 'validation',
  ProjectsPage = 'projects_page',
  ProjectPage = 'project_page',
  CreateTenderPage = 'create_tender_page',
  TenderPage = 'tender_page',
  TypesOfWork = 'types_of_work',
  BuildingTypes = 'building_types',
  CategorizedAttachment = 'categorized_attachment',
  Buyer = 'buyer',
  Seller = 'seller',
  SellerTendersPage = 'seller_tenders_page',
  SellerTenderDetailsPage = 'seller_tender_details_page',
  SellerCreateOfferPage = 'seller_create_offer_page',
  Settings = 'settings',
}

export const NAMESPACES_LIST = Object.values(Namespaces);
