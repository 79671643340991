import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { Button, Popover, PopoverHandler } from '@react-fe/common-ui';
import { ContactCardPerson } from '@react-fe/expertunity-base/models';
import { useCallback, useMemo, useRef } from 'react';
import { ContactCardContactDetails } from '../components/contact-card-contact-details';

interface UseContactCardPopupResult {
  openPopup: (e?: any) => void;
  ContactCardPopupComponent: React.FC<{ contact: ContactCardPerson }>;
}

export const useContactCardPopup = (): UseContactCardPopupResult => {
  const popoverRef = useRef<PopoverHandler>(null);

  const openPopup = useCallback((e?: any) => {
    e?.preventDefault();
    e?.stopPropagation();

    if (popoverRef.current) {
      popoverRef.current.openPopover(e);
    }
  }, []);

  // Always map different properties using this function
  const convertToContactCardPerson = useCallback((input: any): ContactCardPerson => {
    return {
      firstName: input?.firstName ?? input?.first_name ?? input?.member_firstname ?? undefined,
      lastName: input?.lastName ?? input?.last_name ?? input?.member_lastname ?? undefined,
      function: input?.function ?? input?.member_function ?? undefined,
      companyName: input?.companyName ?? input?.company?.name ?? input?.client?.name ?? undefined,
      department: input?.department ?? input?.company?.department ?? input?.client?.department ?? undefined,
      street: input?.street ?? input?.company?.street ?? input?.client?.street ?? undefined,
      city: input?.city ?? input?.clientCity ?? input?.company?.city ?? input?.client?.city ?? undefined,
      zip: input?.zip ?? input?.company?.zip ?? input?.client?.zip ?? undefined,
      country_code: input?.country_code ?? input?.company?.country_code ?? input?.client?.country_code ?? undefined,
      email: input?.email ?? input?.member_email ?? undefined,
      phone:
        input?.phone ??
        input?.phoneNumber ??
        input?.phone_number ??
        input?.company?.phone ??
        input?.member_phone_number ??
        input?.member_mobile_phone_number ??
        undefined,
      logoUrl: input?.logoUrl ?? input?.logo_url ?? input?.company?.logo_url ?? undefined,
    };
  }, []);

  const ContactCardPopupComponent = useMemo(() => {
    const Component: React.FC<{ contact: ContactCardPerson }> = ({ contact }) => {
      const convertedContact = convertToContactCardPerson(contact);

      const handleClose = (e: any) => {
        e?.stopPropagation();
        e?.preventDefault();
        popoverRef.current?.closePopover();
      };

      return (
        <Popover ref={popoverRef}>
          <div className="flex gap-spacing-3 py-spacing-1 px-spacing-3">
            <ContactCardContactDetails contact={convertedContact} />
            <Button.Root type={Button.Root.types.GHOST} onClick={handleClose} className="!p-0 !h-[12px]">
              <Button.Icon icon={faXmark} />
            </Button.Root>
          </div>
        </Popover>
      );
    };

    return Component;
  }, [convertToContactCardPerson]);

  return { openPopup, ContactCardPopupComponent };
};
