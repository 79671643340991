import { CoreComponent, CoreComponentProps, ElementContent, useMergedRef } from '@react-fe/core';
import cx from 'classnames';
import { forwardRef, useMemo, useRef } from 'react';

export interface PagePanelProps extends CoreComponentProps {
  children: ElementContent;
}

export const PagePanel: CoreComponent<PagePanelProps, HTMLDivElement> = forwardRef<HTMLDivElement, PagePanelProps>(
  ({ id, 'data-testid': dataTestId, className, children }, ref) => {
    const internalRef = useRef<HTMLDivElement>(null);
    const mergedRef = useMergedRef(ref, internalRef);
    const classNames = cx(className, 'flex flex-col bg-white grow p-spacing-3 gap-spacing-3 rounded-modal');

    const pagepanelProps = useMemo(
      () => ({
        id,
        'data-testid': dataTestId,
        className: classNames,
        ref: mergedRef,
      }),
      [id, dataTestId, classNames, mergedRef],
    );

    return <div {...pagepanelProps}>{children}</div>;
  },
);

PagePanel.displayName = 'PagePanel';

export default PagePanel;
