import React, { forwardRef, useMemo, useRef } from 'react';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import cx from 'classnames';
import { Text } from '../text';
import { Icon, IconSize } from '../icon';
import { faFileArrowUp, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { ProgressBar } from '../progress-bar';
import { Button, ButtonType } from '../button';
import { useTranslation } from 'react-i18next';

export interface FileInfoDisplayProps extends CoreComponentProps {
  file: File | { name: string; size: number };
  progress?: number;
  error?: any;
  onDelete?: () => void;
}

export const FileInfoDisplay: CoreComponent<FileInfoDisplayProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  FileInfoDisplayProps
>(({ id, 'data-testid': dataTestId, className, file, progress, error, onDelete }, ref) => {
  const { t } = useTranslation();
  const internalRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);

  const fileInfoDisplayProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className,
      ref: mergedRef,
      file,
      progress,
      error,
    }),
    [id, dataTestId, className, mergedRef, file, progress, error],
  );

  const { name, size } = file;
  const sizeInKB = (size / 1024).toFixed(2);
  const statusLabel =
    progress != null ? (progress === 100 ? t('file_upload_completed') : t('file_upload_in_progress')) : '';

  return (
    <div {...fileInfoDisplayProps} className={cx(className, 'p-spacing-2')} ref={mergedRef}>
      <div className="flex gap-spacing-2 items-center">
        <div className="flex w-10 h-10 items-center justify-center">
          <Icon className="text-info" icon={faFileArrowUp} size={IconSize.LARGE} />
        </div>

        <div className="flex flex-col grow gap-spacing-0.5">
          <div className="flex flex-col w-full items-start">
            <Text>{name}</Text>
            <Text className="text-center" variant={Text.variants.BODY2} color={Text.colors.TEXT_SECONDARY}>
              {sizeInKB}KB • {statusLabel}
            </Text>
          </div>
          {progress != null && <ProgressBar color={ProgressBar.colors.SECONDARY} progress={progress} />}
          {error && (
            <Text color={Text.colors.ERROR} className="mt-spacing-1 text-left">
              {t('file_upload_error')}
            </Text>
          )}
        </div>

        <div className="flex items-center justify-center">
          <Button.Root type={ButtonType.GHOST} onClick={onDelete}>
            <Button.Icon className="text-text-secondary text-base" icon={faTrash} />
          </Button.Root>
        </div>
      </div>
    </div>
  );
});

FileInfoDisplay.displayName = 'FileInfoDisplay';

export default FileInfoDisplay;
