import { Box, Checkbox, Chip, ListSubheader, MenuItem } from '@mui/material';
import { CoreComponent } from '@react-fe/core';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Dropdown, DropdownProps } from '../dropdown';
import { Tooltip } from '../tooltip';

export interface MultiSelectOption {
  value: string;
  label: string;
  disabled?: boolean;
  children?: MultiSelectOption[];
}

export interface MultiSelectDropdownProps extends DropdownProps {
  options: MultiSelectOption[];
  grow?: boolean;
}

export interface MultiSelectDropdownRef {
  handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

export const MultiSelectDropdown: CoreComponent<MultiSelectDropdownProps, MultiSelectDropdownRef> = forwardRef<
  MultiSelectDropdownRef,
  MultiSelectDropdownProps
>(
  (
    {
      id,
      'data-testid': dataTestId,
      className,
      options = [],
      value,
      name = '',
      label = '',
      labelId = '',
      required = false,
      inputClassName = '',
      disabled = false,
      onChange,
      fullWidth = true,
      grow = true,
      error = false,
      customSx,
      renderValue,
    }: MultiSelectDropdownProps,
    ref,
  ) => {
    const [selectedValues, setSelectedValues] = useState<string[]>(value ? value : []);

    useEffect(() => {
      if (Array.isArray(value) && value.length === 1 && value[0] === '') {
        setSelectedValues(['']);
      }
    }, [value]);

    const handleChange = useCallback(
      (event: React.ChangeEvent<{ value: unknown }>) => {
        if (!disabled && onChange) {
          onChange(event);
        }

        const {
          target: { value },
        } = event;
        setSelectedValues(typeof value === 'string' ? value.split(',') : (value as string[]));
      },
      [disabled, onChange],
    );

    useImperativeHandle(
      ref,
      () => ({
        handleChange,
      }),
      [handleChange],
    );

    const findOptionLabel = useCallback((optionsList: MultiSelectOption[], selectedValue: string | number): string => {
      for (const option of optionsList) {
        if (option.value === selectedValue) {
          return option.label;
        }
        if (option.children) {
          const childLabel = findOptionLabel(option.children, selectedValue);
          if (childLabel) {
            return childLabel;
          }
        }
      }
      return '';
    }, []);

    const renderValueFunction = useCallback(
      (selected: string[]) => {
        const chipHeight = 24;
        const parentHeight = grow ? undefined : 24;
        const shouldLimitChips =
          !grow && selected && Array.isArray(selected) && selected.length * chipHeight > (parentHeight || 0);

        const selectedOptionsLabel = selected.map(selected => findOptionLabel(options, selected));
        const firstSelectedLabel = selectedOptionsLabel[0];
        const moreChipsTooltip = selectedOptionsLabel.slice(1).join(', ');

        return (
          <Box className={`flex gap-1 flex-wrap ${!grow && '!h-[24px] overflow-hidden'}`}>
            {shouldLimitChips ? (
              <>
                <Tooltip title={firstSelectedLabel} className="!max-w-[50%]">
                  <Chip key={selected[0]} label={firstSelectedLabel} className={'!h-[24px]'} />
                </Tooltip>
                <Tooltip title={moreChipsTooltip}>
                  <Chip
                    key="more-chips"
                    label={`+${(selected as (string | number)[]).length - 1} more`}
                    className={!grow ? '!h-[24px]' : ''}
                  />
                </Tooltip>
              </>
            ) : (
              (selected as (string | number)[]).map(selectedValue => (
                <Chip
                  key={selectedValue}
                  label={findOptionLabel(options, selectedValue)}
                  className={!grow ? '!h-[24px]' : ''}
                />
              ))
            )}
          </Box>
        );
      },
      [findOptionLabel, options, grow],
    );

    const multiSelectDropdownProps = useMemo(() => {
      return {
        id,
        'data-testid': dataTestId,
        disabled,
        className,
        value,
        label,
        labelId,
        required,
        name,
        error,
        options,
        inputClassName,
        fullWidth,
        customSx,
        renderValue: renderValue || renderValueFunction,
      };
    }, [
      id,
      dataTestId,
      disabled,
      className,
      inputClassName,
      value,
      label,
      labelId,
      required,
      name,
      error,
      options,
      fullWidth,
      customSx,
      renderValue,
      renderValueFunction,
    ]);

    const renderMenuItems = useCallback(
      (optionsList: MultiSelectOption[]): React.ReactNode[] => {
        return optionsList.map(option => {
          if (option.children && option.children.length > 0) {
            return [
              <ListSubheader key={`subheader-${option.value}`}>{option.label}</ListSubheader>,
              ...renderMenuItems(option.children),
            ];
          }
          return (
            <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
              <Checkbox checked={selectedValues.includes(option.value)} />
              {option.label}
            </MenuItem>
          );
        });
      },
      [selectedValues],
    );

    return (
      <Dropdown {...multiSelectDropdownProps} onChange={handleChange} multiple value={selectedValues}>
        {renderMenuItems(options)}
      </Dropdown>
    );
  },
);

MultiSelectDropdown.displayName = 'MultiSelectDropdown';

export default MultiSelectDropdown;
