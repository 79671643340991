import { Title } from '@react-fe/common-ui';
import { Namespaces, TenderAttachments } from '@react-fe/expertunity-base';
import { Attachment } from '@react-fe/expertunity-base/models';
import { useTranslation } from 'react-i18next';

export const OtherAttachments = ({ otherAttachments }: { otherAttachments: Attachment[] }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-spacing-2">
      <Title
        title={t('create_offer_document_attachments_title', {
          ns: Namespaces.SellerCreateOfferPage,
          defaultValue: 'Attachments',
        })}
        subTitle={t('create_offer_document_attachments_subtitle', {
          ns: Namespaces.SellerCreateOfferPage,
          defaultValue: 'Below are all the attachments available for this tender.',
        })}
      />

      <TenderAttachments attachments={otherAttachments as Attachment[]} iconClassname="text-info" />
    </div>
  );
};
