import { Portal } from '@mui/material';
import { Modal, ToastType } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, logger, useMergedRef } from '@react-fe/core';
import { UseMutateFunction } from '@tanstack/react-query';
import cx from 'classnames';
import { Formik, FormikHelpers } from 'formik';
import { forwardRef, useMemo, useRef } from 'react';
import { object, string } from 'yup';
import { ConfigurableDescriptionForm } from './components/configurable-description-form';
import {
  ConfigurableDescriptionLabels,
  ConfigurableDescriptionPayload,
} from './configurable-description-modal.constants';
import { useToast } from '@react-fe/expertunity-base/hooks';

export type ConfigurableDescriptionModalProps = CoreComponentProps & {
  isOpen: boolean;
  labels: ConfigurableDescriptionLabels;
  setIsOpen?: (isOpen: boolean) => void;
  mutation: UseMutateFunction<any, Error, any, unknown>;
  transform?: (payload: ConfigurableDescriptionPayload) => any;
  onSuccess?: (result: any) => void;
  onError?: (error: Error) => void;
  onCancel?: () => void;
};

export const ConfigurableDescriptionModal: CoreComponent<ConfigurableDescriptionModalProps, HTMLDivElement> =
  forwardRef<HTMLDivElement, ConfigurableDescriptionModalProps>(
    (
      {
        id,
        'data-testid': dataTestId,
        className,
        isOpen,
        labels,
        setIsOpen,
        mutation,
        transform,
        onSuccess,
        onError,
        onCancel,
      },
      ref,
    ) => {
      const { displayToast } = useToast();
      const internalRef = useRef<HTMLDivElement>(null);
      const mergedRef = useMergedRef(ref, internalRef);
      const classNames = cx(className);

      const handleCancel = () => {
        setIsOpen?.(false);
        onCancel?.();
      };

      const configurableDescriptionModalProps = useMemo(
        () => ({
          id,
          'data-testid': dataTestId,
          className: classNames,
          ref: mergedRef,
        }),
        [id, dataTestId, classNames, mergedRef],
      );

      return (
        <div {...configurableDescriptionModalProps}>
          <Portal>
            <Modal
              open={isOpen}
              handleClose={handleCancel}
              shouldShowCloseButton={false}
              customSx={{ margin: '0', '& .MuiDialogContent-root': { margin: '0', padding: '1.5rem !important' } }}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <Formik
                validateOnChange={false}
                initialValues={{ description: '' }}
                validationSchema={object().shape({
                  description: string().nullable(),
                })}
                onSubmit={(values, { setSubmitting }: FormikHelpers<{ description: string }>) => {
                  const payload = transform ? transform(values) : values;
                  mutation(payload, {
                    onSuccess: (result: any) => {
                      logger.info('Contractor application accepted successfully');
                      setSubmitting(false);
                      setIsOpen?.(false);

                      if (labels.successToastMessage) {
                        displayToast({
                          type: ToastType.SUCCESS,
                          message: labels.successToastMessage,
                        });
                      }

                      onSuccess?.(result);
                    },
                    onError: error => {
                      logger.error('Error while accepting the contractor application to tender:', error);
                      if (labels.errorToastMessage) {
                        displayToast({
                          type: ToastType.ERROR,
                          message: labels.errorToastMessage,
                        });
                      }

                      setSubmitting(false);
                      onError?.(error);
                    },
                  });
                }}
              >
                <ConfigurableDescriptionForm labels={labels} handleCancel={handleCancel} />
              </Formik>
            </Modal>
          </Portal>
        </div>
      );
    },
  );

ConfigurableDescriptionModal.displayName = 'ConfigurableDescriptionModal';

export default ConfigurableDescriptionModal;
