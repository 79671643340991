import React, { forwardRef, useId, useMemo } from 'react';
import { CoreComponent } from '@react-fe/core';
import { ComponentDefaultTestId, getTestId } from '@react-fe/common-ui';
import { useField } from 'formik';
import { FormControlRootProps } from './form-control-root';
import { FormError } from '../form-error';
import { FormControl as MUIFormControl, InputLabel as MUIInputLabel } from '@mui/material';
import { MultiSelectDropdown, MultiSelectDropdownProps } from '../multi-select-dropdown';

type FormControlMergedProps = FormControlRootProps & MultiSelectDropdownProps;

export type FormControlMultiSelectDropdownProps = FormControlMergedProps;

export const FormControlMultiSelectDropdown: CoreComponent<FormControlMergedProps, HTMLInputElement> = forwardRef<
  HTMLInputElement,
  FormControlMergedProps
>(({ id, 'data-testid': dataTestId, className, name, label, required, options, onChange, ...otherProps }, ref) => {
  const elementId = useId();
  const [field, meta, helpers] = useField(name as string);
  const { setValue } = helpers;
  const hasErrors = meta.touched && !!meta.error;
  const labelId = label ? `form-control-dropdown-${elementId}` : '';

  const setSelectValue = (value: React.ChangeEvent<{ value: string }>) => {
    const newValue = value.target.value;
    if (onChange) {
      onChange({ target: { value: newValue } } as any);
    }
    setValue(newValue).catch();
  };

  const formControlDropdownProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId || getTestId(ComponentDefaultTestId.FORM_CONTROL_TEXT_FIELD, id),
      className,
      options,
      ...otherProps,
    }),
    [id, dataTestId, className, options, otherProps],
  );

  return (
    <MUIFormControl error={hasErrors} required={required}>
      {label && <MUIInputLabel id={labelId}>{label}</MUIInputLabel>}
      <MultiSelectDropdown
        {...field}
        {...formControlDropdownProps}
        labelId={labelId}
        error={hasErrors}
        value={field.value}
        onChange={setSelectValue}
      />
      <FormError field={field} />
    </MUIFormControl>
  );
});

FormControlMultiSelectDropdown.displayName = 'FormControl.Dropdown';

export default FormControlMultiSelectDropdown;
