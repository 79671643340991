import { Button, Heading, HeadingVariant, Text } from '@react-fe/common-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useDeleteInternalComment from './hooks/use-delete-internal-comment';
import { InternalComment, MutationConfig } from '@react-fe/expertunity-base/models';

export interface DeleteCommentProps {
  comment: InternalComment;
  onCancel: () => void;
  onSuccess: () => void;
  onError: () => void;
  mutationConfig: MutationConfig;
}

export function DeleteComment({ comment, onCancel, onSuccess, onError, mutationConfig }: DeleteCommentProps) {
  const { t } = useTranslation();
  const deleteUrl = `${mutationConfig.url}/${comment.uuid}`;
  const { mutate, isPending } = useDeleteInternalComment({
    url: deleteUrl,
    invalidateQueries: mutationConfig.invalidateQueries,
  });

  const handleOnDelete = () => {
    mutate(undefined, {
      onSuccess: () => {
        onSuccess();
      },
      onError: () => {
        onError();
      },
    });
  };

  return (
    <>
      <div className="flex flex-col gap-spacing-1">
        <Heading variant={HeadingVariant.H4}>{t('delete_internal_comment')}</Heading>
        <Text>{t('delete_internal_comment_undone')}</Text>
      </div>
      <div className="flex mt-spacing-3 items-center justify-end gap-spacing-2">
        <Button.Root type={Button.Root.types.OUTLINED} onClick={onCancel}>
          {t('cancel')}
        </Button.Root>
        <Button.Root loading={isPending} onClick={handleOnDelete} disabled={isPending}>
          {t('delete_button')}
        </Button.Root>
      </div>
    </>
  );
}

export default DeleteComment;
