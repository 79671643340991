import { useContext } from 'react';
import { BreadcrumbContext, BreadcrumbContextType } from '../../context/breadcrumbs-context';

export const useBreadcrumbs = (): BreadcrumbContextType => {
  const context = useContext(BreadcrumbContext);
  if (!context) {
    throw new Error('useBreadcrumbs must be used within a BreadcrumbProvider');
  }
  return context;
};
