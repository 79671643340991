import { StateCreator } from 'zustand';

export interface ProjectsState {
  isProjectTendersFilterCleared: boolean;
  clearProjectTendersFilters: () => void;
}

export const createProjectsSlice: StateCreator<ProjectsState> = set => ({
  isProjectTendersFilterCleared: false,
  clearProjectTendersFilters: () => {
    set({ isProjectTendersFilterCleared: true });
    setTimeout(() => {
      set({ isProjectTendersFilterCleared: false });
    }, 150);
  },
});
