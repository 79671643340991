import { Step, StepButton, Stepper, Title } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps } from '@react-fe/core';
import { Namespaces } from '@react-fe/expertunity-base';
import { Offer, OfferState } from '@react-fe/expertunity-base/models';
import useBaseStore from '@react-fe/expertunity-base/stores';
import cx from 'classnames';
import { forwardRef, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateOfferActions } from './components/create-offer-actions';
import { CreateOfferCoverSheet } from './components/create-offer-cover-sheet';
import { CreateOfferDocument } from './components/create-offer-document';
import CreateOfferFormik from './components/create-offer-formik/create-offer-formik';
import { CreateOfferInfo } from './components/create-offer-info';
import { SaveOfferDraftButton } from './components/save-offer-draft-button';
import { CreateOfferModalStep, OfferFormMode } from './create-offer-modal.constants';
import { useCreateOfferSteps } from './hooks/use-create-offer-steps';
import { useOfferValidationSchema } from './hooks/use-create-offer-validation-schema';

export interface CreateOfferModalProps extends CoreComponentProps {
  offer?: Offer;
}

export const CreateOfferModal: CoreComponent<CreateOfferModalProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  CreateOfferModalProps
>(({ id, 'data-testid': dataTestId, className, offer }, ref) => {
  const { t } = useTranslation();
  const {
    isCreateOfferOnBehalf,
    activeStep,
    setActiveStep,
    setOfferValidationSchema,
    setCanSelectApplicationInformationCategory,
    clearCreateOfferState,
  } = useBaseStore();
  const offerValidationSchema = useOfferValidationSchema();
  const steps = useCreateOfferSteps();
  const classNames = cx(className, 'flex flex-col gap-spacing-2');

  const handleStepClick = (index: number) => {
    setActiveStep(index);
  };

  useEffect(() => {
    if (offerValidationSchema) {
      setOfferValidationSchema(offerValidationSchema);
    }
  }, [offerValidationSchema, setOfferValidationSchema, clearCreateOfferState]);

  useEffect(() => {
    setCanSelectApplicationInformationCategory(false);
    return () => {
      setCanSelectApplicationInformationCategory(true);
    };
  }, [setCanSelectApplicationInformationCategory]);

  const createOfferProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      offer,
      mode: offer ? OfferFormMode.EDIT : OfferFormMode.CREATE,
    }),
    [id, dataTestId, classNames, offer],
  );

  return (
    <CreateOfferFormik {...createOfferProps}>
      <div className="flex flex-col gap-spacing-3">
        <Title
          title={
            createOfferProps.mode === OfferFormMode.CREATE
              ? t('create_offer_title', {
                  ns: Namespaces.SellerCreateOfferPage,
                  defaultValue: 'Create offer',
                })
              : t('edit_offer_title', {
                  ns: Namespaces.SellerCreateOfferPage,
                  defaultValue: 'Edit offer',
                })
          }
          subTitle={t('create_offer_subtitle', {
            ns: Namespaces.SellerCreateOfferPage,
            defaultValue: 'Enter your offer details below. You can also upload relevent documents.',
          })}
        />

        <div className="flex items-center justify-between">
          <Stepper className="w-[25%]" nonLinear activeStep={activeStep}>
            {steps.map(step => {
              return (
                <Step key={`create-offer-stepper-${step.index}`}>
                  <StepButton onClick={() => handleStepClick(step.index)}>{step.label}</StepButton>
                </Step>
              );
            })}
          </Stepper>
          {!isCreateOfferOnBehalf &&
            (createOfferProps.mode === OfferFormMode.CREATE ||
              (createOfferProps.mode === OfferFormMode.EDIT && offer?.state === OfferState.DRAFT)) && (
              <SaveOfferDraftButton />
            )}
        </div>

        <CreateOfferInfo />

        <div className="flex grow min-h-[500px] max-h-[500px] overflow-y-auto">
          {activeStep === CreateOfferModalStep.DOCUMENTS && <CreateOfferDocument />}
          {activeStep === CreateOfferModalStep.COVER_SHEET && <CreateOfferCoverSheet />}
        </div>

        <CreateOfferActions />
      </div>
    </CreateOfferFormik>
  );
});

CreateOfferModal.displayName = 'CreateOfferModal';

export default CreateOfferModal;
