import { Stepper as MUIStepper, StepperProps as MUIStepperProps } from '@mui/material';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import cx from 'classnames';
import { forwardRef, useMemo, useRef } from 'react';

export type StepperProps = CoreComponentProps & Omit<MUIStepperProps, 'ref'>;

export const Stepper: CoreComponent<StepperProps, HTMLDivElement> = forwardRef<HTMLDivElement, StepperProps>(
  ({ id, 'data-testid': dataTestId, className, children, ...otherProps }, ref) => {
    const internalRef = useRef<HTMLDivElement>(null);
    const mergedRef = useMergedRef(ref, internalRef);
    const classNames = cx(className);

    const stepperProps = useMemo(
      () => ({
        id,
        'data-testid': dataTestId,
        className: classNames,
        ref: mergedRef,
        ...otherProps,
      }),
      [id, dataTestId, classNames, mergedRef, otherProps],
    );

    return <MUIStepper {...stepperProps}>{children}</MUIStepper>;
  },
);

Stepper.displayName = 'Stepper';

export default Stepper;
