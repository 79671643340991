import { TreeViewBaseItem } from '@mui/x-tree-view';
import { HierarchicalItem } from '@react-fe/expertunity-base/models';

const toTreeViewFormat = (item: HierarchicalItem): TreeViewBaseItem => {
  if (item.children.length > 0) {
    return {
      id: item.uid,
      label: item.label,
      children: item.children?.map(toTreeViewFormat),
    };
  }

  return {
    id: item.uid,
    label: item.label,
    children: undefined,
  };
};

export const HierarchicalItemMapper = {
  toTreeViewFormat,
};
