import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { logger } from '@react-fe/core';
import { API_URL } from '@react-fe/expertunity-base/constants';
import { MutationConfig } from '@react-fe/expertunity-base/models';

export const useDeleteInternalComment = ({ url, invalidateQueries }: MutationConfig) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const response = await axios.delete(`${API_URL}${url}`);
      return response.data;
    },
    onSuccess: data => {
      logger.debug('internal comment deleted successfully:', data);

      invalidateQueries.forEach(queryKey => {
        queryClient
          .invalidateQueries({ queryKey })
          .then(() => logger.debug(`invalidateQueries run with params { queryKey: ${JSON.stringify(queryKey)} }.`));
      });
    },
    onError: error => {
      logger.error('Error deleting internal comment:', error);
    },
  });
};

export default useDeleteInternalComment;
