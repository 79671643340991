import axios, { AxiosProgressEvent } from 'axios';

interface Params {
  file: File;
  uploadUrl: string;
  onProgress?: (progressEvent: AxiosProgressEvent) => void;
}

export const uploadFile = async <T>({ file, uploadUrl, onProgress }: Params): Promise<T> => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await axios.post<T>(uploadUrl, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: progressEvent => {
      if (onProgress) {
        onProgress(progressEvent);
      }
    },
  });

  return response.data;
};

export interface UploadItem {
  file: File;
  onProgress: (progressEvent: AxiosProgressEvent) => void;
  onSuccess: (data: any) => void;
  onError: (error: any) => void;
}

export const uploadMultipleFiles = async (items: UploadItem[], uploadUrl: string): Promise<any[]> => {
  const results: any[] = [];

  await Promise.all(
    items.map(({ file, onProgress, onSuccess, onError }) =>
      uploadFile({
        file,
        uploadUrl,
        onProgress,
      })
        .then(data => {
          results.push(data);
          onSuccess(data);
        })
        .catch(onError),
    ),
  );

  return results;
};
