import { Tooltip as MuiTooltip } from '@mui/material';
import { TooltipClasses as MuiTooltipClasses } from '@mui/material/Tooltip/tooltipClasses';
import { CoreComponent, CoreComponentProps, ElementContent, useMergedRef, withStaticProps } from '@react-fe/core';
import { forwardRef, ReactElement, useMemo, useRef } from 'react';
import { CustomWidthTooltip } from './custom-width-tooltip';
import { NoMaxWidthTooltip } from './no-max-width-tooltip';
import { ANCHOR_DISTANCE_SIZES, TooltipAnchorDistance, TooltipPlacement, TooltipWidthType } from './tooltip.constant';

export interface TooltipProps extends CoreComponentProps {
  title: string | ElementContent;
  arrow?: boolean;
  widthType?: TooltipWidthType;
  placement?: TooltipPlacement;
  anchorDistance?: TooltipAnchorDistance;
  enterDelay?: number;
  leaveDelay?: number;
  classes?: Partial<MuiTooltipClasses>;
  children: ReactElement;
}

const TooltipComponent: CoreComponent<TooltipProps> & {
  widthTypes?: typeof TooltipWidthType;
  anchorDistances?: typeof TooltipAnchorDistance;
  placement?: typeof TooltipPlacement;
} = forwardRef(
  (
    {
      id,
      'data-testid': dataTestId,
      className,
      title,
      widthType = Tooltip.widthTypes?.DEFAULT,
      anchorDistance = Tooltip.anchorDistances?.SMALL,
      placement = Tooltip.placement?.BOTTOM,
      enterDelay,
      leaveDelay,
      classes,
      arrow,
      children,
    },
    ref,
  ) => {
    const tooltipRef = useRef(null);
    const mergedRef = useMergedRef(tooltipRef, ref);

    const tooltipProps = useMemo(() => {
      const slotProps = {
        popper: {
          sx: {
            '& .MuiTooltip-tooltip': {
              paddingY: '0.5rem',
              paddingX: '1rem',
              gap: '0.5rem',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '0.5rem',
              backgroundColor: '#321432 !important',
            },
          },
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, ANCHOR_DISTANCE_SIZES[anchorDistance || TooltipAnchorDistance.SMALL]],
              },
            },
          ],
        },
      };

      const props: Record<string, unknown> = {
        ref: mergedRef,
        id,
        className,
        title,
        placement,
        'data-testid': dataTestId,
        arrow,
        classes,
        slotProps,
      };

      if (enterDelay) {
        props.enterDelay = enterDelay;
      }

      if (leaveDelay) {
        props.leaveDelay = leaveDelay;
      }

      return props;
    }, [
      id,
      mergedRef,
      className,
      dataTestId,
      title,
      placement,
      anchorDistance,
      enterDelay,
      leaveDelay,
      classes,
      arrow,
    ]);

    if (widthType === Tooltip.widthTypes?.CUSTOM_WIDTH) {
      return (
        <CustomWidthTooltip title={tooltipProps.title as string} {...tooltipProps}>
          <div className={'w-fit max-w-full'}>{children}</div>
        </CustomWidthTooltip>
      );
    } else if (widthType === Tooltip.widthTypes?.NO_WRAP) {
      return (
        <NoMaxWidthTooltip title={tooltipProps.title as string} {...tooltipProps}>
          <div className={'w-fit max-w-full'}>{children}</div>
        </NoMaxWidthTooltip>
      );
    } else {
      return (
        <MuiTooltip title={tooltipProps.title as string} {...tooltipProps}>
          <div className={'w-fit max-w-full'}>{children}</div>
        </MuiTooltip>
      );
    }
  },
);

export const Tooltip = withStaticProps(TooltipComponent, {
  widthTypes: TooltipWidthType,
  anchorDistances: TooltipAnchorDistance,
  placement: TooltipPlacement,
});

Tooltip.displayName = 'Tooltip';

export default Tooltip;
