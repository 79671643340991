import React, { ReactNode, useCallback, useMemo, useReducer } from 'react';
import { Breadcrumb, BreadcrumbContext } from '../../context/breadcrumbs-context';

interface BreadcrumbAction {
  type: 'SET_BREADCRUMB';
  payload: { breadcrumb: Breadcrumb };
}

function breadcrumbsReducer(state: Breadcrumb, action: BreadcrumbAction) {
  if (action.type === 'SET_BREADCRUMB') {
    return {
      ...state,
      ...action.payload.breadcrumb,
    };
  }
  return state;
}

interface BreadcrumbProviderProps {
  children: ReactNode;
}

export const BreadcrumbProvider = ({ children }: BreadcrumbProviderProps) => {
  const [breadcrumbs, breadcrumbsDispatch] = useReducer(breadcrumbsReducer, {});

  const setBreadcrumb = useCallback((breadcrumb: Breadcrumb) => {
    breadcrumbsDispatch({
      type: 'SET_BREADCRUMB',
      payload: { breadcrumb },
    });
  }, []);

  const ctxValue = useMemo(
    () => ({
      breadcrumbs,
      setBreadcrumb,
    }),
    [breadcrumbs, setBreadcrumb],
  );

  return <BreadcrumbContext.Provider value={ctxValue}>{children}</BreadcrumbContext.Provider>;
};
