import { faEnvelope, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { Heading, Icon, Link, Text } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import { Namespaces } from '@react-fe/expertunity-base';
import useBaseStore from '@react-fe/expertunity-base/stores';
import cx from 'classnames';
import { forwardRef, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
export type CreateOfferContactDetailsProps = CoreComponentProps;

export const CreateOfferContactDetails: CoreComponent<CreateOfferContactDetailsProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  CreateOfferContactDetailsProps
>(({ id, 'data-testid': dataTestId, className }, ref) => {
  const { t } = useTranslation();
  const { isCreateOfferOnBehalf, tenderDetails } = useBaseStore();
  const internalRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx(className, 'flex flex-col gap-spacing-2 w-full');

  const createOfferContactDetailsProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      ref: mergedRef,
    }),
    [id, dataTestId, classNames, mergedRef],
  );

  return (
    <div {...createOfferContactDetailsProps}>
      <Heading variant={Heading.variants.H5}>
        {isCreateOfferOnBehalf
          ? t('create_offer_on_behalf_contact_details_title', {
              ns: Namespaces.SellerCreateOfferPage,
              defaultValue: 'Contractor contact details',
            })
          : t('create_offer_contact_details_title', {
              ns: Namespaces.SellerCreateOfferPage,
              defaultValue: 'Your contact details',
            })}
      </Heading>

      <div className="flex flex-col gap-spacing-1 py-spacing-2 px-spacing-3 rounded-lg border border-grey-300">
        <div className="flex flex-col gap-spacing-0.5">
          <Heading
            variant={Heading.variants.H6}
          >{`${tenderDetails?.contact_person.firstName} ${tenderDetails?.contact_person.lastName}`}</Heading>
          <Text>{tenderDetails?.contact_person.function}</Text>
          <Text>{tenderDetails?.contact_person.clientName}</Text>
          <Text>{tenderDetails?.contact_person.department}</Text>
          <Text>{tenderDetails?.contact_person.clientCity}</Text>
        </div>

        <div className="flex flex-col gap-spacing-1">
          <div className="flex gap-spacing-1 items-center">
            <Icon icon={faEnvelope} />
            <Link href={`mailto:${tenderDetails?.contact_person.email}`}>{tenderDetails?.contact_person.email}</Link>
          </div>
          <div className="flex gap-spacing-1 items-center">
            <Icon icon={faPhone} />
            <Link href={`tel:${tenderDetails?.contact_person.phoneNumber}`}>
              {tenderDetails?.contact_person.phoneNumber}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
});

CreateOfferContactDetails.displayName = 'CreateOfferContactDetails';

export default CreateOfferContactDetails;
