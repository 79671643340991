import React, { forwardRef, useMemo } from 'react';
import { ComponentDefaultTestId, getTestId } from '@react-fe/common-ui';
import { CoreComponent } from '@react-fe/core';
import { useField } from 'formik';
import { FormError } from '../form-error';
import { Switch, SwitchProps } from '../switch';
import { FormControlRootProps } from './form-control-root';

type FormControlMergedProps = FormControlRootProps &
  SwitchProps & {
    label?: string;
  };

export const FormControlSwitch: CoreComponent<FormControlMergedProps, HTMLButtonElement> = forwardRef<
  HTMLButtonElement,
  FormControlMergedProps
>(({ id, 'data-testid': dataTestId, name = '', label, ...otherProps }, ref) => {
  const [field, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;

  const formControlSwitchProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId || getTestId(ComponentDefaultTestId.FORM_CONTROL_SWITCH, id),
      ...otherProps,
    }),
    [id, dataTestId, otherProps],
  );

  const toggleSwitchValue = () => {
    setValue(!value);
  };

  return (
    <div className="flex flex-col">
      <div className={'flex flex-row gap-spacing-2 items-center leading-none w-fit'}>
        <Switch {...field} {...formControlSwitchProps} onChange={toggleSwitchValue} />
        <span className="cursor-pointer" onClick={toggleSwitchValue}>
          {label}
        </span>
      </div>

      <FormError field={field} />
    </div>
  );
});

FormControlSwitch.displayName = 'FormControl.Switch';

export default FormControlSwitch;
