import { Offer, ProjectDetails } from '@react-fe/expertunity-base/models';
import { StateCreator } from 'zustand';
import { Tender, TenderPreview } from '../models';

export interface CommonBuyerState {
  tender: Tender | null;
  projectDetails: { data: ProjectDetails; isLoading: boolean } | null;
  offers: Offer[] | null;
  allTenders: TenderPreview[];
  isDeleteTenderModalOpen: boolean;
  isCreateProjectModalOpen: boolean;
  setTender: (tender: Tender | null) => void;
  setProjectDetails: (details: { data: ProjectDetails; isLoading: boolean }) => void;
  setOffers: (offers: Offer[] | null) => void;
  setAllTenders: (tenders: TenderPreview[]) => void;
  setIsDeleteTenderModalOpen: (isOpen: boolean) => void;
  setIsCreateProjectModalOpen: (isOpen: boolean) => void;
  clearCommonBuyerState: () => void;
}

export const createCommonBuyerSlice: StateCreator<CommonBuyerState> = set => ({
  tender: null,
  projectDetails: null,
  offers: null,
  allTenders: [],
  isDeleteTenderModalOpen: false,
  isCreateProjectModalOpen: false,
  setTender: (tender: Tender | null) => set({ tender }),
  setProjectDetails: projectDetails => set({ projectDetails }),
  setOffers: offers => set({ offers }),
  setAllTenders: tenders => set({ allTenders: tenders }),
  setIsDeleteTenderModalOpen: status => set({ isDeleteTenderModalOpen: status }),
  setIsCreateProjectModalOpen: status => set({ isCreateProjectModalOpen: status }),
  clearCommonBuyerState: () => set({ tender: null, projectDetails: null, offers: null, allTenders: [] }),
});
